import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import Sidebar from "../Sidebar/Sidebar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import { Dropdown } from "primereact/dropdown";
import "./AdminSettingPage.css";
import axios from "axios";
import { Divider } from "primereact/divider";
import { Row, Col } from "react-bootstrap";
import { Accordion, AccordionTab } from "primereact/accordion";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import SRUNavBarHeader from "../SRU/SRUNavBarHeader/SRUNavBarHeader";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";



let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;

let currentAppNameFromUrl;
let userSelectedLanguage = localStorage.getItem("selectedLanguage");
let currentUser,
  userTimeZone = "";

let steps = [
  {
    content: TranslationFile.TourGuide_Edit_Btn[userSelectedLanguage],
    placement: "right",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".header-edit",
    title: "Edit",
    disableBeacon: true,
  },
  {
    content: TranslationFile.TourGuide_Role_Edit_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".adminPageAddUserTag",
    title: "Add User",
    disableBeacon: true,
  },
  {
    content: TranslationFile.TourGuide_Active_Edit_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".adminPage-Status",
    title: "Status",
    disableBeacon: true,
  },
];

export default function ProductsDemo() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  userTimeZone = localStorage.getItem("userTimeZone");
  let emptyProduct = {
    id: "",
    Name: "",
    Email: "",
    ContactNo: "",
    Password: "",
    ConfirmPassword: "",
    Role: "",
    CreatedBy: "",
    ModifiedBy: "",
    Active: "",
    Accounting: "",
    AccountingName: "",
  };

  const userRoleOptions = [
    { name: "Admin", code: "Admin" },
    { name: "User", code: "User" },
  ];

  const [rolestatus] = useState(["Admin", "User"]);

  const userStatusOptions = [
    { name: "Active", code: "Active" },
    { name: "In Active", code: "In Active" },
  ];

  const [products, setProducts] = useState(null);
  const [companyInfoDialog, setCompanyInfoDialog] = useState(false);
  const [addNewUserDialog, setAddNewUserDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const loginReducer = useSelector((state) => state.login.login.values);
  const [companyInformation, setCompanyInformation] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [companyRegNo, setCompanyRegNo] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyPostalCode, setCompanyPostalCode] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [run, setRun] = useState(false);
  const [tempCompanyName, setTempCompanyName] = useState("");
  const [tempCompanyAddress, setTempCompanyAddress] = useState("");
  const [tempCompanyCity, setTempCompanyCity] = useState("");
  const [tempCompanyPostalCode, setTempCompanyPostalCode] = useState("");
  const [sideBarOpenState, setSideBarOpenState] = useState(true);
  const currentPageTourGuideName = "AdminSettingPage";


  //NEW USER
  const [newUserName, setNewUserName] = useState("");
  const [newUserLastName, setNewUserLastName] = useState("");
  const [newUserEmailId, setNewUserEmailId] = useState("");
  const [newUserContactNo, setNewUserContactNo] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserRole, setNewUserRole] = useState(userRoleOptions[1]);
  const [newUserStatus, setNewUserStatus] = useState(userStatusOptions[0]);
  const [newUsersubmitted, setNewUserSubmitted] = useState(false);
  const [newUserEmaildIdFocus, setNewUserEmaildIdFocus] = useState(false);
  const [isEmailIdAlreadyExists, setIsEmailIdAlreadyExists] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  //TEMP NEW USER
  const [TempNewUserName, setTempNewUserName] = useState("");
  const [TempNewUserEmailId, setTempNewUserEmailId] = useState("");
  const [TempNewUserContactNo, setTempNewUserContactNoe] = useState("");
  const [TempNewUserPassword, setTempNewUserPassword] = useState("");
  const [TempNewUserRole, setTempNewUserRole] = useState(userRoleOptions[1]);
  const [TempNewUserStatus, setTempNewUserStatus] = useState(
    userStatusOptions[0]
  );
  const [TempNewUsersubmitted, setTempNewUserSubmitted] = useState(false);
  const [activeIndex, setActiveIndex] = useState([0, 1]);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [showAdminPage, setShowAdminPage] = useState("");
  const [confirmDeleteUserVisible, setConfirmDeleteUserVisible] =
    useState(false);
  const [deleteUserDetails, setDeleteUserDetails] = useState("");
  let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
  currentUser = JSON.parse(localStorage.getItem("rebelSkoolUser"));

  useEffect(() => {
    localStorage.setItem("currentPageName", "AdminSetting");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentAppNameFromUrl = splittedUrl[splittedUrl.length - 3];
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/accountingCompany/getCompanyDetailsAndUsers?accountingCompanyId=" +
          loginReducer.accountingCompanyRegNo,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          setCompanyInformation(response.data.accountingCompany);
          setCompanyName(response.data.accountingCompany.accountingCompanyName);
          setCompanyRegNo(response.data.accountingCompany.registrationNo);
          setCompanyAddress(response.data.accountingCompany.address);
          setCompanyCity(response.data.accountingCompany.city);
          setCompanyPostalCode(response.data.accountingCompany.postalCode);
          setTempCompanyName(
            response.data.accountingCompany.accountingCompanyName
          );
          setTempCompanyAddress(response.data.accountingCompany.address);
          setTempCompanyCity(response.data.accountingCompany.city);
          setTempCompanyPostalCode(response.data.accountingCompany.postalCode);

          //This returns all the Users in the same accounting company
          //Have to remove the current email id and set in userDetails

          let userDetailsWithOwner = response.data.usersResponse;
          let usersInfo = userDetailsWithOwner.filter(
            (val) => val.emailID != currentUser.emailID
          );
          setUserDetails(usersInfo);
        });
    }

    if (currentUser["userRole"] == "User") {
      setShowAdminPage(false);
    } else {
      setShowAdminPage(true);
    }

    const timer = setTimeout(() => {
      let userTourGuide =
        JSON.parse(localStorage.getItem("TourGuide")) || false;
      setRun(userTourGuide);
    }, 200);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    let tourGuidePages = JSON.parse(localStorage.getItem("tourGuidePages")) || [];

    if (userTourGuide) {
      if (tourGuidePages.includes(currentPageTourGuideName)) {
        setTimeout(() => {
          setRun(false);
        }, 200);
      } else {
        setTimeout(() => {
          setRun(true);
        }, 200);
      }
    }

    // Update tourGuidePages in localStorage
    if (!tourGuidePages.includes(currentPageTourGuideName)) {
      tourGuidePages.push(currentPageTourGuideName);
      localStorage.setItem("tourGuidePages", JSON.stringify(tourGuidePages));
    }
  }, [currentPageTourGuideName]);

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setAddNewUserDialog(true);
    clearNewUserDetailsInDialogBox();
  };

  const hideAddNewUser = () => {
    setAddNewUserDialog(false);
    setNewUserSubmitted(false);
    setIsEmailIdAlreadyExists(false);
    setNewUserName(TempNewUserName);
    setNewUserEmailId(TempNewUserEmailId);
    setNewUserContactNo(TempNewUserContactNo);
    setNewUserPassword(TempNewUserPassword);
    setNewUserRole(TempNewUserRole);
    setNewUserStatus(TempNewUserStatus);
    clearNewUserDetailsInDialogBox();
    setDisableSaveBtn(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCompanyInfoDialog(false);
    setCompanyName(tempCompanyName);
    setCompanyAddress(tempCompanyAddress);
    setCompanyCity(tempCompanyCity);
    setCompanyPostalCode(tempCompanyPostalCode);
    clearNewUserDetailsInDialogBox();
    setDisableSaveBtn(true);
  };

  const addNewUser = () => {
    if (userSessionAuthKeyReturnValue) {
      setNewUserSubmitted(true);
      setCurrentField("");

      if (
        newUserName != "" &&
        newUserLastName != "" &&
        newUserEmailId != "" &&
        // newUserPassword != "" &&
        !isEmailIdAlreadyExists
      ) {
        let createNewUserReq = {
          userName: newUserName,
          firstName: newUserName,
          lastName: newUserLastName,
          emailID: newUserEmailId,
          password: "",
          mobileNo: newUserContactNo,
          createdByUserID: loginReducer && loginReducer.userID,
          accountingCompanyName: companyName,
          registrationNo: companyRegNo,
          address: companyAddress,
          postalCode: companyPostalCode,
          city: companyCity,
          userRole: newUserRole.name,
          isAdminUser: false,
          active: newUserStatus.name == "Active" ? true : false,
        };

        axios
          .post(
            "/accountingCompany/initializeWithUserAndAccess",
            createNewUserReq
          )
          .then((response) => {
            axios
              .get(
                "/accountingCompany/getCompanyDetailsAndUsers?accountingCompanyId=" +
                loginReducer.accountingCompanyRegNo,
                userSessionAuthKeyReturnValue
              )
              .then((res) => {
                ///////////////
                let emailVerifiedRequest = {
                  emailID: newUserEmailId,
                };
                axios
                  .post(
                    "/user/getUserDetailsByEmailIdAsLoginResp",
                    emailVerifiedRequest
                  )
                  .then((response) => {
                    let userDetailsResponse = response.data;
                    if (
                      userDetailsResponse != null &&
                      userDetailsResponse.emailID != null &&
                      userDetailsResponse.userGUID != null
                    ) {
                      userDetailsResponse.emailVerificationStatus = false;
                      let recipient = response.data.emailID;
                      let userGUID = response.data.userGUID;

                      let sendMailObject = {
                        recipient: recipient,
                        emailType: "emailVerification",
                        userGUID: userGUID,
                        status: "New",
                        noOfAttempts: 0,
                        userName: newUserName,
                        firstName: newUserName,
                        lastName: newUserLastName,
                      };
                      const url =
                        "/emailQueueController/insertEmailQueueAndSendMail";
                      const formData = new FormData();
                      const objString = JSON.stringify(sendMailObject);

                      formData.append("request", objString);
                      const config = {
                        headers: {
                          // "content-type": "multipart/form-data,application/json",
                          timeZone: userTimeZone,
                          language: userSelectedLanguage
                        },
                      };
                      axios.post(url, formData, config).then((response) => {
                        if (response.data) {
                          toast.current.show({
                            severity: "success",
                            summary: "Successful",
                            detail:
                              "Verification mail sent for the newly added User. Kindly Verify the email before logging in ",
                            life: 3000,
                            // sticky: true,
                          });
                          // clearNewUserDetailsInDialogBox();
                        }
                      });
                    }
                  });
                ///////////////

                setAddNewUserDialog(false);
                setNewUserName("");
                setNewUserLastName("");
                setNewUserEmailId("");
                setNewUserPassword("");
                setNewUserRole("");
                setNewUserStatus("");
                setNewUserSubmitted(false);
                toast.current.show({
                  severity: "success",
                  summary: TranslationFile.SuccessText[userSelectedLanguage],
                  detail: TranslationFile.NewUserAdded[userSelectedLanguage],
                  life: 3000,
                });
                let userDetailsWithOwner = res.data.usersResponse;
                let usersInfo = userDetailsWithOwner.filter(
                  (val) => val.emailID != currentUser.emailID
                );
                setUserDetails(usersInfo);
              });
          });
      }
      // clearNewUserDetailsInDialogBox();
    }
  };

  const clearNewUserDetailsInDialogBox = () => {
    setNewUserName("");
    setNewUserSubmitted(false);
    setNewUserEmailId("");
    setNewUserPassword("");
    setNewUserRole(userRoleOptions[1]);
    setNewUserStatus(userStatusOptions[0]);
  };

  const saveProduct = () => {
    if (userSessionAuthKeyReturnValue) {
      setSubmitted(true);
      if (
        companyName != "" &&
        companyAddress != "" &&
        companyCity != "" &&
        companyPostalCode != ""
      ) {
        let updateAccCompanyReq = {
          accountingCompanyName: companyName,
          address: companyAddress,
          city: companyCity,
          postalCode: companyPostalCode,
          registrationNo: companyRegNo,
        };

        axios
          .post(
            "/accountingCompany/update",
            updateAccCompanyReq,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessText[userSelectedLanguage],
              detail: TranslationFile.CompanyInfoUpdated[userSelectedLanguage],
              life: 3000,
            });

            setCompanyInformation(response.data.accountingCompany);
            setCompanyName(
              response.data.accountingCompany.accountingCompanyName
            );
            setCompanyRegNo(response.data.accountingCompany.registrationNo);
            setCompanyAddress(response.data.accountingCompany.address);
            setCompanyCity(response.data.accountingCompany.city);
            setCompanyPostalCode(response.data.accountingCompany.postalCode);
            setCompanyInfoDialog(false);
            setTempCompanyName(
              response.data.accountingCompany.accountingCompanyName
            );
            setTempCompanyAddress(response.data.accountingCompany.address);
            setTempCompanyCity(response.data.accountingCompany.city);
            setTempCompanyPostalCode(
              response.data.accountingCompany.postalCode
            );
          });
      }
      clearNewUserDetailsInDialogBox();
    }
  };

  const editProduct = (product) => {
    setCompanyInfoDialog(true);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <h4 className="adminPageUserListLabel">User List</h4>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label={TranslationFile.AddUeserText2[userSelectedLanguage]}
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
          className="adminPageAddUserTag"
        />
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status}
        severity={getSeverityActive(rowData.status)}
      ></Tag>
    );
  };

  const [statuses] = useState(["Active", "InActive"]);

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverityActive(option)} />;
  };

  const statusFilterTemplate = (options) => {
    return (
      <div className="TEST">
        <Dropdown
          value={options.value}
          options={statuses}
          onChange={(e) => options.filterCallback(e.value, options.index)}
          itemTemplate={statusItemTemplate}
          placeholder={TranslationFile.dashboardSelectOne[userSelectedLanguage]}
          className="dashboard-status-filter"
          showClear
          filter={false}
        />
      </div>
    );
  };

  const roleBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.userRole}
        severity={getSeverity(rowData.userRole)}
      ></Tag>
    );
  };

  const roleItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  const roleFilterTemplate = (options) => {
    return (
      <div className="TEST">
        <Dropdown
          value={options.value}
          options={rolestatus}
          onChange={(e) => options.filterCallback(e.value, options.index)}
          itemTemplate={roleItemTemplate}
          placeholder={TranslationFile.dashboardSelectOne[userSelectedLanguage]}
          className="dashboard-status-filter"
          showClear
          filter={false}
        />
      </div>
    );
  };

  const getSeverity = (Role) => {
    switch (Role) {
      case "Admin":
        return "success";

      case "User":
        return "warning";
    }
  };

  const getSeverityActive = (Active) => {
    switch (Active) {
      case "Active":
        return "success";

      case "Inactive":
        return "danger";
    }
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Products</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder={TranslationFile.dashboardSearchBtn[userSelectedLanguage]}
        />
      </span>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button label={TranslationFile.CancelBtn[userSelectedLanguage]} icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label={TranslationFile.IncomestateYearsave[userSelectedLanguage]} icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  const addNewUserDialogFooter = (
    <React.Fragment>
      <Button
        label={TranslationFile.CancelBtn[userSelectedLanguage]}
        icon="pi pi-times"
        outlined
        onClick={hideAddNewUser}
      />
      <Button
        label={TranslationFile.IncomestateYearsave[userSelectedLanguage]}
        icon="pi pi-check"
        onClick={addNewUser}
        disabled={disableSaveBtn}
      />
    </React.Fragment>
  );

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const roleEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={rolestatus}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={TranslationFile.SelectAStatusText[userSelectedLanguage]}
        itemTemplate={(option) => {
          return <Tag value={option} severity={getSeverity(option)}></Tag>;
        }}
      />
    );
  };

  const statusEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={options.value}
        itemTemplate={(option) => {
          return <Tag value={option} severity={getSeverity(option)}></Tag>;
        }}
      />
    );
  };

  const onRowEditComplete = (e) => {
    let { newData, index } = e;
    if (userSessionAuthKeyReturnValue) {
      let statusBooleanValue = false;
      if (newData.status == "Active") statusBooleanValue = true;

      let updateUserReq = {
        userID: newData.userID,
        mobileNo: newData.mobileNo,
        password: newData.password,
        userRole: newData.userRole,
        active: statusBooleanValue,
        modifiedByUserID: loginReducer.userID,
      };

      axios
        .post(
          "/accountingCompany/adminUpdateUser",
          updateUserReq,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            axios
              .get(
                "/accountingCompany/getCompanyDetailsAndUsers?accountingCompanyId=" +
                loginReducer.accountingCompanyRegNo,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let userDetailsWithOwner = response.data.usersResponse;
                let usersInfo = userDetailsWithOwner.filter(
                  (val) => val.emailID != currentUser.emailID
                );
                setUserDetails(usersInfo);
              });
          }
        });
    }
  };

  function checkLoginEmailIDisExists(emailId) {
    let userLoginDetails = {
      emailID: emailId,
    };

    if (userLoginDetails.emailID != "" && userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/user/checkIfEmailExists",
          userLoginDetails,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data == true) {
            setIsEmailIdAlreadyExists(true);
            setDisableSaveBtn(true);
          } else {
            setIsEmailIdAlreadyExists(false);
            setDisableSaveBtn(false);
          }

          // saveBtnDisableCheck();
        });
    }
  }
  function accordionToggle(toggleOpen) {
    if (toggleOpen == true) {
      setActiveIndex([0, 1]);
      setToggleOpen(false);
    } else {
      setActiveIndex([2]);

      setToggleOpen(true);
    }
  }
  const languageValue = (e) => { };

  const callback = (annualReport, session, link, status) => {
    if (status) {
      this.props.history.push(annualReport + session + link);
    }
  };

  const sideBarCallback = (value) => { };
  const sideBarStatus = (e) => {
    setSideBarOpenState(e);
  };

  const tourStatus = (e) => {
    setRun(e);
  };

  return (
    <div className="Adimsettingpage_main">
      <Joyride
        continuous
        run={run}
        disableBeacon={true}
        showProgress={false}
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps
        scrollOffset={100}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <NavBarHeader isAppPortal={true} isHomePage={false} callBack={callback} />

      <div className="admin-setting-sidebar">
        <Sidebar
          sideBarStatus={sideBarStatus}
          sideBarCallback={(e) => sideBarCallback(e)}
          tourStatus={tourStatus}
        />
      </div>
      {showAdminPage != "" && showAdminPage ? (
        <div>
          <div className="admin-page-total-content">
            <div className="Admin-ISExapndCollapsToggleLbl">
              <Row className="Admin-toggle-open">
                <Col></Col>
                <Col></Col>
                <Col>
                  {toggleOpen == true ? (
                    <Button
                      value={toggleOpen}
                      icon="pi pi-chevron-down"
                      title="Expand"
                      onClick={(e) => accordionToggle(toggleOpen)}
                      className="toggle-open"
                    />
                  ) : (
                    <Button
                      value={toggleOpen}
                      icon="pi pi-chevron-up"
                      title="Expand"
                      onClick={(e) => accordionToggle(toggleOpen)}
                      className="toggle-open"
                    />
                  )}
                </Col>
              </Row>
              <br></br>
            </div>
            <Accordion
              multiple
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
              // ref={this.myRef}
              className={
                sideBarOpenState ? "admin-accordion-close" : "admin-accordion"
              }
            >
              <AccordionTab
                // className="adminPage-Accordion-tab1"
                header={TranslationFile.ViewEditInfoTabText[userSelectedLanguage]}
                className={
                  currentAppNameFromUrl == "annualreport"
                    ? "IS-Accordion-Tab"
                    : "SRU-INK2-accordion-Tab"
                }
              >
                {companyInformation != null && (
                  <div className="adminPageCompanyInfoMainDiv">
                    <Row className="adminPageCompanyInfoTitleRow">
                      <Col className="edit-btn-col">
                        <Button
                          icon="pi pi-pencil"
                          rounded
                          outlined
                          className="header-edit"
                          onClick={() => editProduct()}
                        />
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col className="adminPage-Registration">
                        Registration No.
                      </Col>
                      <Col className="adminPageCompanyInfoKey">
                        {companyInformation.registrationNo}
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col className="adminPage-CompanyName">Company Name</Col>
                      <Col className="adminPageCompanyInfoKey">
                        {companyInformation.accountingCompanyName}
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col className="adminPage-Address">Address</Col>
                      <Col className="adminPageCompanyInfoKey">
                        {companyInformation.address}
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col className="adminPage-City">City</Col>
                      <Col className="adminPageCompanyInfoKey">
                        {companyInformation.city}
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col className="adminPage-PostalCity">Postal Code</Col>
                      <Col className="adminPageCompanyInfoKey">
                        {companyInformation.postalCode}
                      </Col>
                    </Row>
                    <Divider />
                  </div>
                )}

                <div></div>
              </AccordionTab>

              <AccordionTab
                // className="adminPage-Accordion-tab2"
                header={TranslationFile.ViewEditInfoTabText[userSelectedLanguage]}
                className="IS-Accordion-Tab"
              //  className="SRU-INK2-accordion-Tab"
              >
                <div>
                  <Toast ref={toast} className="toast-top" />
                  <center>
                    <div className="card-admin-page">
                      {/* <Divider/>  */}
                      <Toolbar
                        className="admin-toolbar"
                        left={leftToolbarTemplate}
                        right={rightToolbarTemplate}
                      ></Toolbar>
                      <DataTable
                        ref={dt}
                        className="adminPage-datatable-22"
                        value={userDetails}
                        dataKey="id"
                        globalFilter={globalFilter}
                        header={header}
                        editMode="row"
                        onRowEditComplete={onRowEditComplete}
                        paginator
                        rows={10}
                        emptyMessage="No Users Found"
                      >
                        <Column
                          field="userName"
                          className="adminPage-name"
                          header={TranslationFile.FirstNameText[userSelectedLanguage]}
                          filter
                          showFilterMatchModes={false}
                        ></Column>

                        <Column
                          field="lastName"
                          className="adminPage-name"
                          header={TranslationFile.LastName[userSelectedLanguage]}
                          filter
                          showFilterMatchModes={false}
                        ></Column>

                        <Column
                          field="emailID"
                          className="adminPage-Email"
                          header={TranslationFile.FooterEmailLabel[userSelectedLanguage]}
                        ></Column>
                        {/* <Column
                          field="mobileNo"
                          header="Contact No"
                          className="adminPage-Contact"
                          editor={(options) => textEditor(options)}
                        ></Column> */}
                        <Column
                          field="userRole"
                          header={TranslationFile.repRole[userSelectedLanguage]}
                          filter
                          className="adminPage-Role"
                          body={roleBodyTemplate}
                          filterElement={roleFilterTemplate}
                          showFilterMatchModes={false}
                          editor={(options) => roleEditor(options)}
                        ></Column>

                        <Column
                          field="createdByUserName"
                          header={TranslationFile.dashboardCreatedBy[userSelectedLanguage]}
                          className="adminPage-createdBy"
                          filter
                        ></Column>

                        <Column
                          field="modifiedByUserName"
                          header={TranslationFile.dashboardModifiedBy[userSelectedLanguage]}
                          className="adminPage-ModifiedBy"
                          filter
                        ></Column>

                        <Column
                          field="status"
                          header={TranslationFile.dashboardStatusBtn[userSelectedLanguage]}
                          filter
                          className="adminPage-Status"
                          body={statusBodyTemplate}
                          filterElement={statusFilterTemplate}
                          showFilterMatchModes={false}
                          editor={(options) => statusEditor(options)}
                        ></Column>

                        <Column
                          rowEditor
                          headerStyle={{ width: "10%", minWidth: "8rem" }}
                          bodyStyle={{ textAlign: "center" }}
                        ></Column>
                        {/* <Column body={deleteBodyTemplate}></Column> */}
                      </DataTable>
                    </div>
                  </center>
                </div>
              </AccordionTab>
            </Accordion>
          </div>

          <Dialog
            visible={addNewUserDialog}
            blockScroll
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header={TranslationFile.AddNewUserText[userSelectedLanguage]}
            modal
            className="p-fluid"
            footer={addNewUserDialogFooter}
            onHide={hideAddNewUser}
          >
            <div className="field">
              <p className="adminPageCompanyInfoEditPopUpTitles">{TranslationFile.FirstNameText[userSelectedLanguage]}</p>
              <InputText
                id="name"
                value={newUserName}
                onChange={(e) => {
                  // userUserFirstNameOnChange(e);
                  setNewUserName(e.target.value);
                  // setCurrentField("");
                  // saveBtnDisableCheck();
                }}
                required
                autoFocus
                className={classNames({
                  "p-invalid": newUsersubmitted && !newUserName,
                })}
                placeholder={TranslationFile.LoginFirstName[userSelectedLanguage]}
              />
              {newUsersubmitted && !newUserName && (
                <small className="p-error">{TranslationFile.repFirstNameReq[userSelectedLanguage]}</small>
              )}
            </div>

            <br></br>
            <div className="field">
              <p className="adminPageCompanyInfoEditPopUpTitles">{TranslationFile.LastName[userSelectedLanguage]}</p>
              <InputText
                id="name"
                value={newUserLastName}
                onChange={(e) => {
                  setNewUserLastName(e.target.value);
                  setCurrentField("");
                }}
                required
                className={classNames({
                  "p-invalid": newUsersubmitted && !newUserLastName,
                })}
                placeholder={TranslationFile.repLastNameText[userSelectedLanguage]}
              />
              {newUsersubmitted && !newUserLastName && (
                <small className="p-error">{TranslationFile.repLastNameReq[userSelectedLanguage]}</small>
              )}
            </div>

            <br></br>

            <div
              className="field"
              onFocus={() => setNewUserEmaildIdFocus(true)}
              onBlur={() => setNewUserEmaildIdFocus(false)}
            >
              <p className="adminPageCompanyInfoEditPopUpTitles">{TranslationFile.EmailIdText[userSelectedLanguage]}</p>
              <InputText
                // id="email"
                value={newUserEmailId}
                onChange={(e) => {
                  setNewUserEmailId(e.target.value);
                  setCurrentField("EmailID");
                  // saveBtnDisableCheck();
                }}
                required
                className={classNames({
                  "p-invalid": newUsersubmitted && !newUserEmailId,
                })}
                placeholder={TranslationFile.EmailIdText[userSelectedLanguage]}
              />
              {newUsersubmitted && !newUserEmailId ? (
                <small className="p-error">{TranslationFile.EmailIdreqText[userSelectedLanguage]}</small>
              ) : (
                !newUserEmaildIdFocus &&
                newUserEmailId != "" &&
                currentField == "EmailID" &&
                checkLoginEmailIDisExists(newUserEmailId)
              )}

              {isEmailIdAlreadyExists && newUserEmailId != "" && (
                <div className="adminPageEmailExistsAlready">
                  {" "}
                  {TranslationFile.EmailExistsAlreadyText[userSelectedLanguage]}
                </div>
              )}
            </div>

            <br></br>
            <div className="field">
              <p className="adminPageCompanyInfoEditPopUpTitles"> {TranslationFile.repRole[userSelectedLanguage]}</p>
              <Dropdown
                value={newUserRole}
                options={userRoleOptions}
                onChange={(e) => {
                  setNewUserRole(e.value);
                  setCurrentField("");
                }}
                optionLabel="name"
                placeholder={TranslationFile.selectStatusText[userSelectedLanguage]}
              />
            </div>

            <br></br>

            <div className="field">
              <p className="adminPageCompanyInfoEditPopUpTitles">Status</p>
              <Dropdown
                value={newUserStatus}
                options={userStatusOptions}
                onChange={(e) => {
                  setNewUserStatus(e.value);
                  setCurrentField("");
                }}
                optionLabel="name"
                placeholder={TranslationFile.selectUserRollText[userSelectedLanguage]}
              />
            </div>

            <br></br>
          </Dialog>

          <Dialog
            blockScroll
            visible={companyInfoDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header={TranslationFile.EditCmpyInfoText[userSelectedLanguage]}
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            {companyInformation != null && (
              <div>
                <div className="field">
                  <p className="adminPageCompanyInfoEditPopUpTitles">
                    {/* Registration No. */}
                    {TranslationFile.loginRegistrationNo[userSelectedLanguage]}
                  </p>
                  <InputText
                    id="RegistrationNo"
                    value={companyRegNo}
                    onChange={(e) => setCompanyRegNo(e.target.value)}
                    required
                    className={classNames({
                      "p-invalid": submitted && !companyRegNo,
                    })}
                    placeholder={TranslationFile.loginRegistrationNo[userSelectedLanguage]}
                    disabled={true}
                  />
                  {submitted && !companyRegNo && (
                    <small className="p-error">
                      {/* Registration No. is required. */}
                      {TranslationFile.RegNumberIsReqText[userSelectedLanguage]}
                    </small>
                  )}
                </div>

                <br></br>

                <div className="field">
                  <p className="adminPageCompanyInfoEditPopUpTitles">
                    {/* Company Name */}
                    {TranslationFile.IncomestateCompanyOrgName[userSelectedLanguage]}
                  </p>
                  <InputText
                    id="name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                    className={classNames({
                      "p-invalid": submitted && !companyName,
                    })}
                    placeholder={TranslationFile.IncomestateCompanyOrgName[userSelectedLanguage]}
                  />
                  {submitted && !companyName && (
                    <small className="p-error">   {TranslationFile.NameisReqText[userSelectedLanguage]}</small>
                  )}
                </div>

                <br></br>

                <div className="field">
                  <p className="adminPageCompanyInfoEditPopUpTitles"> {TranslationFile.loginAddress[userSelectedLanguage]}</p>
                  <InputText
                    id="Address"
                    value={companyAddress}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    required
                    className={classNames({
                      "p-invalid": submitted && !companyAddress,
                    })}
                    placeholder={TranslationFile.loginAddress[userSelectedLanguage]}
                  />
                  {submitted && !companyAddress && (
                    <small className="p-error"> {TranslationFile.AddressReqText[userSelectedLanguage]}</small>
                  )}
                </div>

                <br></br>

                <div className="field">
                  <p className="adminPageCompanyInfoEditPopUpTitles"> {TranslationFile.IncomestateCompanyCity[userSelectedLanguage]}</p>
                  <InputText
                    id="PostalCode"
                    value={companyCity}
                    onChange={(e) => setCompanyCity(e.target.value)}
                    required
                    className={classNames({
                      "p-invalid": submitted && !companyCity,
                    })}
                    placeholder={TranslationFile.IncomestateCompanyCity[userSelectedLanguage]}
                  />
                  {submitted && !companyCity && (
                    <small className="p-error"> {TranslationFile.CityCodeIsreqText[userSelectedLanguage]}</small>
                  )}
                </div>
                <br></br>

                <div className="field">
                  <p className="adminPageCompanyInfoEditPopUpTitles">
                    {/* Postal Code */}
                    {TranslationFile.IncomestateCompanyPostalcode[userSelectedLanguage]}
                  </p>
                  <InputText
                    id="PostalCode"
                    value={companyPostalCode}
                    onChange={(e) => setCompanyPostalCode(e.target.value)}
                    required
                    className={classNames({
                      "p-invalid": submitted && !companyPostalCode,
                    })}
                    placeholder={TranslationFile.IncomestateCompanyPostalcode[userSelectedLanguage]}
                  />
                  {submitted && !companyPostalCode && (
                    <small className="p-error"> {TranslationFile.PostalcodeisReqText[userSelectedLanguage]}</small>
                  )}
                </div>
              </div>
            )}
          </Dialog>
        </div>
      ) : (
        <div className="admin-restriction">
          <center>
            {/* The Admin of the Accounting Company can only view this Page */}
            {TranslationFile.AdminOnlyViewPageText[userSelectedLanguage]}
          </center>
        </div>
      )}

      <ScrolltoTop />
      <Footer_contact_tab isCorpPortal={false} isHomePage={true} />
    </div>
  );
}
