import React, { Component } from "react";
import { Carousel } from "primereact/carousel";
import axios from "axios";
import { Button } from "primereact/button";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import { connect } from "react-redux";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import Prices from "../Prices/Prices";
import DemoSlide from "../DemoSlide/DemoSlide";
import FaqSection from "../FAQ/FAQSection";
import OtherServices from "../OtherServices/OtherServices";
import { Helmet } from "react-helmet";
import carousel1 from "../../Assests/carousel1.webp";
import carousel2 from "../../Assests/carousel2.webp";
import carousel3 from "../../Assests/carousel3.jpg";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import "../01-Home/home.css";
import $ from "jquery";

import config from "../../Configuration/Config";
import { StatusALert } from "../StatusAlert/StatusAlert";

let uuidForNavigation = "";
const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    incomeStatement: state.incomeStatement.incomeStatement,
    sessionDetails: state.sessionDetails,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    language: state.language.language,
    activeSideBar: state.activeSideBar.activeSideBar,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    pricesToLogin: state.pricesToLogin.pricesToLogin,
  };
};

const responsiveOptions = [
  {
    breakpoint: "1199px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "991px",
    numVisible: 1,
    numScroll: 1,
  },
  // {
  //   breakpoint: "991px",
  //   numVisible: 1,
  //   numScroll: 1,
  // },
];

let userSelectedLanguage;
let imgArray = [];

class home extends Component {
  constructor(props) {
    super(props);
    this.priceRef = React.createRef();
    this.howItWorksRef = React.createRef();
    this.faqRef = React.createRef();
    this.otherServicesRef = React.createRef();
    // this.contactRef = React.createRef();

    this.state = {
      carouselObjects: [],
      refresh: false,
      selectedLanguage: "sw",
      cartValueToHeader: [],
      setCart: "",
      handleChange: "",
      hover: false,
      basicPrice: "",
      render: false,
    };
    this.carouselRender = this.carouselRender.bind(this);
    this.annualReport = this.annualReport.bind(this);
  }

  componentWillMount() {
    let { sideBarReducer, currentPageName, sessionDetails, login } = this.props;
    window.scrollTo(0, 0);
    localStorage.removeItem("possibleAccessablePages");
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    if (sessionUUIDFromLS) uuidForNavigation = sessionUUIDFromLS;
    let scrollToLs = localStorage.getItem("scrollTo");

    if (scrollToLs == "companyInfo") {
      localStorage.removeItem("scrollTo");

      const annualReport = "/annualreport/";

      this.setState({
        render: false,
      });

      setTimeout(() => {
        this.props.history.push(
          annualReport +
          sessionDetails["sessionDetails"].values.uuid +
          "/companyInfo"
        );
      }, 1000);
    } else {
      this.setState({
        render: true,
      });

      currentPageName.values = "Home";
      userSelectedLanguage = localStorage.getItem("selectedLanguage");

      this.settingCarouselDesc(null, "img");

      axios.get("/siteOwner/getTemplatePackages").then((response) => {
        let basicPrice = [];
        response.data && response.data.forEach((val, idx) => {
          if (
            val.packageId == 100 ||
            val.packageId == 400

            // || val.packageId == 900

          ) {
            basicPrice.push(val.price);
          }
        });
        setTimeout(() => {
          this.settingCarouselDesc(basicPrice, "price");
        }, 1000);
      });
    }
  }

  componentDidMount() {
    let {
      companyInformation,
      financialYear,
      sideBarReducer,
      sessionDetails,
      prices,
      currentPageName,
    } = this.props;

    let navigateTo = localStorage.getItem("navigateTo");

    if (navigateTo != "") {
      if (navigateTo == "dashboard") {
        const annualReport = "/annualreport/";
        localStorage.setItem("navigateTo", "");
        const guid = localStorage.getItem("sessionUUID");
        this.props.history.push(annualReport + guid + "/dashboard");
        window.location.reload(false);
      } else if (navigateTo == "companyInformation") {
        this.annualReport();
      }
    }

    if (companyInformation != undefined) {
      companyInformation.isExistingOrganization = false;
      companyInformation.organizationname = "";
      companyInformation.organizationnumber = "";
      companyInformation.postalcode = "";
      companyInformation.city = "";
    }

    if (sideBarReducer.values != undefined) {
      sideBarReducer.values = [];
    }

    if (financialYear != undefined) {
      financialYear.values = {};
    }

    let currentPageUrl = window.location.href;
    let splitCurrentPageUrl = currentPageUrl.split("/");
    this.scrollTo();
  }

  settingCarouselDesc(response, field) {
    let carouselUrlsWithDescArray = [
      // {
      //   id: 3,
      //   title: TranslationFile.NavbarSRUfiles[userSelectedLanguage],
      //   price: "",
      //   buttonlabel: TranslationFile.NavbarTryForFree[userSelectedLanguage],
      //   img: "",
      //   paragraph: TranslationFile.carouselParagraph3[userSelectedLanguage],
      //   onlyfor: TranslationFile.onlyfor[userSelectedLanguage],
      //   img: carousel2,
      // },
      {
        id: 1,
        mainTitle: TranslationFile.carouselTitlePrivate[userSelectedLanguage],
        title: TranslationFile.carouselTitlePrivate[userSelectedLanguage],
        price: "",
        buttonlabel: TranslationFile.NavbarTryForFree[userSelectedLanguage],
        img: "",
        paragraph: TranslationFile.carouselParagraph1[userSelectedLanguage],
        onlyfor: TranslationFile.onlyfor[userSelectedLanguage],
        img: carousel1,
      },

      {
        id: 2,
        mainTitle: TranslationFile.carouselTitleAccounting[userSelectedLanguage],
        title: TranslationFile.carouselTitleAccounting[userSelectedLanguage],
        price: "",
        buttonlabel: TranslationFile.NavbarTryForFree[userSelectedLanguage],
        img: "",
        paragraph: TranslationFile.carouselParagraph1[userSelectedLanguage],
        onlyfor: TranslationFile.onlyfor[userSelectedLanguage],
        img: carousel3,
      }
    ];

    null != response &&
      response &&
      response.length &&
      response.map((i, idx) => {
        let carouselObj = carouselUrlsWithDescArray.filter(
          (obj) => obj.id == idx + 1
        );
        carouselObj[0]["price"] = i;
        this.setState({ reRender: true });

        if (response.length == idx + 1) {
          this.setState({ carouselObjects: carouselUrlsWithDescArray });
        }
      });
    // this.setState({ carouselObjects: carouselUrlsWithDescArray });
  }

  annualReport(product) {

    // if (product && product.id == 3) {
    // if (product) {

    //   this.sruPage();
    // } else {
    const { sessionDetails } = this.props;
    const { activeSideBar } = this.props;
    let selectedSideBar = [];
    selectedSideBar.push("Company Information");
    activeSideBar.values = selectedSideBar;
    setTimeout(() => {
      sessionDetails["sessionDetails"].values.currentPage = "companyInfo";
      sessionDetails["sessionDetails"].values.IsAnnualReportSubmitted = false;

      const annualReport = "/annualreport/";
      this.props.history.push(
        annualReport +
        sessionDetails["sessionDetails"].values.uuid +
        "/companyInfo"
      );
    }, 1000);
    // }
  }

  carouselRender(product) {
    return (
      <div className="product-item">
        <div className="product-item-content">
          <div
            className={
              product.id == 1
                ? "upper-text-carousel-left"
                : "upper-text-carousel-right"
            }
          >
            <span className="digital-main-title">{product.mainTitle}</span>
            <span className="carouselDivText">{product.paragraph}</span>
            <br></br>
            {/* {product.title} */}
            {/* <span className="pageCompanyDetail">
              {product.title}
              {product.id !== 3 && (
                <span
                  style={{
                    fontSize: "14px",
                    color: "grey",
                  }}
                >
                  (SRU Included)
                </span>
              )}
            </span> */}
            <span className="price-tag-color">
              {product.onlyfor} {product.price} kr
            </span>
            <Button
              label={product.buttonlabel}
              onClick={() => this.annualReport(product)}
              id="btn_Begin"
              className="btn_Begin"
            />
          </div>

          <img src={product.img} alt={product} className="product-image" />
        </div>
      </div>
    );
  }

  sruPage() {
    const sruPage = "/income-declaration-2/";
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    // window.location.href = config.apps.url + "#" + "/income-declaration-2";
    window.location.href = sruPage + sessionUUIDFromLS + "/companyInfo";
  }

  languageValue = (e) => {
    this.setState({
      selectedLanguage: e,
    });
  };

  callback = (annualReport, session, link, status) => {
    const { sessionDetails } = this.props;
    this.scrollTo();
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      this.props.history.push(annualReport + sessionUUIDFromLS + link);
      // window.location.reload(false);
    }
    this.scrollTo();
    this.setState({
      reRender: true,
    });
    //
  };

  scrollTo() {
    let currentPageUrl = window.location.href;
    let splitCurrentPageUrl = currentPageUrl.split("/");

    let scrollToLs = localStorage.getItem("scrollTo");

    setTimeout(() => {
      if (splitCurrentPageUrl[splitCurrentPageUrl.length - 2] == "contact-us") {
        window.history.pushState(null, "", "/annualreport/#contact-us");
        this.priceRef.current.scrollIntoView({ behavior: "smooth" });
      }

      if (
        splitCurrentPageUrl[splitCurrentPageUrl.length - 1] == "#prices" ||
        scrollToLs == "#prices"
      ) {
        window.history.pushState(null, "", "/annualreport/#prices");
        this.priceRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        splitCurrentPageUrl[splitCurrentPageUrl.length - 1] ==
        "#how-it-works" ||
        scrollToLs == "#how-it-works"
      ) {
        window.history.pushState(null, "", "/annualreport/#how-it-works");
        this.howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        splitCurrentPageUrl[splitCurrentPageUrl.length - 1] == "#faq" ||
        scrollToLs == "#faq"
      ) {
        window.history.pushState(null, "", "/annualreport/#faq");
        this.faqRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        splitCurrentPageUrl[splitCurrentPageUrl.length] ==
        "income-declaration-2"
      ) {
        this.setState({
          reRender: true,
        });
      }

      localStorage.removeItem("scrollTo");
      $(".corp-body").removeClass("open-menu");
      $(".hamburger-menu .bar").removeClass("animate");
    }, 1000);
  }

  render() {
    const { carouselObjects, basicPrice, incomeStatementFieldsObj, render } =
      this.state;
    const { sessionDetails } = this.props;
    let { currentPageName } = this.props;
    currentPageName.values = "Home";

    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {userSelectedLanguage == "sw"
              ? TranslationFile.FaviconAppText[userSelectedLanguage]
              : TranslationFile.FaviconAppText.en}
          </title>
        </Helmet>

        <div>
          <NavBarHeader
            callBack={this.callback}
            uuid={uuidForNavigation}
            isAppPortal={true}
            isHomePage={true}
          />
        </div>

        {render && (
          <div className="carousel-demo">
            <div className="first-container">
              <Carousel
                value={carouselObjects}
                numVisible={1}
                numScroll={1}
                autoplayInterval={4500}
                responsiveOptions={responsiveOptions}
                circular
                itemTemplate={this.carouselRender}
                className="main-carousel-card"
                showIndicators={false}
                showNavigators={false}
              />
            </div>

            <div className="about-div-home" ref={this.howItWorksRef}>
              <DemoSlide />
            </div>

            <div className="prices-div-home" ref={this.priceRef}>
              <Prices callBack={this.callback} />
            </div>

            <div className="other-services-div" ref={this.otherServicesRef}>
              <OtherServices />
            </div>

            <div className="faq-div-home" ref={this.faqRef}>
              <FaqSection />
            </div>
            <ScrolltoTop />
            {/* <Footerpage isHomePage={true} /> */}
            <Footer_contact_tab
              sideBar={this.state.sideBarStatus}
              isCorpPortal={false}
              isHomePage={true}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(home);
