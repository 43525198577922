import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Toast } from "primereact/toast";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import "./Prices.css";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    login: state.login.login,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    pricesToLogin: state.pricesToLogin.pricesToLogin,
  };
};
let cart = [];
let cartSetToLS = [];
let cartValuesLocalData = [];
let data = "";
let userSelectedLanguage;
let userFromLS = "";
class Prices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricesList: [],
      addOn: [],
      isAddExtraUserApplicable: "",
    };
  }

  handleClick(item) {
    const { login } = this.props;
    let loginReducer = login.values;

    if (localStorage.getItem("cartValues") != "") {
      cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
    }

    let otherUsersLS = [];
    if (loginReducer && loginReducer.emailID && loginReducer.userName) {
      otherUsersLS = cartValuesLocalData.filter(
        (obj) => obj.userID != loginReducer.userID
      );
      cartValuesLocalData = cartValuesLocalData.filter(
        (obj) => obj.userID == loginReducer.userID
      );

      if (cartValuesLocalData.length > 0) {
        cart = cartValuesLocalData[0].cartArray;
      } else {
        cart = [];
      }
      let isItemPresentInCart = false;
      let loopCount = 0;
      let isCartEmpty = true;
      cart &&
        cart.forEach((product) => {
          isCartEmpty = false;
          if (item.packageId === product.packageId) {
            isItemPresentInCart = true;
            product.quantity += 1;
            cart.splice(loopCount, 1, product);
          }
          loopCount++;
        });
      if (!isItemPresentInCart) {
        item.quantity = 1;
      }

      if (isCartEmpty || !isItemPresentInCart) {
        let sortedRespObj =
          loginReducer != undefined &&
          cartValuesLocalData.filter(
            (obj) => obj.userID == loginReducer.userID
          );

        if (Object.keys(sortedRespObj).length == 0) {
          cart.push(item);
        } else {
          let existingCartArray = sortedRespObj[0].cartArray;
          existingCartArray.push(item);
        }
      }
      let cartWithUserID = {
        userID: loginReducer != undefined && loginReducer.userID,
        cartArray: cart,
      };

      let filteredPeople =
        loginReducer != undefined &&
        cartSetToLS.filter((item) => item.userID !== loginReducer.userID);
      loginReducer != undefined && filteredPeople.push(cartWithUserID);
      cartSetToLS = filteredPeople;

      loginReducer != undefined && otherUsersLS.push(cartWithUserID);
      localStorage.setItem("cartValues", JSON.stringify(otherUsersLS));
      this.props.callBack(cart, "", "", false);
      this.setState({
        reRender: true,
      });
    }
  }

  componentWillMount() {
    userFromLS = localStorage.getItem("rebelSkoolUser")
      ? JSON.parse(localStorage.getItem("rebelSkoolUser"))
      : "";
    const { prices } = this.props;
    if (localStorage.getItem("cartValues") != "")
      cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
    axios.get("/siteOwner/getTemplatePackages").then((response) => {
      let respData = response.data;
      let filterArray = respData.filter(
        (item) => item.application == "Annual Report"
      );
      if (filterArray.length > 0) {
        prices.values = filterArray;
        filterArray = filterArray.filter((obj) => obj.visibleInGrid);
        this.setState({
          pricesList: filterArray,
        });

      }
    });

    userSelectedLanguage = localStorage.getItem("selectedLanguage");
  }

  pricesResponse() {
    this.state.pricesList.map((item, index) => {
      if (this.state.pricesList[index].quantity === undefined) {
        this.state.pricesList[index].quantity = 1;
      }
    });
  }

  handleClickUnLogged() {
    let { pricesToLogin } = this.props;
    pricesToLogin.values = true;

    document.getElementsByClassName("user-btn")[0].click();
    this.setState({
      reRender: true,
    });
  }

  render() {
    const { pricesList, addOn } = this.state;
    const { login } = this.props;
    this.pricesResponse();
    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }
    return (
      <div className="prices-main-title-div">
        <Toast
          ref={(el) => (this.toast = el)}
          style={{ marginTop: "75px" }}
          position="top-right"
        ></Toast>
        <div className="login-in-prices"></div>

        <div className="prices-main-title">
          <span className="prices-span-main">
            {TranslationFile.priceTitle[userSelectedLanguage]}
          </span>
        </div>

        <section className="section-prices">
          {pricesList &&
            pricesList.length &&
            pricesList.map((item) => (
              <div>
                <div class="col-md-4 col-sm-6" className="pricesIterationDiv">
                  <div class="pricingTable">
                    <div class="pricingTable-header">
                      <span class="heading">{item.title}</span>
                      <span>{item.text1}</span>
                    </div>
                    <div class="pricing-plans">
                      <span class="price-value">
                        <span>{item.price} kr</span>
                      </span>
                    </div>
                    <div class="pricingContent">
                      <ul>
                        <li>{item.packageName}</li>
                        <li>validity : {item.validityPeriod}</li>
                        <li>{item.text3}</li>
                      </ul>
                    </div>
                    <div class="pricingTable-sign-up">
                      <button
                        onClick={() => {
                          userFromLS != null &&
                            userFromLS != undefined &&
                            userFromLS.emailID != undefined &&
                            userFromLS.userName != undefined
                            ? this.handleClick(item)
                            : this.handleClickUnLogged();
                        }}
                        className="btn btn-block btn-default"
                        id="price_btn_AddToCart"
                      >
                        {TranslationFile.priceAddCart[userSelectedLanguage]}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(Prices);
