import React, { useState, useEffect } from "react";
import Config from "../../Configuration/Config";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import "./StatusAlert.css";

export default function StatusAlert() {
  let userSelectedLanguage = localStorage.getItem("selectedLanguage");
  let appStatusBandLS = localStorage.getItem("StatusBand");
  appStatusBandLS = appStatusBandLS && JSON.parse(appStatusBandLS);

  const [statusBarMsg, setStatusBarMsg] = useState("");
  const [statusBarColor, setStatusColor] = useState("");

  useEffect(() => {
    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }
    if (userSelectedLanguage == "sw") {
      setStatusBarMsg(appStatusBandLS.statusTextSw);
    } else {
      setStatusBarMsg(appStatusBandLS.statusTextEn);
    }

    setStatusColor(appStatusBandLS.statusColor);
  }, []);

  return (
    <div
      className="status-alert-div"
      style={{ backgroundColor: statusBarColor }}
    >
      <center>
        <p className="statusAlterP-tag">{statusBarMsg}</p>
      </center>
    </div>
  );
}
