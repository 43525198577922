import React, { Component } from "react";
import "../../Pages/02-CompanyInformation/CompanyInformation.css";
class sliderLabel extends Component {
  constructor(props) {
    super(props);

    this.MIN = 0;
    this.MAX = 3;

    this.state = {
      options: [
        { value: 0, label: "0", leftPos: this.calcOffSet(0) },
        { value: 1, label: "1", leftPos: this.calcOffSet(1) },
        { value: 2, label: "2", leftPos: this.calcOffSet(2) },
        { value: 3, label: "3", leftPos: this.calcOffSet(3) },
      ],
    };

    this.calcOffSet = this.calcOffSet.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  calcOffSet(indexOfElement) {
    /*
     * Offset calculated based on how react-slider calculates its own
     * handle offset. This is my attempt in making them align with each other.
     */

    const sliderWidth = document
      .querySelector(".horizontal-slider")
      .getBoundingClientRect().width;
    const handleWidth = document
      .querySelector(".thumb")
      .getBoundingClientRect().width;
    const sliderUpperBound = sliderWidth - handleWidth;
    const range = this.MAX - this.MIN; //This is the way react-slider does the range calculation
    const ratio = indexOfElement / range;

    return (ratio * sliderUpperBound).toFixed(2);
  }

  handleResize() {
    const optionsCopy = this.state.options.slice();

    const newOptions = optionsCopy.map((o) => {
      o.leftPos = this.calcOffSet(o.value);
      return o;
    });

    this.setState({ options: newOptions });
  }

  render() {
    const { options } = this.state;

    return (
      <div className="slider__labels">
        {options.map((l) => {
          return (
            <div
              className="slider__labels-label"
              key={l.label}
              style={{
                left: l.leftPos + "px",
              }}
            >
              {l.label}
            </div>
          );
        })}
      </div>
    );
  }
}

export default sliderLabel;
