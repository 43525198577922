import React, { Component } from "react";
import axios from "axios";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
import logo from "../../Assests/RebelSkool.png";
import { Image } from "primereact/image";
import { Fieldset } from "primereact/fieldset";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FcApproval } from "react-icons/fc";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import config from "../../Configuration/Config";
import ConfettiExplosion from "react-confetti-explosion";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";

let userSelectedLanguage = localStorage.getItem("selectedLanguage");
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let navigationURL, navigationTarget;
let splittedUrl = window.location.href.split("/");
let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
let currentAppNameFromUrl = splittedUrl[splittedUrl.length - 3];
const annualReport = "/annualreport/";
currentAppNameFromUrl = "/" + currentAppNameFromUrl + "/";
class PaymentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      detailsText: "",
      showBox: false,
      isExploding: false,
      // readyToShipDateTime: "",
    };
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = "";
    if (localData != "undefined") {
      localObject = JSON.parse(localData);
    }

    const userGUID =
      localObject && localObject.userGUID
        ? localObject.userGUID
        : currentReportGUIDFromUrl;

    if (userSessionAuthKeyReturnValue) {
      const config = {
        headers: {
          "User-Session-Auth-Key":
            userSessionAuthKeyReturnValue.headers["User-Session-Auth-Key"],
          language: userSelectedLanguage,
        },
      };
      axios
        .get(
          "/annualReportPackage/paysonGetCheckoutObj?reportGUID=" +
            currentReportGUIDFromUrl,
          config
        )
        .then((response) => {
          // console.log(response.data, "!!!pc");

          if (
            response.data.paymentStatus == null ||
            response.data.paymentStatus == "created"
          ) {
            this.toast.show({
              severity: "error",
              summary: "Payment Not Completed",
              detail:
                "Please Complete your payment. Redirecting to Payment Page",
              life: 2000,
            });
            let reverseNavigationUrl =
              "/annualreport/" + currentReportGUIDFromUrl + "/payment";
            setTimeout(() => {
              this.props.history.push("/payment");
            }, 3000);
          } else if (
            response.data.paymentStatus !== null &&
            (response.data.paymentStatus == "readyToShip" ||
              response.data.paymentStatus == "shipped")
          ) {
            let taxRate = 0.25;
            let exMomsValue = response.data.totalPrice / (1 + taxRate);
            let momsValue = response.data.totalPrice - exMomsValue;
            this.setState({ showBox: true, isExploding: true });
            this.setState({ isLoading: false });

            let dateString = response.data.readyToShipDateTime;
            let date = new Date(dateString);
            // Extract components
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            let day = String(date.getDate()).padStart(2, "0");
            let hours = String(date.getHours()).padStart(2, "0");
            let minutes = String(date.getMinutes()).padStart(2, "0");
            let seconds = String(date.getSeconds()).padStart(2, "0");

            // Combine to "YYYY-MM-DD HH:MM:SS" format
            let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

            this.setState({
              paymentID: response.data.pspCheckoutRef,
              purchaseID: response.data.purchaseId,
              paymentPlatform: response.data.paymentMethod == 0 && "Payson",
              totalIncMoms: response.data.totalPrice,
              moms: momsValue.toFixed(2),
              totalExMoms: exMomsValue.toFixed(2),
              invoiceSentEmail: response.data.emailId,
              readyToShipDateTime: formattedDate,
            });
          } else if (
            response.data.paymentStatus !== null &&
            (response.data.paymentStatus == "cancelled" ||
              response.data.paymentStatus == "expired")
          ) {
            this.toast.show({
              severity: "error",
              summary: "Session Expired",
              detail: "Your Payment Session is expired. Redirecting to Home",
              life: 2000,
            });
            let reverseNavigationUrl = "/annualreport/";
            setTimeout(() => {
              this.props.history.push(config.annualReport.url);
            }, 3000);
          } else if (
            response.data.paymentStatus != null &&
            response.data.paymentStatus == "denied"
          ) {
            this.toast.show({
              severity: "error",
              summary: "Payment Denied",
              detail: "Your Payment is denied by Payson. Redirecting to Home",
              life: 2000,
            });
            setTimeout(() => {
              this.props.history.push(config.annualReport.url);
            }, 3000);
          } else {
            this.setState({ showBox: true, isExploding: true });
            this.setState({ detailsText: "Redirecting to Next Page" });
            setTimeout(() => {
              this.navigateAfterPayment();
            }, 2000);
          }
        })
        .catch((error) => {
          this.toast.show({
            severity: "error",
            summary: error.status,
            detail: error.statusText + ". Redirecting to Home",
            life: 2000,
          });
          setTimeout(() => {
            // this.props.history.push(reverseNavigationUrl);
            this.props.history.push(config.annualReport.url);
          }, 3000);
        });
    }
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data != "" && response.data != null) {
            localStorage.setItem("cartValues", []);

            if (response.data.reportID == null) {
              navigationURL = currentAppNameFromUrl + userGUID + "/MyProfile";
              navigationTarget = "MyProfile";
              this.setState({ navigateBtnLabel: "Go to My Profile" });
            } else {
              navigationURL =
                annualReport + currentReportGUIDFromUrl + "/digitalSignature";
              navigationTarget = "digitalSignature";
              this.setState({
                navigateBtnLabel: "Go to Digital Signature",
              });
            }
          }
        });
    }
  }

  navigateAfterPayment() {
    this.props.history.push(navigationURL);
  }

  render() {
    return (
      <div className="forgot-password-main-div">
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          style={{ marginTop: "80px" }}
        ></Toast>
        {this.state.isLoading ? (
          <div className="ISLoading">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        ) : (
          <div>
            {this.state.showBox && this.state.isExploding && (
              <div
                className="forget-password-container"
                style={{ marginTop: "1%", width: "45%" }}
              >
                <center>
                  <ConfettiExplosion />
                </center>

                <div className="mand-bill-info" style={{ marginTop: "2%" }}>
                  <center>
                    <i
                      className="pi pi-info-circle"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <span style={{ marginRight: "2px" }}>
                      {/* Please Don't go back or refresh this page */}
                      {
                        TranslationFile.refreshthispageText[
                          userSelectedLanguage
                        ]
                      }
                    </span>
                  </center>
                </div>
                <center>
                  <div
                    className="forget-password-logo"
                    style={{ margin: "10px" }}
                  >
                    <Link to="/">
                      <Image src={logo} alt="Image" width="200" />
                    </Link>
                  </div>
                </center>

                <Fieldset
                  legend={
                    this.state.detailsText != ""
                      ? this.state.detailsText
                      : this.state.isLoading
                      ? "Payment Processing.. Please Wait"
                      : "Payment Confirmation"
                  }
                >
                  <div>
                    {this.state.isLoading || !this.state.showBox ? (
                      <div>
                        <center>
                          <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "2rem" }}
                          ></i>
                        </center>
                        <br></br>
                      </div>
                    ) : (
                      <div>
                        <center className="PC-Info">
                          {/* Payment successfully done! */}
                          {TranslationFile.Paymentsuccess[userSelectedLanguage]}
                        </center>
                        <center>
                          <FcApproval style={{ fontSize: "5rem" }} />
                        </center>
                        <div
                          style={{ textAlign: "left" }}
                          className="payment-info-div"
                        >
                          <Divider />
                          <Row>
                            <Col className="PC-Info">
                              {" "}
                              {
                                TranslationFile.PaymentIDText[
                                  userSelectedLanguage
                                ]
                              }
                            </Col>
                            <Col>{this.state.paymentID}</Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col className="PC-Info">
                              {" "}
                              {
                                TranslationFile.PurchaseIDText[
                                  userSelectedLanguage
                                ]
                              }
                            </Col>
                            <Col>{this.state.purchaseID}</Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col className="PC-Info">
                              {
                                TranslationFile.PurchasePlatformText[
                                  userSelectedLanguage
                                ]
                              }
                            </Col>
                            <Col>{this.state.paymentPlatform}</Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col className="PC-Info">
                              {
                                TranslationFile.TotalExMoms[
                                  userSelectedLanguage
                                ]
                              }
                            </Col>
                            <Col>{this.state.totalExMoms} kr</Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col className="PC-Info">
                              {TranslationFile.MomsText[userSelectedLanguage]}
                            </Col>
                            <Col>{this.state.moms} kr</Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col className="PC-Info">
                              {
                                TranslationFile.TotalIncMomsText[
                                  userSelectedLanguage
                                ]
                              }
                            </Col>
                            <Col>{this.state.totalIncMoms} kr</Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col className="PC-Info">
                              {
                                TranslationFile.TransactionDateText[
                                  userSelectedLanguage
                                ]
                              }
                            </Col>
                            <Col>{this.state.readyToShipDateTime}</Col>
                          </Row>
                          <Divider />

                          <Row>
                            <Col className="PC-Info">
                              {/* Your Invoice has been sent to{" "} */}
                              {
                                TranslationFile.urInvoiceSendtoText[
                                  userSelectedLanguage
                                ]
                              }{" "}
                              <span className="PC-invoice-mail">
                                {this.state.invoiceSentEmail}
                              </span>
                            </Col>
                          </Row>

                          <Divider />

                          <center>
                            <Link
                              to={navigationURL}
                              target={
                                navigationTarget == "MyProfile"
                                  ? "_blank"
                                  : "_self"
                              }
                            >
                              <Button
                                label={this.state.navigateBtnLabel}
                                icon="pi pi-arrow-right"
                                iconPos="right"
                                // onClick={() => this.navigateAfterPayment()}
                              />
                            </Link>
                          </center>
                        </div>
                      </div>
                    )}
                  </div>
                </Fieldset>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PaymentConfirmation;
