import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  FcFilingCabinet,
  FcNeutralTrading,
  FcOpenedFolder,
  FcKindle,
  FcApproval,
  FcMoneyTransfer,
  FcSignature,
  FcAbout,
} from "react-icons/fc";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import TranslationFile from "../../15-Translation/locale/Translation_apps.json";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SRUSidebarMenu";
import "./SRUSidebar.css";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import galaxy from "../../../Assests/galaxy.png";
import { setUserSessionAuthKey } from "../../../Component/commonUtils";
import axios from "axios";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    payment: state.payment.payment,
  };
};

let userSelectedLanguage;
let uuid = "/year";
let possibleNavigationPagesList = ["My Dashboard", "Company Information"];
let showInfoToast = false;
let formsArray = [];

const SRUSidebar = ({
  children,
  sideBarStatus,
  switchValue,
  sideBarCallback,
  selectedForms,
}) => {
  const history = useHistory();
  const toast = useRef(null);
  const [position, setPosition] = useState("center");
  const [isReportCreated, setReportCreated] = useState(false);
  const [isInDashboardPage, setInDashboardPage] = useState(false);
  const [hidePaymentMenu, setHidePaymentMenu] = useState(false);
  const [reportAccess, setReportAccess] = useState(null);
  const [selectedSideMenu, setSelectedSideMenu] = useState("");
  const sessionDetailsReducer = useSelector((state) => state.sessionDetails);
  const selectSRUFormReducer = useSelector((state) => state.companyInformation);
  const activeSideBarReducer = useSelector(
    (state) => state.activeSideBar.activeSideBar.values
  );
  uuid = sessionDetailsReducer["sessionDetails"]["values"].uuid;
  const incomedeclaration2SRU = "/income-declaration-2/";
  const ink2 = incomedeclaration2SRU + uuid + "/INK2";
  const ink2r = incomedeclaration2SRU + uuid + "/INK2R";
  const ink2s = incomedeclaration2SRU + uuid + "/INK2S";
  const balanceSheet = incomedeclaration2SRU + uuid + "/balanceSheet";
  const companyInformationUrl = incomedeclaration2SRU + uuid + "/companyInfo";
  const dashboard = incomedeclaration2SRU + uuid + "/dashboard";
  userSelectedLanguage = localStorage.getItem("selectedLanguage");

  const routes = [
    {
      name: (
        <div>
          <button className="new-SRU-report-create-button">
            {/* New Report */}
            {TranslationFile.sidebarNewReport[userSelectedLanguage]}
          </button>
        </div>
      ),
      icon: <img className="new-annual-report-create" src={galaxy}></img>,
      //icon: <FcAddRow className="icon" />,
      path: "/year",
    },
    {
      swName: "Min instrumentpanel",
      name: "My Dashboard",
      icon: <FcOpenedFolder className="icon" />,
      path: dashboard,
    },
    {
      swName: "Företagsinformation",
      name: "Company Information",
      icon: <FcFilingCabinet className="icon" />,
      path: companyInformationUrl,
    },

    {
      swName: "INK2",
      name: "INK2",
      displayName: "Inkomstdeklaration 2",
      icon: <FcKindle className="icon" />,
      path: ink2,
    },
    {
      swName: "INK2R",
      name: "INK2R",
      displayName: "Räkenskapsschema",
      icon: <FcNeutralTrading className="icon" />,
      path: ink2r,
    },
    {
      swName: "INK2S",
      name: "INK2S",
      displayName: "Skattemässiga Justeringar",
      icon: <FcNeutralTrading className="icon" />,
      path: ink2s,
    },

    {
      swName: "Betalning",
      name: "Payment",
      icon: <FcMoneyTransfer className="icon" />,
      path: balanceSheet,
    },

    {
      swName: "Granska och skicka",
      name: "Review And Send",
      icon: <FcMoneyTransfer className="icon" />,
      path: balanceSheet,
    },
    {
      swName: "Digital signatur",
      name: "Digital Signature",
      icon: <FcSignature className="icon" />,
      path: "/info",
    },
    {
      swName: "Hjälp/Exempelrapport",
      name: "Help/Example Report",
      icon: <FcAbout className="icon" />,
      path: "#/fileSIE",
    },
  ];

  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
    sideBarStatus(!isOpen);
  };

  const sideBarReducer = useSelector((state) => state.sideBarReducer);
  const companyInformationReducer = useSelector(
    (state) => state.companyInformation
  );

  const financialYearReducer = useSelector((state) => state.financialYear);
  let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
  let possibleAccessablePagesListLSString = localStorage.getItem(
    "possibleAccessablePages"
  );

  let sideBarReducerValues = [];
  if (null != possibleAccessablePagesListLSString)
    sideBarReducerValues = JSON.parse(possibleAccessablePagesListLSString);
  let splittedUrl = window.location.href.split("/");
  let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
  let currentPageNameFromUrl = splittedUrl[splittedUrl.length - 1];

  if (selectedForms && selectedForms.length > 0) {
    formsArray = selectedForms.map((val) => {
      return val.name;
    });
  }
  useEffect(() => {
    if (activeSideBarReducer != undefined)
      setSelectedSideMenu(activeSideBarReducer[0]);

    if (
      currentPageNameFromUrl == "dashboard" ||
      currentPageNameFromUrl == "MyProfile"
    )
      setInDashboardPage(true);
    else setInDashboardPage(false);

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/SRUHeader/getHeader?reportGuid=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let respForms = response.data.forms;
          if (respForms != "" && respForms != null) {
            respForms = respForms.split(",");
            formsArray = respForms;
          }

          let currentPageFromLS = localStorage.getItem("currentPageName");
          if (response.data.reportID == null) {
            setReportCreated(false);
          } else {
            let reportAccessReq = {
              reportId: response.data.reportID,
            };
            axios
              .post(
                "/SRUHeader/getAccessStatus",
                reportAccessReq,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                switch (response.data) {
                  case 2:
                    //Write
                    setReportAccess(2);
                    break;
                  case 3:
                    //Read Only
                    setReportAccess(3);
                    break;
                  case 4:
                    //No Access
                    setReportAccess(4);
                    break;
                }
              })
              .catch((error) => {
                let noAccessURL =
                  "/income-declaration-2/" +
                  currentReportGUIDFromUrl +
                  "/no-access";
                history.push(noAccessURL);
              });
            setReportCreated(true);
          }
          if (
            response.data.reportID == null &&
            currentPageFromLS &&
            (currentPageFromLS == "Payment" ||
              currentPageFromLS == "Dashboard" ||
              currentPageFromLS == "MyProfile")
          ) {
            setIsOpen(false);
            sideBarStatus(false);
          }
          if (
            response.data.paymentEntryNo > 0 ||
            reportAccess == 2 ||
            reportAccess == 3
          ) {
            setHidePaymentMenu(true);
          }
        });
    }
  }, []);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
    },
    show: {
      opacity: 1,
      width: "auto",
    },
  };
  const newReport = () => {
    localStorage.setItem("sruNavigateTo", "companyInfo");
    localStorage.setItem("navigateToGUID", "");
    localStorage.setItem("navigatedCompanyName", "");
    localStorage.setItem("possibleAccessablePages", "");

    history.push("/income-declaration-2");
    window.location.reload(false);
  };

  const show = (position) => {
    let financialYearReducerValue = financialYearReducer.financialYear.values;
    let companyInformationReducerValue =
      companyInformationReducer.companyInformation.values;

    setPosition(position);
    let isFinancialYearReducerValueEmpty = false;

    if (financialYearReducerValue != undefined) {
      isFinancialYearReducerValueEmpty = true;
      if (Object.keys(financialYearReducerValue).length === 0) {
        isFinancialYearReducerValueEmpty = false;
      }
    }

    if (position == "top") {
      newReport();
      let possibleNavigationPagesList = ["My Dashboard"];
      let possibleNavigationPagesListString = JSON.stringify(
        possibleNavigationPagesList
      );
      localStorage.setItem(
        "possibleAccessablePages",
        possibleNavigationPagesListString
      );
    }
  };

  const callback = (value) => {
    sideBarCallback(value);
  };

  const sideMenuOnClick = (event, selectedSideMenuName) => {
    localStorage.setItem("navigateToGUID", "");

    let possibleAccessablePagesListLS = localStorage.getItem(
      "possibleAccessablePages"
    );

    if (selectedSideMenuName == "Company Information") {
      const incomedeclaration2SRU = "/income-declaration-2/";
      history.push(
        incomedeclaration2SRU + currentReportGUIDFromUrl + "/companyInfo"
      );
    }

    let possibleAccessablePagesListLSArray =
      possibleAccessablePagesListLS &&
      JSON.parse(possibleAccessablePagesListLS);

    let companyInformationReducerValue =
      companyInformationReducer.companyInformation.values;
    callback(false);
    if (
      null == possibleAccessablePagesListLSArray ||
      (null != possibleAccessablePagesListLSArray &&
        !possibleAccessablePagesListLSArray.includes(selectedSideMenuName))
    ) {
      if (!showInfoToast) {
        showInfoToast = true;
        callback(true);
        showInfoToast = false;
      }

      event.preventDefault();
    } else if (
      companyInformationReducerValue != undefined &&
      (companyInformationReducerValue.city == "" ||
        companyInformationReducerValue.postalcode == 0)
    ) {
      if (!showInfoToast) {
        const button = document.evaluate(
          '//*[@class="p-button p-component year-btn-save"]',
          document,
          null,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;

        if (button) {
          button.click();
        }

        showInfoToast = true;

        setTimeout(() => {
          showInfoToast = false;
        }, 2000);
      }
      event.preventDefault();
    }
    setSelectedSideMenu(selectedSideMenuName);
  };

  return (
    <div>
      <Toast ref={toast} className="SRU_sideBarNotAccessableToast" />
      <div
        className={
          isOpen
            ? "SRU_main-container-sidebarOpenClass"
            : "SRU_main-container-sidebarCloseClass"
        }
      >
        <motion.div
          animate={{
            width: isOpen ? "280px" : "50px",
          }}
          className={switchValue ? "SRU_sidebarExtent" : `SRU_sidebar `}
        >
          <div className="SRU_top_section">
            <AnimatePresence>
              {isOpen && (
                <p className="SRU_subtext-sru-report">
                  SRU Files
                  {/* {TranslationFile.sidebarText1[userSelectedLanguage]} */}
                </p>
              )}
            </AnimatePresence>

            <div className="SRU_bars">
              {isOpen == true ? (
                <RxDoubleArrowLeft className="icon" onClick={toggle} />
              ) : (
                <RxDoubleArrowRight className="icon" onClick={toggle} />
              )}
            </div>
          </div>

          <section className="SRU_routes">
            {/* NEW REPORT */}
            {routes[0].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <div
                className="link"
                onClick={() => show("top")}
                style={{ cursor: "pointer" }}
              >
                <div className="icon" style={{ marginRight: "-11px" }}>
                  {routes[0].icon}
                </div>
                <div className="menu-names-link">
                  {isOpen && <div>{routes[0].name}</div>}
                </div>
              </div>
            )}

            {/* MY DASHBOARD */}
            {routes[1].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[1].path}
                // className="link"
                className={
                  children == "MyDashboard" ? "SRU-sidebarActive" : "notesLink"
                }
                activeClassName="active"
                onClick={(e) => sideMenuOnClick(e, routes[1].name)}
              >
                <div className="icon">{routes[1].icon}</div>
                <div className="menu-names-link">
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[1].swName
                        : routes[1].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* Company Information*/}
            {routes[2].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[2].path}
                className={
                  children == "Company Information"
                    ? "SRU-sidebarActive"
                    : "notesLink"
                }
                activeClassName="active"
                onClick={(e) => sideMenuOnClick(e, routes[2].name)}
              >
                <div className="icon">
                  {routes[2].icon}
                  {sideBarReducerValues &&
                    sideBarReducerValues.includes("Company Information") && (
                      <FcApproval className="SRU_tickIcon" />
                    )}
                </div>
                <div className="menu-names-link">
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[2].swName
                        : routes[2].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}

            {/* INK2*/}
            {formsArray && formsArray.includes(routes[3].name) && (
              <div>
                {routes[3].subRoutes ? (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={routes}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                ) : (
                  <NavLink
                    to={routes[3].path}
                    className={
                      children == "INK2" ? "SRU-sidebarActive" : "notesLink"
                    }
                    activeClassName="active"
                    style={{
                      backgroundColor:
                        !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                    }}
                    onClick={(e) => sideMenuOnClick(e, routes[3].name)}
                  >
                    <div className="icon">
                      {routes[3].icon}{" "}
                      {sideBarReducerValues.includes("INK2") && (
                        <FcApproval className="SRU_tickIcon" />
                      )}
                    </div>
                    <div
                      className="menu-names-link"
                      style={{
                        color:
                          !isReportCreated && isInDashboardPage
                            ? "gray"
                            : "black",
                      }}
                    >
                      {isOpen && (
                        <div>
                          {userSelectedLanguage == "sw"
                            ? routes[3].swName
                            : routes[3].name}
                          <span style={{ fontSize: "12px" }}>
                            ({routes[3].displayName})
                          </span>
                        </div>
                      )}
                    </div>
                  </NavLink>
                )}
              </div>
            )}

            {/* INK2R */}
            {formsArray && formsArray.includes(routes[4].name) && (
              <div>
                {routes[4].subRoutes ? (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={routes}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                ) : (
                  <NavLink
                    to={routes[4].path}
                    className={
                      children == "INK2R" ? "SRU-sidebarActive" : "notesLink"
                    }
                    activeClassName="active"
                    style={{
                      backgroundColor:
                        !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                    }}
                    onClick={(e) => sideMenuOnClick(e, routes[4].name)}
                  >
                    <div className="icon">
                      {routes[4].icon}
                      {sideBarReducerValues.includes("INK2R") && (
                        <FcApproval className="SRU_tickIcon" />
                      )}
                    </div>

                    <div
                      className="menu-names-link"
                      style={{
                        color:
                          !isReportCreated && isInDashboardPage
                            ? "gray"
                            : "black",
                      }}
                    >
                      {isOpen && (
                        <div>
                          {userSelectedLanguage == "sw"
                            ? routes[4].swName
                            : routes[4].name}
                          <span style={{ fontSize: "12px" }}>
                            ({routes[4].displayName})
                          </span>
                        </div>
                      )}
                    </div>
                  </NavLink>
                )}
              </div>
            )}

            {/* INK2S */}
            {formsArray && formsArray.includes(routes[5].name) && (
              <div>
                {routes[5].subRoutes ? (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={routes}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                ) : (
                  <NavLink
                    to={routes[5].path}
                    className={
                      children == "INK2S" ? "SRU-sidebarActive" : "notesLink"
                    }
                    activeClassName="active"
                    style={{
                      backgroundColor:
                        !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                    }}
                    onClick={(e) => sideMenuOnClick(e, routes[5].name)}
                  >
                    <div className="icon">
                      {routes[5].icon}
                      {sideBarReducerValues.includes("INK2S") && (
                        <FcApproval className="SRU_tickIcon" />
                      )}
                    </div>

                    <div
                      className="menu-names-link"
                      style={{
                        color:
                          !isReportCreated && isInDashboardPage
                            ? "gray"
                            : "black",
                      }}
                    >
                      {isOpen && (
                        <div>
                          {userSelectedLanguage == "sw"
                            ? routes[5].swName
                            : routes[5].name}
                          <span style={{ fontSize: "12px" }}>
                            ({routes[5].displayName})
                          </span>
                        </div>
                      )}
                    </div>
                  </NavLink>
                )}
              </div>
            )}

            {/* PAYMENT */}
            {!hidePaymentMenu && (
              <div>
                {routes[6].subRoutes ? (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={routes}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                ) : (
                  <NavLink
                    to={routes[6].path}
                    className={
                      children == "Payment" ? "SRU-sidebarActive" : "notesLink"
                    }
                    activeClassName="active"
                    style={{
                      backgroundColor:
                        !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                    }}
                    onClick={(e) => sideMenuOnClick(e, routes[6].name)}
                  >
                    <div className="icon">
                      {routes[6].icon}
                      {sideBarReducerValues.includes("Payment") && (
                        <FcApproval className="SRU_tickIcon" />
                      )}
                    </div>
                    <div
                      className="menu-names-link"
                      style={{
                        color:
                          !isReportCreated && isInDashboardPage
                            ? "gray"
                            : "black",
                      }}
                    >
                      {isOpen && (
                        <div>
                          {userSelectedLanguage == "sw"
                            ? routes[6].swName
                            : routes[6].name}
                        </div>
                      )}
                    </div>
                  </NavLink>
                )}
              </div>
            )}
            {/* REVIEW AND SEND  */}
            {routes[7].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[7].path}
                className={
                  children == "Review And Send"
                    ? "SRU-sidebarActive"
                    : "notesLink"
                }
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[7].name)}
              >
                <div className="icon">
                  {routes[7].icon}
                  {sideBarReducerValues.includes("Review And Send") && (
                    <FcApproval className="SRU_tickIcon" />
                  )}
                </div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[7].swName
                        : routes[7].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}
            {/* DIGITAL SIGNATURE */}
            {routes[8].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[8].path}
                className="link"
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[8].name)}
              >
                <div className="icon">{routes[8].icon}</div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[8].swName
                        : routes[8].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}
            {/* HELP/EXAMPLE REPORT */}
            {routes[9].subRoutes ? (
              <SidebarMenu
                setIsOpen={setIsOpen}
                route={routes}
                showAnimation={showAnimation}
                isOpen={isOpen}
              />
            ) : (
              <NavLink
                to={routes[9].path}
                className="link"
                activeClassName="active"
                style={{
                  backgroundColor:
                    !isReportCreated && isInDashboardPage ? "#E1E1E7" : "",
                }}
                onClick={(e) => sideMenuOnClick(e, routes[9].name)}
              >
                <div className="icon">{routes[9].icon}</div>
                <div
                  className="menu-names-link"
                  style={{
                    color:
                      !isReportCreated && isInDashboardPage ? "gray" : "black",
                  }}
                >
                  {isOpen && (
                    <div>
                      {userSelectedLanguage == "sw"
                        ? routes[9].swName
                        : routes[9].name}
                    </div>
                  )}
                </div>
              </NavLink>
            )}
          </section>
        </motion.div>
      </div>
    </div>
  );
};

export default SRUSidebar;
