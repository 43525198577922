import React, { Component } from "react";
import { Carousel } from "primereact/carousel";
import axios from "axios";
import "./SRUHomePage.css";
import { Button } from "primereact/button";
import SRUNavBarHeader from "../SRUNavBarHeader/SRUNavBarHeader";
import { connect } from "react-redux";
import ScrolltoTop from "../../ScrollTop/ScrollTop";
import TranslationFile from "../../15-Translation/locale/Translation_apps.json";
import Prices from "../SRUPrices/SRUPrices";
import SRUDemoSlide from "../SRUDemoSlide/SRUDemoSlide";
import FaqSection from "../../FAQ/FAQSection";
import carousel1 from "../../../Assests/carousel1.webp";
// import carousel2 from "../../../Assests/carousel2.webp";
import carousel3 from "../../../Assests/carousel3.jpg";
import Footer_contact_tab from "../../Footerpage/Footer_contact_tab";
import { Helmet } from "react-helmet";
import config from "../../../Configuration/Config";

let uuidForNavigation = "";
const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    incomeStatement: state.incomeStatement.incomeStatement,
    sessionDetails: state.sessionDetails,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    language: state.language.language,
    activeSideBar: state.activeSideBar.activeSideBar,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    pricesToLogin: state.pricesToLogin.pricesToLogin,
  };
};

const responsiveOptions = [
  {
    breakpoint: "1199px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "991px",
    numVisible: 1,
    numScroll: 1,
  },
];

let userSelectedLanguage;
let imgArray = [];

class home extends Component {
  constructor(props) {
    super(props);
    this.priceRef = React.createRef();
    this.howItWorksRef = React.createRef();
    this.faqRef = React.createRef();

    this.state = {
      carouselObjects: [],
      refresh: false,
      selectedLanguage: "sw",
      cartValueToHeader: [],
      setCart: "",
      handleChange: "",
      hover: false,
      basicPrice: "",
      render: false,
    };
    this.carouselRender = this.carouselRender.bind(this);
    this.annualReport = this.annualReport.bind(this);
  }

  componentWillMount() {
    let { currentPageName, sessionDetails } = this.props;
    window.scrollTo(0, 0);
    localStorage.removeItem("possibleAccessablePages");
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    if (sessionUUIDFromLS) uuidForNavigation = sessionUUIDFromLS;
    let scrollToLs = localStorage.getItem("scrollTo");

    let sruNavigateTo = localStorage.getItem("sruNavigateTo");
    if (sruNavigateTo == "companyInfo") {
      setTimeout(() => {
        localStorage.removeItem("sruNavigateTo");
        this.props.history.push(
          "/income-declaration-2/" +
          sessionDetails["sessionDetails"].values.uuid +
          "/companyInfo"
        );
      }, 1000);
      // console.log("@@ OUT");
    }

    if (scrollToLs == "companyInfo") {
      localStorage.removeItem("scrollTo");

      const annualReport = "/income-declaration-2/";

      this.setState({
        render: false,
      });

      setTimeout(() => {
        this.props.history.push(
          annualReport +
          sessionDetails["sessionDetails"].values.uuid +
          "/companyInfo"
        );
      }, 1000);
    } else {
      this.setState({
        render: true,
      });

      currentPageName.values = "Home";
      userSelectedLanguage = localStorage.getItem("selectedLanguage");

      this.settingCarouselDesc(null, "img");

      axios.get("/siteOwner/getTemplatePackages").then((response) => {
        let basicPrice = [];
        response.data && response.data.forEach((val, idx) => {
          if (val.packageId == 100 || val.packageId == 900) {
            basicPrice.push(val.price);
          }
        });
        setTimeout(() => {
          this.settingCarouselDesc(basicPrice, "price");
        }, 1000);
      });
    }
  }

  componentDidMount() {
    let { companyInformation, financialYear, sideBarReducer } = this.props;

    let navigateTo = localStorage.getItem("navigateTo");

    if (navigateTo != "") {
      if (navigateTo == "dashboard") {
        const incomedeclaration2SRU = "/income-declaration-2/";
        localStorage.setItem("navigateTo", "");
        const guid = localStorage.getItem("sessionUUID");
        this.props.history.push(incomedeclaration2SRU + guid + "/dashboard");
        window.location.reload(false);
      } else if (navigateTo == "companyInformation") {
        this.annualReport();
      }
    }

    if (companyInformation != undefined) {
      companyInformation.isExistingOrganization = false;
      companyInformation.organizationname = "";
      companyInformation.organizationnumber = "";
      companyInformation.postalcode = "";
      companyInformation.city = "";
    }

    if (sideBarReducer.values != undefined) {
      sideBarReducer.values = [];
    }

    if (financialYear != undefined) {
      financialYear.values = {};
    }

    let currentPageUrl = window.location.href;
    let splitCurrentPageUrl = currentPageUrl.split("/");
    this.scrollTo();
  }

  settingCarouselDesc(response, field) {
    let carouselUrlsWithDescArray = [
      {
        id: 2,
        title: TranslationFile.NavbarSRUfiles[userSelectedLanguage],
        price: "",
        buttonlabel: TranslationFile.NavbarTryForFree[userSelectedLanguage],
        img: "",
        paragraph: TranslationFile.SRUCarouselPara1[userSelectedLanguage],
        onlyfor: TranslationFile.onlyfor[userSelectedLanguage],
        img: carousel3,
      },
      {
        id: 1,
        title: TranslationFile.SRUcarouselTitleAccounting[userSelectedLanguage],
        price: "",
        buttonlabel: TranslationFile.NavbarTryForFree[userSelectedLanguage],
        img: "",
        paragraph: TranslationFile.SRUCarouselPara2[userSelectedLanguage],
        onlyfor: TranslationFile.onlyfor[userSelectedLanguage],
        img: carousel1,
      },
    ];

    null != response &&
      response &&
      response.length &&
      response.map((i, idx) => {
        let carouselObj = carouselUrlsWithDescArray.filter(
          (obj) => obj.id == idx + 1
        );
        carouselObj[0]["price"] = i;
        this.setState({ reRender: true });

        if (response.length == idx + 1) {
          this.setState({ carouselObjects: carouselUrlsWithDescArray });
        }
      });
  }

  carouselRender(product) {
    return (
      <div className="SRU-product-item">
        <div className="SRU-product-item-content">
          <div
            className={
              product.id == 1
                ? "SRU-upper-text-carousel-left"
                : "SRU-upper-text-carousel-right"
            }
          >
            {product.paragraph}
            <br></br>
            <span className="SRU-pageCompanyDetail">{product.title}</span>
            <span className="SRU-price-tag-color">
              {product.onlyfor} {product.price} kr
            </span>
            <Button
              label={product.buttonlabel}
              onClick={() => this.annualReport(product)}
              id="SRU-btn_Begin"
              className="SRU-btn_Begin"
            />
          </div>

          <img src={product.img} alt={product} className="SRU-product-image" />
        </div>
      </div>
    );
  }

  annualReport(product) {
    if (product && product.id == 2) {
      this.sruPage();
    } else {
      const { sessionDetails } = this.props;
      const { activeSideBar } = this.props;
      let selectedSideBar = [];
      selectedSideBar.push("Company Information");
      activeSideBar.values = selectedSideBar;
      setTimeout(() => {
        sessionDetails["sessionDetails"].values.currentPage = "companyInfo";
        sessionDetails["sessionDetails"].values.IsAnnualReportSubmitted = false;

        const incomedeclaration2SRU = "income-declaration-2/";
        let sessionUUIDFromLS = localStorage.getItem("sessionUUID");

        // console.log(
        //   config,
        //   ">> CONFIG",
        //   config.SRU.url + "/" + sessionUUIDFromLS + "/" + "companyInfo"
        // );

        window.location.href =
          config.SRU.url + "/" + sessionUUIDFromLS + "/" + "companyInfo";

        // );
      }, 1000);
    }
  }

  sruPage() {
    const sruPage = "/income-declaration-2/";
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    window.location.href = sruPage + sessionUUIDFromLS + "/companyInfo";
  }

  languageValue = (e) => {
    this.setState({
      selectedLanguage: e,
    });
  };

  callback = (annualReport, session, link, status) => {
    const { sessionDetails } = this.props;
    this.scrollTo();
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      this.props.history.push(annualReport + sessionUUIDFromLS + link);
      // window.location.reload(false);
    }
    this.scrollTo();
    this.setState({
      reRender: true,
    });
    //
  };

  scrollTo() {
    let currentPageUrl = window.location.href;
    let splitCurrentPageUrl = currentPageUrl.split("/");

    let scrollToLs = localStorage.getItem("scrollTo");

    setTimeout(() => {
      if (
        splitCurrentPageUrl[splitCurrentPageUrl.length - 1] == "#prices" ||
        scrollToLs == "#prices"
      ) {
        window.history.pushState(null, "", "/income-declaration-2/#prices");
        this.priceRef.current.scrollIntoView({
          behavior: "smooth", block: "center",
          inline: "center",
        });
      } else if (
        splitCurrentPageUrl[splitCurrentPageUrl.length - 1] ==
        "#how-it-works" ||
        scrollToLs == "#how-it-works"
      ) {
        window.history.pushState(
          null,
          "",
          "/income-declaration-2/#how-it-works"
        );
        this.howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        splitCurrentPageUrl[splitCurrentPageUrl.length - 1] == "#faq" ||
        scrollToLs == "#faq"
      ) {
        window.history.pushState(null, "", "/income-declaration-2/#faq");
        this.faqRef.current.scrollIntoView({
          behavior: "smooth", block: "center",
          inline: "center",
        });
      } else if (
        splitCurrentPageUrl[splitCurrentPageUrl.length] ==
        "income-declaration-2"
      ) {
        this.setState({
          reRender: true,
        });
      }

      localStorage.removeItem("scrollTo");
    }, 1000);
  }

  render() {
    const { carouselObjects, basicPrice, incomeStatementFieldsObj, render } =
      this.state;
    const { sessionDetails } = this.props;
    let { currentPageName } = this.props;
    currentPageName.values = "Home";

    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {userSelectedLanguage == "sw"
              ? TranslationFile.FaviconAppText[userSelectedLanguage]
              : TranslationFile.FaviconAppText.en}
          </title>
        </Helmet>

        <div>
          <SRUNavBarHeader
            callBack={this.callback}
            uuid={uuidForNavigation}
            isAppPortal={true}
            isHomePage={true}
          />
        </div>

        {render && (
          <div className="SRU-carousel-demo">
            <div className="SRU-first-container">
              <Carousel
                value={carouselObjects}
                numVisible={1}
                numScroll={1}
                autoplayInterval={3500}
                responsiveOptions={responsiveOptions}
                circular
                itemTemplate={this.carouselRender}
                className="SRU-main-carousel-card"
                showIndicators={false}
                showNavigators={false}
              />
            </div>
            <div className="SRU-about-div-home" ref={this.howItWorksRef}>
              <SRUDemoSlide />
            </div>
            <div className="SRU-prices-div-home" ref={this.priceRef}>
              <Prices callBack={this.callback} />
            </div>

            <div className="SRU-faq-div-home" ref={this.faqRef}>
              <FaqSection callBack={this.callback} />
            </div>
            <ScrolltoTop />
            {/* <Footerpage isHomePage={true} /> */}
            <Footer_contact_tab
              sideBar={this.state.sideBarStatus}
              isCorpPortal={false}
              isHomePage={true}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(home);
