import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { TabView, TabPanel } from "primereact/tabview";
import { Image } from "primereact/image";
import logo from "../../Assests/RebelSkool.png";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { validateEmail } from "./utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { FcApproval, FcCancel } from "react-icons/fc";
import { connect } from "react-redux";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import { SignUpVerifyEmail } from "./SignUpVerifyEmail";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
import config from "../../Configuration/Config";
import $ from "jquery";
import "./Login.css";

import usePasswordValidator from "./usePasswordValidator";
import { CreateForgetPasswordLink } from "./CreateForgetPasswordLink";
import ForgetPassword from "./ForgetPassword";
import { RiArrowDownSFill } from "react-icons/ri";
import { HashLink } from "react-router-hash-link";
// import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Link } from "react-scroll";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    incomeStatement: state.incomeStatement.incomeStatement,
    sessionDetails: state.sessionDetails.sessionDetails,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    language: state.language.language,
    activeSideBar: state.activeSideBar.activeSideBar,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    login: state.login.login,
    pricesToLogin: state.pricesToLogin.pricesToLogin.values,
  };
};

let renderOneTime = true;
let loggedIn = "";
let userSelectedLanguage,
  userTimeZone = "",
  statusBandFromLS = "";
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let rebelSkoolUserLS = localStorage.getItem("rebelSkoolUser");
let rebelSkoolUserLSParse = "";
class LoginNew extends Component {
  constructor(props) {
    super(props);
    this.valueRef = React.createRef();
    this.state = {
      loginSuccess: false,
      loginSideBarVisible: false,
      activeIndex: 1,
      activeSubTabIndex: 1,
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      registrationNo: "",
      companyName: "",
      address: "",
      postalCode: "",
      city: "",
      iAcceptChecked: false,
      userNameFromResponse: "",
      userRoleFromResponse: "",
      isAccountingCompanyFromResponse: false,
      emailNotExists: false,
      emailAlreadyExists: false,
      isActiveEmailId: true,
      signUpType: "private",
      privateUserName: "",
      privateUserLastName: "",
      privateEmailId: "",
      privatePassword: "",
      privateConfirmPassword: "",
      isPrivateConfirmPasswordBlur: false,
      isPrivatePasswordMismatch: true,
      isPrivateEmailIdValid: true,
      iPrivateAcceptChecked: false,
      isPrivateEmailAlreadyExists: false,
      accountingCompanyRegNo: "",
      accountingCompanyName: "",
      accountingCompanyAddress: "",
      accountingCompanyPostalCode: "",
      accountingCompanyCity: "",
      accountingCompanyUserName: "",
      accountingCompanyUserLastName: "",
      accountingCompanyEmailId: "",
      accountingCompanyPassword: "",
      accountingCompanyConfirmPassword: "",
      isAccountingCompanyRegNoAlreadyExists: false,
      isAccountingCompanyPasswordMismatch: true,
      isAccountingCompanyConfirmPasswordBlur: false,
      isAccountingCompanyEmailIdValid: true,
      iAccountingCompanyAcceptChecked: false,
      isAccountingCompanyEmailAlreadyExists: false,
      forgotPasswordDialogVisible: false,
      resetEmail: "",
      emailExistsAlready: false,
      sessionUUID: "",
      emailDoesNotExists: false,
      redirectToForgetPassword: false,
      showEmailVerificationMessage: false,
      showEmailVerificationNeed: false,
      resetPasswordBtnLoading: false,
    };
  }

  componentWillMount() {
    userTimeZone = localStorage.getItem("userTimeZone");
    statusBandFromLS =
      localStorage.getItem("StatusBand") &&
      JSON.parse(localStorage.getItem("StatusBand"));
    let openLoginSidebar = localStorage.getItem("openLoginSidebar");
    this.setState({
      loginSideBarVisible: JSON.parse(openLoginSidebar),
    });
    localStorage.removeItem("openLoginSidebar");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    const { sessionDetails, pricesToLogin } = this.props;

    if (rebelSkoolUserLS != "undefined") {
      rebelSkoolUserLSParse = JSON.parse(rebelSkoolUserLS);
    }

    if (
      rebelSkoolUserLSParse != null &&
      rebelSkoolUserLSParse.userName != "" &&
      rebelSkoolUserLSParse.userName != undefined
    ) {
      this.setState({
        loginSuccess: true,
        usernameFromResponse: rebelSkoolUserLSParse.userName,
        userRoleFromResponse: rebelSkoolUserLSParse.userRole,
        isAccountingCompanyFromResponse:
          rebelSkoolUserLSParse.isAccountingCompany,
      });
    }
  }
  componentDidMount() {
    const { sessionDetails } = this.props;
    // let sessionfromHeader = this.props.uuid
    //

    setTimeout(() => {
      if (
        sessionDetails["values"] != undefined &&
        sessionDetails["values"]["uuid"].length > 0
      ) {
        this.setState({
          sessionUUID: sessionDetails.values.uuid,
        });
      }
    }, 1000);
    // this.setState({
    //   sessionUUID: sessionDetails.sessionDetails.values.uuid,
    // });
  }

  loginSideBarOnClick() {
    this.setState({
      loginSideBarVisible: false,
    });
  }

  tabOnChange(e, tabName) {
    setTimeout(() => {
      // loadCaptchaEnginge(4, "#DBE2EF", "#3F72AF", "numbers");
    }, 50);
    if (tabName == "parentTab") {
      this.setState({
        activeIndex: e.index,
      });
    } else {
      this.setState({
        activeSubTabIndex: e.index,
      });
    }

    this.setState({
      email: "",
      password: "",
      confirmPassword: "",
      emailNotExists: false,
      isActiveEmailId: true,
      privateUserName: "",
      privateEmailId: "",
      privatePassword: "",
      privateConfirmPassword: "",
      isPrivateConfirmPasswordBlur: false,
      isPrivatePasswordMismatch: true,
      isPrivateEmailIdValid: true,
      accountingCompanyName: "",
      accountingCompanyAddress: "",
      accountingCompanyPostalCode: "",
      accountingCompanyCity: "",
      accountingCompanyUserName: "",
      accountingCompanyUserLastName: "",
      accountingCompanyEmailId: "",
      accountingCompanyPassword: "",
      accountingCompanyConfirmPassword: "",
      isAccountingCompanyRegNoAlreadyExists: false,
      isAccountingCompanyEmailIdValid: true,
      iAcceptChecked: false,
      iPrivateAcceptChecked: false,
      iAccountingCompanyAcceptChecked: false,
    });
  }

  emailOnChange(e) {
    this.setState({
      email: e,
    });
  }

  emailOnBlur() {
    const { email, emailNotExists } = this.state;
    if (email != "") {
      let userLoginDetails = {
        emailID: email,
      };
      axios
        .post("/user/checkIfEmailExists", userLoginDetails)
        .then((response) => {
          if (response.data) {
            this.setState({
              emailNotExists: false,
            });

            axios
              .get("/user/getByEmail?emailId=" + email)
              .then((userDetailsByEmailResponse) => {
                let userDetailsByEmail = userDetailsByEmailResponse.data;
                if (!userDetailsByEmail.isEmailVerified) {
                  this.setState({ showEmailVerificationNeed: true });
                }
              });

            axios
              .get(
                "/user/getUserActiveStatus?emailId=" + userLoginDetails.emailID
              )
              .then((responseEmailIdStatus) => {
                if (responseEmailIdStatus.data) {
                  this.setState({
                    isActiveEmailId: true,
                  });
                } else {
                  this.setState({
                    isActiveEmailId: false,
                  });
                }
              });
          } else {
            this.setState({
              emailNotExists: true,
            });
          }
        });
    } else {
      this.setState({
        emailNotExists: false,
      });
    }
  }

  privateEmailOnChange(e) {
    this.setState({
      privateEmailId: e,
    });
    this.resetAccountingCompanyRelatedStates();
  }

  privateEmailOnBlur() {
    const { privateEmailId } = this.state;
    if (privateEmailId != "") {
      let isValidEmailAddress = validateEmail(privateEmailId);
      if (isValidEmailAddress) {
        this.setState({
          isPrivateEmailIdValid: true,
        });

        let userLoginDetails = {
          emailID: privateEmailId,
        };
        axios
          .post("/user/checkIfEmailExists", userLoginDetails)
          .then((response) => {
            if (response.data) {
              this.setState({
                isPrivateEmailAlreadyExists: true,
              });
            } else {
              this.setState({
                isPrivateEmailAlreadyExists: false,
              });
            }
          });
      } else {
        this.setState({
          isPrivateEmailIdValid: false,
        });
      }
    } else {
      this.setState({
        isPrivateEmailAlreadyExists: false,
      });
    }
  }

  accountingCompanyEmailIdOnBlur() {
    const { accountingCompanyEmailId, isAccountingCompanyEmailIdValid } =
      this.state;
    if (accountingCompanyEmailId != "") {
      let isValidEmailAddress = validateEmail(accountingCompanyEmailId);

      if (isValidEmailAddress) {
        this.setState({
          isAccountingCompanyEmailIdValid: true,
        });

        let userLoginDetails = {
          emailID: accountingCompanyEmailId,
        };
        axios
          .post("/user/checkIfEmailExists", userLoginDetails)
          .then((response) => {
            if (response.data) {
              this.setState({
                isAccountingCompanyEmailAlreadyExists: true,
              });
            } else {
              this.setState({
                isAccountingCompanyEmailAlreadyExists: false,
              });
            }
          });
      } else {
        this.setState({
          isAccountingCompanyEmailIdValid: false,
        });
      }
    } else {
      this.setState({
        isAccountingCompanyEmailAlreadyExists: false,
      });
    }
  }

  passwordOnChange(e) {
    this.setState({
      password: e,
    });
  }

  passwordOnBlur() {
    const { password } = this.state;
    if (password != "") {
    }
  }

  privatePasswordOnChange(e) {
    this.setState({
      privatePassword: e,
    });
    this.resetAccountingCompanyRelatedStates();
  }

  privatePasswordOnBlur() {
    const { privateConfirmPassword, privatePassword } = this.state;
    if (privateConfirmPassword != "") {
      if (privatePassword == privateConfirmPassword) {
        this.setState({
          isPrivatePasswordMismatch: false,
        });
      } else {
        this.setState({
          isPrivatePasswordMismatch: true,
        });
      }
    }
  }

  privateConfirmPasswordOnChange(e) {
    this.setState({
      privateConfirmPassword: e,
    });
    this.resetAccountingCompanyRelatedStates();
  }

  privateConfirmPasswordOnBlur() {
    const { privateConfirmPassword, privatePassword } = this.state;
    if (privateConfirmPassword != "") {
      this.setState({
        isPrivateConfirmPasswordBlur: true,
      });
      if (privateConfirmPassword == privatePassword) {
        this.setState({
          isPrivatePasswordMismatch: false,
        });
      } else {
        this.setState({
          isPrivatePasswordMismatch: true,
        });
      }
    }
  }

  accountingCompanyPasswordOnBlur() {
    const { accountingCompanyConfirmPassword, accountingCompanyPassword } =
      this.state;

    if (accountingCompanyConfirmPassword != "") {
      if (accountingCompanyPassword == accountingCompanyConfirmPassword) {
        this.setState({
          isAccountingCompanyPasswordMismatch: false,
        });
      } else {
        this.setState({
          isAccountingCompanyPasswordMismatch: true,
        });
      }
    }
  }

  accountingCompanyConfirmPasswordOnBlur() {
    const { accountingCompanyConfirmPassword, accountingCompanyPassword } =
      this.state;
    if (accountingCompanyConfirmPassword != "") {
      this.setState({
        isAccountingCompanyConfirmPasswordBlur: true,
      });
      if (accountingCompanyConfirmPassword == accountingCompanyPassword) {
        this.setState({
          isAccountingCompanyPasswordMismatch: false,
        });
      } else {
        this.setState({
          isAccountingCompanyPasswordMismatch: true,
        });
      }
    }
  }

  loginButtonOnClick(email, password) {
    const { login, currentPageName } = this.props;
    let userEmailPasswordDetails = {
      emailID: email,
      password: password,
    };

    if (
      userEmailPasswordDetails.emailID != "" &&
      userEmailPasswordDetails.password != ""
    ) {
      axios
        .post("/user/loginAndGetUser", userEmailPasswordDetails)
        .then((response) => {
          if (
            response.data.emailID !== null &&
            response.data.userName !== null
          ) {
            const userAuthKey = response.headers["user-session-auth-key"];
            this.setState({
              loginSuccess: true,
              loginSideBarVisible: false,
              usernameFromResponse: response.data.userName,
              userRoleFromResponse: response.data.userRole,
              isAccountingCompanyFromResponse:
                response.data.isAccountingCompany,
            });
            login.values = response.data;
            response.data["User-Session-Auth-Key"] = userAuthKey;
            localStorage.setItem(
              "rebelSkoolUser",
              JSON.stringify(response.data)
            );
            if (
              currentPageName &&
              (currentPageName.values == "Home" ||
                currentPageName.values == "home")
            ) {
              localStorage.setItem("navigateTo", "dashboard");
            }
            let navigateTo = localStorage.getItem("navigateTo");

            let splittedUrl = window.location.href.split("/");
            let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

            let updateCreatedByUserId = {
              createdByUserID: response.data.userID,
              reportGUID: currentReportGUIDFromUrl,
            };

            axios
              .post(
                "/annualReport/updateCreatedByUserId",
                updateCreatedByUserId
              )
              .then((response) => { });
            window.location.reload(false);
          } else {
            this.toast.show({
              severity: "error",
              summary: TranslationFile.errorText[userSelectedLanguage],
              detail: TranslationFile.InvalidPasswordText[userSelectedLanguage],
              life: 2000,
            });
          }
        });
    }
  }

  signUpTypeRadioBtnOnClick(e, radioBtnLabel) {
    setTimeout(() => {
    }, 50);

    this.setState({
      signUpType: e.value,
      email: "",
      password: "",
      confirmPassword: "",
      emailNotExists: false,
      isActiveEmailId: true,
    });
  }

  footerContent() {
    return (
      <div>
        <Button
          label={TranslationFile.DoneBtn[userSelectedLanguage]}
          icon="pi pi-check"
          autoFocus
        />
      </div>
    );
  }

  termsAndConditons(subTab) {
    const {
      iAcceptChecked,
      iPrivateAcceptChecked,
      iAccountingCompanyAcceptChecked,
    } = this.state;

    return (
      <div className="login-terms-condition">
        {/* <Checkbox
          onChange={(e) =>
            subTab == "private"
              ? this.setState({ iPrivateAcceptChecked: e.checked })
              : this.setState({ iAccountingCompanyAcceptChecked: e.checked })
          }
          checked={
            subTab == "private"
              ? iPrivateAcceptChecked
              : iAccountingCompanyAcceptChecked
          }
        ></Checkbox> */}
        <span>
          {/* * I accept the  */}
          {TranslationFile.loginAcceptText[userSelectedLanguage]} RebelSkool
        </span>
        <span>
          <a
            className="classcolourterm"
            href={config.corporatePage.url + "terms-of-usage"}
            target="_blank"
          >
            {TranslationFile.loginTermsText[userSelectedLanguage]}
            {/* Terms & Conditions{" "} */}
          </a>
        </span>
      </div>
    );
  }

  showError(errorMessage) {
    this.toast.show({
      severity: "error",
      summary: TranslationFile.errorText[userSelectedLanguage],
      detail: errorMessage,
      life: 2000,
    });
  }

  signedUpSuccessfully(tabHeader) {
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = "";
    if (localData != "undefined") {
      localObject = JSON.parse(localData);
    }

    let splittedUrl = window.location.href.split("/");
    let currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    const {
      privateUserName,
      privateUserLastName,
      privateEmailId,
      privatePassword,
      privateConfirmPassword,
      accountingCompanyRegNo,
      accountingCompanyName,
      accountingCompanyAddress,
      accountingCompanyPostalCode,
      accountingCompanyCity,
      accountingCompanyUserName,
      accountingCompanyUserLastName,
      accountingCompanyEmailId,
      accountingCompanyPassword,
      accountingCompanyConfirmPassword,
    } = this.state;

    let userDetails = {
      createdByUserID: 0,
      userRole: "Admin",
    };

    let isAllFieldFilled = false,
      isAccountingCompany = false;

    if (tabHeader == "private") {
      if (
        privateUserName != "" &&
        privateUserLastName != "" &&
        privateEmailId != "" &&
        privatePassword != "" &&
        privateConfirmPassword != ""
      ) {
        userDetails.userName = privateUserName;
        userDetails.firstName = privateUserName;
        userDetails.lastName = privateUserLastName;
        userDetails.emailID = privateEmailId;
        userDetails.password = privatePassword;
        userDetails.reportGUID = currentReportGUIDFromUrl;
        isAllFieldFilled = true;
        isAccountingCompany = false;
      }
    } else {
      if (
        accountingCompanyRegNo != "" &&
        accountingCompanyName != "" &&
        accountingCompanyUserName != "" &&
        accountingCompanyUserLastName != "" &&
        accountingCompanyEmailId != "" &&
        accountingCompanyPassword != "" &&
        accountingCompanyConfirmPassword != ""
      ) {
        userDetails.userName = accountingCompanyUserName;
        userDetails.firstName = accountingCompanyUserName;
        userDetails.lastName = accountingCompanyUserLastName;
        userDetails.emailID = accountingCompanyEmailId;
        userDetails.password = accountingCompanyPassword;
        userDetails.accountingCompanyName = accountingCompanyName;
        userDetails.registrationNo = accountingCompanyRegNo;
        userDetails.address = accountingCompanyAddress;
        if (accountingCompanyPostalCode == null) {
          userDetails.postalCode = 0;
        } else {
          userDetails.postalCode = accountingCompanyPostalCode;
        }
        userDetails.city = accountingCompanyCity;
        isAllFieldFilled = true;
        isAccountingCompany = true;
        userDetails.isAdminUser = true;
        userDetails.reportGUID = currentReportGUIDFromUrl;
      }
    }

    if (isAllFieldFilled) {
      if (isAccountingCompany) {
        axios
          .post("/accountingCompany/initializeWithUserAndAccess", userDetails)
          .then((response) => {
            if (response.data) {
              SignUpVerifyEmail(userDetails.emailID, userDetails.password);
              this.setState({ showEmailVerificationMessage: true });
              this.toast.show({
                severity: "success",

                summary: TranslationFile.SuccessText[userSelectedLanguage],
                detail: (
                  <div>
                    {
                      TranslationFile.CheckEmailForVerification[
                        userSelectedLanguage
                      ]
                    }
                    <br></br>
                    {
                      TranslationFile.CheckEmailForVerificationInSpan[
                        userSelectedLanguage
                      ]
                    }
                  </div>
                ),

                sticky: true,
                closable: true,
              });
              this.setState({
                loginSideBarVisible: false,
              });
              setTimeout(() => {
                this.setState({
                  accountingCompanyRegNo: "",
                  accountingCompanyName: "",
                  accountingCompanyAddress: "",
                  accountingCompanyPostalCode: "",
                  accountingCompanyCity: "",
                  accountingCompanyUserName: "",
                  accountingCompanyUserLastName: "",
                  accountingCompanyEmailId: "",
                  accountingCompanyPassword: "",
                  accountingCompanyConfirmPassword: "",
                });
              }, 1000);
            }
          });
      } else {
        axios.post("/user/insert", userDetails).then((response) => {
          if (response.data) {
            this.setState({
              loginSideBarVisible: false,
            });
            SignUpVerifyEmail(userDetails.emailID, userDetails.password);
            this.setState({ showEmailVerificationMessage: true });
            this.toast.show({
              severity: "success",
              summary: TranslationFile.SuccessText[userSelectedLanguage],
              detail:
              (
                <div>
                  {
                    TranslationFile.CheckEmailForVerification[
                      userSelectedLanguage
                    ]
                  }
                  <br></br>
                  {
                    TranslationFile.CheckEmailForVerificationInSpan[
                      userSelectedLanguage
                    ]
                  }
                </div>
              ),
              baseZIndex: 1000,
              sticky: true,
              closable: true,
            });
          }
        });
      }

      this.setState({
        emailID: userDetails.emailID,
        password: userDetails.password,
      });
    }
  }

  accountingCompanyRegNoOnBlur() {
    const { accountingCompanyRegNo, isAccountingCompanyRegNoAlreadyExists } =
      this.state;
    axios
      .get(
        "/accountingCompany/checkRegistrationNoExists?registrationNo=" +
        accountingCompanyRegNo
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            isAccountingCompanyRegNoAlreadyExists: true,
          });
        } else {
          this.setState({
            isAccountingCompanyRegNoAlreadyExists: false,
          });
        }
      });
  }

  privateUserNameOnChange(e, name) {
    if (name == "name") {
      this.setState({
        privateUserName: e.target.value,
      });
    } else {
      this.setState({
        privateUserLastName: e.target.value,
      });
    }

    this.resetAccountingCompanyRelatedStates();
  }

  resetAccountingCompanyRelatedStates() {
    this.setState({
      accountingCompanyName: "",
      accountingCompanyAddress: "",
      accountingCompanyPostalCode: "",
      accountingCompanyCity: "",
      accountingCompanyUserName: "",
      accountingCompanyUserLastName: "",
      accountingCompanyEmailId: "",
      accountingCompanyPassword: "",
      accountingCompanyConfirmPassword: "",
      isAccountingCompanyRegNoAlreadyExists: false,
      isAccountingCompanyPasswordMismatch: true,
      isAccountingCompanyConfirmPasswordBlur: false,
      isAccountingCompanyEmailIdValid: true,
      iAccountingCompanyAcceptChecked: false,
      isAccountingCompanyEmailAlreadyExists: false,
    });
  }

  resetPrivateRelatedStates() {
    this.setState({
      privateUserName: "",
      privateEmailId: "",
      privatePassword: "",
      privateConfirmPassword: "",
      isPrivateConfirmPasswordBlur: false,
      isPrivatePasswordMismatch: true,
      isPrivateEmailIdValid: true,
      iPrivateAcceptChecked: false,
      isPrivateEmailAlreadyExists: false,
    });
  }

  accountingCompanyRegNoOnChange(e) {
    this.setState({
      accountingCompanyRegNo: e.target.value,
    });
    this.resetPrivateRelatedStates();
  }

  accountingCompanyNameOnChange(e) {
    this.setState({
      accountingCompanyName: e.target.value,
    });
    this.resetPrivateRelatedStates();
  }

  accountingCompanyAddressOnChange(e) {
    this.setState({
      accountingCompanyAddress: e.target.value,
    });
    this.resetPrivateRelatedStates();
  }

  accountingCompanyPostalCodeOnChange(e) {
    this.setState({
      accountingCompanyPostalCode: e.value,
    });
    this.resetPrivateRelatedStates();
  }

  accountingCompanyCityOnChange(e) {
    this.setState({
      accountingCompanyCity: e.target.value,
    });
    this.resetPrivateRelatedStates();
  }

  accountingCompanyUserNameOnChange(e, name) {
    if (name == "name") {
      this.setState({
        accountingCompanyUserName: e.target.value,
      });
    } else {
      this.setState({
        accountingCompanyUserLastName: e.target.value,
      });
    }

    this.resetPrivateRelatedStates();
  }

  accountingCompanyEmailIdOnChange(e) {
    this.setState({
      accountingCompanyEmailId: e.target.value,
    });
    this.resetPrivateRelatedStates();
  }

  accountingCompanyPasswordOnChange(e) {
    this.setState({
      accountingCompanyPassword: e.target.value,
    });
    this.resetPrivateRelatedStates();
  }

  accountingCompanyConfirmPasswordOnChange(e) {
    this.setState({
      accountingCompanyConfirmPassword: e.target.value,
    });
    this.resetPrivateRelatedStates();
  }

  signOut(e) {
    const { sessionDetails, currentPageName } = this.props;
    e.preventDefault();
    localStorage.setItem("rebelSkoolUser", null);
    window.location.assign("/#/annualreport");
    let { login } = this.props;
    login.values = undefined;
    if (currentPageName.values == "Home") window.location.reload(false);
  }

  resetEmailOnBlur() {
    const { resetEmail, emailExistsAlready } = this.state;
    if (resetEmail != "") {
      let userLoginDetails = {
        emailID: resetEmail,
      };
      axios
        .post("/user/checkIfEmailExists", userLoginDetails)
        .then((response) => {
          if (response.data) {
            this.setState({ emailExistsAlready: true });
          }
        });
    }
  }

  sendPasswordResetLink(event) {
    this.setState({ resetPasswordBtnLoading: true });
    const { email, resetEmail, emailDoesNotExists } = this.state;
    const { sessionDetails } = this.props;
    let resetEmailValue = "";
    if (email !== "") {
      resetEmailValue = email;
    } else {
      resetEmailValue = resetEmail;
    }

    if (resetEmailValue != "") {
      let userLoginDetails = {
        emailID: resetEmailValue,
      };
      event.preventDefault();

      axios
        .post("/user/checkIfEmailExists", userLoginDetails)
        .then((response) => {
          if (response.data) {
            const link = "/forgetpassword";
            const annualReport = "/annualReport/";
            axios
              .get(
                "/user/getByEmail?emailId=" + resetEmailValue,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let userResponseDate = response.data;
                if (userResponseDate.userID > 0) {
                  let forgetLinkEmailObject = {
                    recipient: userResponseDate.emailID,
                    contactNumber: "",
                    emailType: "forgotPassword",
                    userGUID: userResponseDate.userGUID,
                    status: "New",
                    noOfAttempts: 0,
                  };

                  const url =
                    "/emailQueueController/insertEmailQueueAndSendMail";
                  const formData = new FormData();
                  const objString = JSON.stringify(forgetLinkEmailObject);

                  formData.append("request", objString);
                  const config = {
                    headers: {
                      timeZone: userTimeZone,
                      language: userSelectedLanguage
                    },
                  };
                  axios.post(url, formData, config).then((response) => {
                    if (response.data) {
                      this.setState({ resetPasswordBtnLoading: false });
                      this.toast.show({
                        severity: "success",
                        summary:
                          TranslationFile.SuccessText[userSelectedLanguage],
                        detail:
                          // "Please check your email to reset your password ",
                          TranslationFile.EmailResetPassword[
                          userSelectedLanguage
                          ],
                        // life: 2000,
                        sticky:true
                        // : true
                      });
                      this.setState({ forgotPasswordDialogVisible: false })
                    }
                  });
                }
              });
          } else {
            this.setState({ emailDoesNotExists: true });
          }
        });
    }
  }

  resetEmailOnChange(e) {
    this.setState({ resetEmail: e.target.value });
  }

  headerLoginButtonOnClick() {
    this.setState({ loginSideBarVisible: true });
    $(".corp-body").removeClass("open-menu");
    $(".hamburger-menu .bar").removeClass("animate");
    setTimeout(() => {
      // loadCaptchaEnginge(4, "#DBE2EF", "#3F72AF", "numbers");
    }, 100);
  }

  resendVerificationEmail(privateEmailId, privatePassword) {
    SignUpVerifyEmail(privateEmailId, privatePassword);
    this.toast.show({
      severity: "success",
      summary: "success",
      detail: "Email Sent ",
      life: 2000,
      // sticky: true,
    });
  }

  render() {
    const {
      loginSuccess,
      loginSideBarVisible,
      activeIndex,
      activeSubTabIndex,
      email,
      password,
      emailNotExists,
      isActiveEmailId,
      signUpType,
      username,
      confirmPassword,
      privateUserName,
      privateUserLastName,
      privateEmailId,
      privatePassword,
      privateConfirmPassword,
      emailAlreadyExists,
      iAcceptChecked,
      isPrivatePasswordMismatch,
      isPrivateConfirmPasswordBlur,
      isPrivateEmailIdValid,
      iPrivateAcceptChecked,
      isPrivateEmailAlreadyExists,
      accountingCompanyRegNo,
      accountingCompanyName,
      accountingCompanyAddress,
      accountingCompanyPostalCode,
      accountingCompanyCity,
      accountingCompanyUserName,
      accountingCompanyUserLastName,
      accountingCompanyEmailId,
      accountingCompanyPassword,
      accountingCompanyConfirmPassword,
      isAccountingCompanyRegNoAlreadyExists,
      isAccountingCompanyPasswordMismatch,
      isAccountingCompanyConfirmPasswordBlur,
      isAccountingCompanyEmailIdValid,
      iAccountingCompanyAcceptChecked,
      isAccountingCompanyEmailAlreadyExists,
      usernameFromResponse,
      userRoleFromResponse,
      isAccountingCompanyFromResponse,
      forgotPasswordDialogVisible,
      sessionUUID,
      showEmailVerificationNeed,
      resetPasswordBtnLoading,
    } = this.state;
    const { resetEmail, emailDoesNotExists, redirectToForgetPassword } =
      this.state;

    const { pricesToLogin, sessionDetails } = this.props;

    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = "";
    if (localData != "undefined") {
      localObject = JSON.parse(localData);
    }

    if (userSelectedLanguage == null) {
      userSelectedLanguage = "sw";
    }
    const userGUID =
      rebelSkoolUserLSParse && rebelSkoolUserLSParse.userGUID
        ? rebelSkoolUserLSParse.userGUID
        : sessionUUID;

    const annualReport = "/#/annualreport/";
    const dashbaordURL = annualReport + sessionUUID + "/dashboard";
    const myProfile = annualReport + userGUID + "/MyProfile";
    const admin = annualReport + userGUID + "/admin";

    return (
      <div className="login-btn-user">
        <Toast
          ref={(el) => (this.toast = el)}
          className="login_toast_success"
        />

        {!loginSuccess && (
          <div>
            <Button
              value="Login"
              className="user-btn"
              onClick={() => this.headerLoginButtonOnClick()}
            >
              {TranslationFile.loginText[userSelectedLanguage]}
              {/* Login */}
            </Button>
          </div>
        )}

        {/* SIDE BAR */}
        <Sidebar
          visible={loginSideBarVisible || pricesToLogin}
          onHide={() => this.loginSideBarOnClick()}
          position="right"
          className="user-sidebar"
          // style={{ marginTop: config.statusBar.visibility ? "217px" : "140px" }}
          style={{
            marginTop:
              statusBandFromLS && statusBandFromLS["activate"]
                ? "228px"
                : "150px",
          }}
          blockScroll
        >
          <div className="loginRebelSkoolLogo">
            <Image src={logo} alt="Image" width="150" height="50" className="login-sidebar-logo"/>
          </div>

          <br></br>

          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => this.tabOnChange(e, "parentTab")}
            className="flex flex-wrap gap-2 mb-3"
          >
            {/* SIGN-IN TAB */}
            <TabPanel
              header={TranslationFile.loginText[userSelectedLanguage]}
              className="login-Login-Tab"
              headerClassName="head-one"
            >
              <div className="m-0">
                <br></br>
                <InputText
                  value={email}
                  onChange={(e) => {
                    this.emailOnChange(e.target.value);
                  }}
                  onBlur={() => this.emailOnBlur()}
                  placeholder={TranslationFile.loginEmailText[userSelectedLanguage]}
                  className="inputtext"
                />
                <br></br>
                <br></br>
                <Password
                  className="password"
                  value={password}
                  onChange={(e) => {
                    this.passwordOnChange(e.target.value);
                  }}
                  onBlur={() => this.passwordOnBlur()}
                  placeholder={
                    TranslationFile.IncomestateYearPassword[
                    userSelectedLanguage
                    ]
                  }
                  toggleMask
                  feedback={false}
                />

                <br></br>
                <br></br>

                {!emailNotExists && (
                  <center>
                    <Button
                      label={TranslationFile.loginText1[userSelectedLanguage]}
                      className="login-btn"
                      id="login_corp-btn"
                      onClick={() => {
                        this.loginButtonOnClick(email, password);
                      }}
                      disabled={this.state.showEmailVerificationNeed}
                    />
                    <br></br>
                    <br></br>
                    {/* <a href='#forgetPassword'>
                    </a> */}
                    <Button
                      // ForgetPassword?
                      label={
                        TranslationFile.loginForgotPass[userSelectedLanguage]
                      }
                      className="login-btn"
                      id="login_forgotPass"
                      style={{ background: "white", color: "#2196F3" }}
                      onClick={() => {
                        this.setState({
                          forgotPasswordDialogVisible: true,
                          emailDoesNotExists: false,
                          resetEmail: "",
                        });
                      }}
                      outlined
                    />
                  </center>
                )}

                {emailNotExists && (
                  <div className="success-msg">
                    <FcCancel className="sign-icon" />
                    <br></br>
                    {/* Your Email Address doesn't Exists. */}
                    {TranslationFile.loginEmailDoesnt[userSelectedLanguage]}
                    <br></br>
                    <br></br>
                    <center>
                      <Button
                        label={
                          TranslationFile.loginTrySignUp[userSelectedLanguage]
                        }
                        className="forgot-btn"
                        onClick={(e) => {
                          this.setState({
                            activeIndex: 1,
                            email: "",
                            password: "",
                            confirmPassword: "",
                            emailNotExists: false,
                            isActiveEmailId: true,
                          });
                        }}
                      />
                    </center>
                  </div>
                )}

                {!isActiveEmailId && (
                  <div className="success-msg">
                    <FcCancel className="sign-icon" />
                    <br></br>
                    {/* Your Email Address is Inactive. Please contact admin */}
                    {TranslationFile.loginInactiveEmail[userSelectedLanguage]}
                    <br></br>
                    <br></br>
                  </div>
                )}

                {this.state.showEmailVerificationNeed && isActiveEmailId && (
                  <div className="after-sign-up-verification">
                    <p style={{ color: "Red" }}>{TranslationFile.EmailNotVerifiedText[userSelectedLanguage]}</p>
                    {/* Please check your Email and Login through the Verification Link. */}
                    {/* <br></br> */}
                    {/* Click the below Button to resend the Verification Email */}
                    {TranslationFile.ResendVerifyText[userSelectedLanguage]}
                    <Button
                      label={TranslationFile.resendMailText[userSelectedLanguage]}
                      className="login-btn"
                      style={{
                        background: "white",
                        color: "#2196F3",
                        marginTop: "1%",
                      }}
                      outlined
                      onClick={() =>
                        this.resendVerificationEmail(email, password)
                      }
                    />
                  </div>
                )}
              </div>
            </TabPanel>

            {/* SIGN-UP TAB */}
            <TabPanel
              header={TranslationFile.loginSignUpText[userSelectedLanguage]}
              className="login-SignUp-Tab"
              headerClassName="head-one"
              activeIndex={activeSubTabIndex}
            >
              <div className="signUpSubTabRadioBtnDiv">
                <div>
                  <RadioButton
                    inputId="private"
                    name="private"
                    value="private"
                    onChange={(e) =>
                      this.signUpTypeRadioBtnOnClick(e, "private")
                    }
                    checked={signUpType === "private"}
                  />
                  <label htmlFor="private" className="subTabRadioBtnLbl">
                    {/* Private */}
                    {TranslationFile.loginPrivateText[userSelectedLanguage]}
                  </label>
                </div>
                <div>
                  <RadioButton
                    inputId="Accounting Company"
                    name="pizza"
                    value="Accounting Company"
                    onChange={(e) =>
                      this.signUpTypeRadioBtnOnClick(e, "Accounting Company")
                    }
                    checked={signUpType === "Accounting Company"}
                  />
                  <label
                    htmlFor="Accounting Company"
                    className="subTabRadioBtnLbl"
                  >
                    {TranslationFile.loginAcompanyText[userSelectedLanguage]}
                    {/* Accounting Company */}
                  </label>
                </div>
              </div>

              {signUpType == "private" ? (
                <div>
                  <p className="loginSignUpMandatoryStar">*</p>
                  <InputText
                    value={privateUserName}
                    onChange={(e) => this.privateUserNameOnChange(e, "name")}
                    placeholder={
                      TranslationFile.LoginFirstName[userSelectedLanguage]
                    }
                    className="inputtext"
                  />
                  <br></br>
                  <p className="loginSignUpMandatoryStar">*</p>
                  <InputText
                    value={privateUserLastName}
                    onChange={(e) =>
                      this.privateUserNameOnChange(e, "lastName")
                    }
                    placeholder={
                      TranslationFile.LoginLastName[userSelectedLanguage]
                    }
                    className="inputtext"
                  />
                  <br></br>
                  <p className="loginSignUpMandatoryStar">*</p>
                  <InputText
                    value={privateEmailId}
                    onChange={(e) => {
                      this.privateEmailOnChange(e.target.value);
                    }}
                    onBlur={() => this.privateEmailOnBlur()}
                    placeholder={
                      TranslationFile.loginEmailText[userSelectedLanguage]
                    }
                    className="inputtext"
                  />

                  {!isPrivateEmailIdValid ? (
                    <div className="error">
                      {/* Please a valid Email id */}
                      {
                        TranslationFile.loginValidEmailText[
                        userSelectedLanguage
                        ]
                      }
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <p className="loginSignUpMandatoryStar">*</p>
                  <Password
                    value={privatePassword}
                    onChange={(e) => {
                      this.privatePasswordOnChange(e.target.value);
                    }}
                    onBlur={() => this.privatePasswordOnBlur()}
                    placeholder={
                      TranslationFile.IncomestateYearPassword[
                      userSelectedLanguage
                      ]
                    }
                    toggleMask
                    feedback={false}
                    className="password"
                  />

                  <br></br>
                  <p className="loginSignUpMandatoryStar">*</p>
                  <Password
                    value={privateConfirmPassword}
                    onChange={(e) => {
                      this.privateConfirmPasswordOnChange(e.target.value);
                    }}
                    onBlur={() => this.privateConfirmPasswordOnBlur()}
                    placeholder={
                      TranslationFile.IncomestateYearConPassword[
                      userSelectedLanguage
                      ]
                    }
                    toggleMask
                    feedback={false}
                    className="password"
                  />

                  {isPrivatePasswordMismatch &&
                    privatePassword != "" &&
                    privateConfirmPassword != "" &&
                    isPrivateConfirmPasswordBlur && (
                      <div className="error">{
                        TranslationFile.PasswordMustMatchText[userSelectedLanguage]
                      }</div>
                    )}

                  <br></br>
                  <br></br>

                  {!isPrivateEmailAlreadyExists &&
                    // iPrivateAcceptChecked &&
                    !isPrivatePasswordMismatch &&
                    privateUserName != "" ? (
                    <Button
                      label={
                        TranslationFile.loginSignUpText2[userSelectedLanguage]
                      }
                      className="login-btn"
                      onClick={() => this.signedUpSuccessfully("private")}
                    />
                  ) : (
                    <Button
                      label={
                        TranslationFile.loginSignUpText2[userSelectedLanguage]
                      }
                      className="login-btn"
                      disabled
                    />
                  )}
                  {this.termsAndConditons("private")}

                  {isPrivateEmailAlreadyExists && (
                    <div className="success-msg">
                      <FcCancel className="sign-icon" />
                      <br></br>
                      {/* Your Email Address Already Exists. */}
                      {
                        TranslationFile.loginEmailAlreadyExistText[
                        userSelectedLanguage
                        ]
                      }
                      <br></br>
                      <br></br>
                      <center>
                        <Button
                          label={
                            TranslationFile.loginTrySignInText[
                            userSelectedLanguage
                            ]
                          }
                          className="forgot-btn"
                          onClick={(e) => {
                            this.setState({
                              activeIndex: 0,
                              privateUserName: "",
                              privateEmailId: "",
                              privatePassword: "",
                              privateConfirmPassword: "",
                              isPrivateEmailAlreadyExists: false,
                            });
                          }}
                        />
                      </center>
                    </div>
                  )}
                </div>
              ) : (
                <div className="login-acc-form">
                  <p className="loginSignUpMandatoryStar">*</p>
                  <InputMask
                    mask="999999-9999"
                    className="login-singUp-registration"
                    value={accountingCompanyRegNo}
                    onChange={(e) => {
                      this.accountingCompanyRegNoOnChange(e);
                    }}
                    onBlur={() => this.accountingCompanyRegNoOnBlur()}
                    useGrouping={false}
                    placeholder={
                      TranslationFile.loginRegistrationNo[userSelectedLanguage]
                    }
                  />
                  <br></br>
                  <p className="loginSignUpMandatoryStar">*</p>
                  <InputText
                    value={accountingCompanyName}
                    onChange={(e) => {
                      this.accountingCompanyNameOnChange(e);
                    }}
                    placeholder={
                      TranslationFile.dashboardCompanyName[userSelectedLanguage]
                    }
                    className="inputtext"
                  />
                  <br></br>
                  <br></br>

                  <InputText
                    value={accountingCompanyAddress}
                    onChange={(e) => {
                      this.accountingCompanyAddressOnChange(e);
                    }}
                    placeholder={
                      TranslationFile.loginAddress[userSelectedLanguage]
                    }
                    className="inputtext"
                  />
                  <br></br>
                  <br></br>
                  <div className="companySignUpPostalCodeCity">
                    <InputNumber
                      value={accountingCompanyPostalCode}
                      maxLength={5}
                      useGrouping={false}
                      onValueChange={(e) => {
                        this.accountingCompanyPostalCodeOnChange(e);
                      }}
                      placeholder={
                        TranslationFile.IncomestateCompanyPostalcode[
                        userSelectedLanguage
                        ]
                      }
                      className="inputtext"
                    />
                    <InputText
                      value={accountingCompanyCity}
                      onChange={(e) => {
                        this.accountingCompanyCityOnChange(e);
                      }}
                      placeholder={
                        TranslationFile.IncomestateCompanyCity[
                        userSelectedLanguage
                        ]
                      }
                      className="inputtext"
                    />
                  </div>

                  <p className="loginSignUpMandatoryStar">*</p>
                  <InputText
                    value={accountingCompanyUserName}
                    onChange={(e) => {
                      this.accountingCompanyUserNameOnChange(e, "name");
                    }}
                    placeholder={
                      TranslationFile.LoginFirstName[userSelectedLanguage]
                    }
                    className="inputtext"
                  />
                  <br></br>
                  <p className="loginSignUpMandatoryStar">*</p>
                  <InputText
                    value={accountingCompanyUserLastName}
                    onChange={(e) => {
                      this.accountingCompanyUserNameOnChange(e, "lastName");
                    }}
                    placeholder={
                      TranslationFile.LoginLastName[userSelectedLanguage]
                    }
                    className="inputtext"
                  />
                  <br></br>

                  <p className="loginSignUpMandatoryStar">*</p>
                  <InputText
                    value={accountingCompanyEmailId}
                    onChange={(e) => {
                      this.accountingCompanyEmailIdOnChange(e);
                    }}
                    onBlur={() => this.accountingCompanyEmailIdOnBlur()}
                    placeholder={
                      TranslationFile.loginEmailText[userSelectedLanguage]
                    }
                    className="inputtext"
                  />

                  {!isAccountingCompanyEmailIdValid ? (
                    <div className="error">
                      {/* Please a valid Email id */}
                      {
                        TranslationFile.loginValidEmailText[
                        userSelectedLanguage
                        ]
                      }
                    </div>
                  ) : isAccountingCompanyEmailAlreadyExists ? (
                    <div className="error">
                      {/* This email id is already registered */}
                      {
                        TranslationFile.loginEmailAlreadyRegText[
                        userSelectedLanguage
                        ]
                      }
                    </div>
                  ) : (
                    <br></br>
                  )}

                  <p className="loginSignUpMandatoryStar">*</p>
                  <Password
                    value={accountingCompanyPassword}
                    onChange={(e) => {
                      this.accountingCompanyPasswordOnChange(e);
                    }}
                    onBlur={() => this.accountingCompanyPasswordOnBlur()}
                    placeholder={
                      TranslationFile.IncomestateYearPassword[
                      userSelectedLanguage
                      ]
                    }
                    toggleMask
                    feedback={false}
                    className="password"
                  />
                  <br></br>

                  <p className="loginSignUpMandatoryStar">*</p>
                  <Password
                    value={accountingCompanyConfirmPassword}
                    onChange={(e) => {
                      this.accountingCompanyConfirmPasswordOnChange(e);
                    }}
                    onBlur={() => this.accountingCompanyConfirmPasswordOnBlur()}
                    placeholder={
                      TranslationFile.IncomestateYearConPassword[
                      userSelectedLanguage
                      ]
                    }
                    toggleMask
                    feedback={false}
                    className="password"
                  />
                  {isAccountingCompanyPasswordMismatch &&
                    accountingCompanyPassword != "" &&
                    accountingCompanyConfirmPassword != "" &&
                    isAccountingCompanyConfirmPasswordBlur && (
                      <div className="error">{
                        TranslationFile.PasswordMustMatchText[userSelectedLanguage]
                      }</div>
                    )}

                  <br></br>

                  {!isAccountingCompanyEmailAlreadyExists &&
                    // iAccountingCompanyAcceptChecked &&
                    !isAccountingCompanyPasswordMismatch &&
                    accountingCompanyRegNo != "" &&
                    accountingCompanyName != "" &&
                    accountingCompanyUserName != "" &&
                    !isAccountingCompanyRegNoAlreadyExists ? (
                    <Button
                      label={
                        TranslationFile.loginSignUpText2[userSelectedLanguage]
                      }
                      className="login-btn"
                      onClick={() =>
                        this.signedUpSuccessfully("accountingCompany")
                      }
                    />
                  ) : (
                    <Button
                      label={
                        TranslationFile.loginSignUpText2[userSelectedLanguage]
                      }
                      className="login-btn"
                      disabled
                    />
                  )}

                  {this.termsAndConditons("accounting company")}

                  {isAccountingCompanyRegNoAlreadyExists && (
                    <div className="success-msg">
                      <FcCancel className="sign-icon" />
                      <br></br>
                      {/* Registration No. is Exists Already. */}
                      {
                        TranslationFile.loginRegExistAlready[
                        userSelectedLanguage
                        ]
                      }
                      <br></br>
                      <br></br>
                      <center>
                        <Button
                          label={
                            TranslationFile.loginTrySignInText[
                            userSelectedLanguage
                            ]
                          }
                          className="forgot-btn"
                          onClick={(e) => {
                            this.setState({
                              activeIndex: 0,
                            });
                            this.resetAccountingCompanyRelatedStates();
                            this.resetPrivateRelatedStates();
                          }}
                        />
                      </center>
                    </div>
                  )}
                </div>
              )}
            </TabPanel>
          </TabView>
        </Sidebar>
        <Dialog
          blockScroll
          // style={{ width: "35vw" }}
          visible={forgotPasswordDialogVisible}
          onHide={() => this.setState({ forgotPasswordDialogVisible: false })}
          header={TranslationFile.resetPassText[userSelectedLanguage]}
          className="resetYourPassword_Box"
        >
          <div>
            <div>
              <div>
                <div>
                  <center>
                    {/* Enter your Email Address. We will send you a link to reset your password. */}
                    {TranslationFile.loginResetYourPass[userSelectedLanguage]}
                  </center>
                  <br></br>
                  <center>
                    <InputText
                      value={email != "" ? email : resetEmail}
                      onChange={(e) => {
                        this.resetEmailOnChange(e);
                      }}
                      placeholder={
                        TranslationFile.loginEmailText[userSelectedLanguage]
                      }
                      className="forgot-password-inputtext"
                      id="ResetYourPasswordEmailTextBar"
                    />
                  </center>
                </div>
                <center>
                  <div>
                    <Button
                      onClick={(e) => this.sendPasswordResetLink(e)}
                      className="resetPassword-year-btn-save"
                      disabled={email == "" && resetEmail == "" && true}
                    >
                      {resetPasswordBtnLoading ? (
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: "2rem", marginLeft: "40%" }}
                        ></i>
                      ) : (
                        <div style={{ marginLeft: "16%" }}>
                          {TranslationFile.loginEmailSendText[userSelectedLanguage]}
                        </div>
                      )}
                    </Button>
                  </div>
                  {emailDoesNotExists && (
                    <div className="success-msg">
                      <FcCancel className="sign-icon" />
                      <br></br>
                      <p style={{ fontSize: "18px" }}>
                        {/* Your Email Address doesn't Exists. */}
                        {TranslationFile.loginEmailDoesnt[userSelectedLanguage]}
                      </p>
                      <center>
                        <Button
                          style={{ width: "60%" }}
                          label={
                            TranslationFile.loginTrySignUp[userSelectedLanguage]
                          }
                          className="forgot-btn"
                          onClick={(e) => {
                            this.setState({
                              activeIndex: 1,
                              email: "",
                              password: "",
                              confirmPassword: "",
                              emailNotExists: false,
                              isActiveEmailId: true,
                              forgotPasswordDialogVisible: false,
                            });
                          }}
                        />
                      </center>
                    </div>
                  )}
                </center>
              </div>
            </div>
          </div >
        </Dialog >
      </div >
    );
  }
}

export default connect(mapStateToProps, null)(LoginNew);
