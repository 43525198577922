import React, { useState, useEffect } from "react";
import "./index.css";
import { Provider } from "react-redux";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers/index";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css"; //icons
import "primeflex/primeflex.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import home from "./Pages/01-Home/home";
import CompanyInformation from "./Pages/02-CompanyInformation/CompanyInformation.js";
import IncomeStatement from "./Pages/03-IncomeStatement/IncomeStatement.js";
import BalanceSheet from "./Pages/04-BalanceSheet/BalanceSheet.js";
import Notes from "./Pages/05-Notes/Notes.js";
import ManagementStatement from "./Pages/06-ManagementStatement/ManagementStatement.js";
import Representative from "./Pages/07-Representatives/Representative.js";
import Payment from "./Pages/08-Payment/Payment.js";
import PaymentConfirmation from "./Pages/08-Payment/PaymentConfirmation.js";
import DigitalSignature from "./Pages/09-DigitalSignature/digitalSignature.js";
import reviewAndSendPage from "./Pages/10-ReviewAndSend/ReviewAndSend.js";
import MyDashboard from "./Pages/MyDashboard/MyDashboard.js";
import ID_SRUFiles from "./Pages/ID_SRUFiles/ID_SRUFiles.js";
import ID_NEappendix from "./Pages/ID_NEappendix/ID_NEappendix.js";
import ProductsDemo from "./Pages/AdminSettingPage/AdminSettingPage";
import Template from "./Pages/TemplateFile/Template";
import DemoSlide from "./Pages/DemoSlide/DemoSlide.js";
import FaqSection from "./Pages/FAQ/FAQSection";
import TermsAndConditons from "./Pages/Login/Termsandconditions";
import MyProfile from "./Pages/MyProfile/MyProfile";
import ForgetPassword from "./Pages/Login/ForgetPassword";
import Credits from "./Pages/Footerpage/Credits";
import NoAccess from "./Pages/NoAccess/NoAccess.js";
import { setUserSessionAuthKey } from "./Component/commonUtils.js";
import PageNotFound from "./Pages/PageNotFound/PageNotFound.js";
// SRU - START

import SRUHomePage from "./Pages/SRU/SRUHomePage/SRUHomePage.js";
import SRUCompanyInfo from "./Pages/SRU/SRUCompanyInfo/SRUCompanyInfo.js";
import SRUDashboard from "./Pages/SRU/SRUDashboard/SRUDashboard.js";
import SRUPayment from "./Pages/SRU/SRUPayment/SRUPayment.js";
import INK2 from "./Pages/SRU/INK2/INK2.js";
import INK2R from "./Pages/SRU/INK2R/INK2R.js";
import INK2S from "./Pages/SRU/INK2S/INK2S.js";
import SRUReviewAndSend from "./Pages/SRU/SRUReviewAndSend/SRUReviewAndSend.js";



// SRU - END

let uuid = "";
const history = createBrowserHistory();
let store = createStore(reducers, applyMiddleware(thunk));
const propsStore = store.getState();
localStorage.removeItem("scrollTo");

export default function App() {
  const [rerender, setRerender] = useState(false);

  let localData = localStorage.getItem("rebelSkoolUser");
  let localObject = "";
  if (localData != "undefined") {
    localObject = JSON.parse(localData);
  }

  let currentPageUrl = window.location.href;
  let splitCurrentPageUrl = currentPageUrl.split("/");
  let currentReportGUID = "";
  if (splitCurrentPageUrl.length == 6) {
    currentReportGUID = splitCurrentPageUrl && splitCurrentPageUrl[4];
  }
  let pageNameFromURL =
    splitCurrentPageUrl && splitCurrentPageUrl[splitCurrentPageUrl.length - 1];
  let userGUIDFromURL =
    splitCurrentPageUrl && splitCurrentPageUrl[splitCurrentPageUrl.length - 2];

  let currentUrl = window.location.href;
  if (currentUrl.includes("#prices")) {
    localStorage.setItem("scrollTo", "#prices");
  } else if (currentUrl.includes("#faq")) {
    localStorage.setItem("scrollTo", "#faq");
  } else if (currentUrl.includes("#how-it-works")) {
    localStorage.setItem("scrollTo", "#how-it-works");
  } else if (
    currentUrl.includes("/#/annualreport") &&
    currentUrl.includes("/companyInfo")
  ) {
    localStorage.setItem("scrollTo", "companyInfo");
  } else if (currentUrl.includes("/annualreport/login")) {
    localStorage.setItem("openLoginSidebar", true);
    window.history.pushState(null, "", "/annualreport");
  }

  useEffect(() => {
    //Setting default Language for user
    let langFromLS = localStorage.getItem("selectedLanguage");
    if (langFromLS == undefined || langFromLS == "" || langFromLS == null) {
      localStorage.setItem("selectedLanguage", "sw");
    }

    //Getting Data From Status Band Table
    axios.get("/siteOwner/getStatusBand?appId=" + 1).then((response) => {
      let respData = response.data;
      localStorage.setItem("StatusBand", JSON.stringify(respData));
    });

    //Getting User's Timezone in GMT Format and setting it in Local Storage
    const userDate = new Date();
    const offsetMinutes = userDate.getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes / 60);
    const offsetHoursString = String(offsetHours).padStart(2, "0");
    const offsetHoursStringWithoutMinutes = offsetHoursString.split(".")[0];
    const offsetMinutesString = String(Math.abs(offsetMinutes % 60)).padStart(
      2,
      "0"
    );
    const offsetSign = offsetMinutes < 0 ? "+" : "-";
    const userTimezone = `GMT${offsetSign}${offsetHoursStringWithoutMinutes}:${offsetMinutesString}`;
    localStorage.setItem("userTimeZone", userTimezone);

    let currentUrl = window.location.href;
    if (currentUrl.includes("#prices")) {
      localStorage.setItem("scrollTo", "#prices");
    } else if (currentUrl.includes("#faq")) {
      localStorage.setItem("scrollTo", "#faq");
    } else if (currentUrl.includes("#how-it-works")) {
      localStorage.setItem("scrollTo", "#how-it-works");
    } else if (
      currentUrl.includes("/#/annualreport") &&
      currentUrl.includes("/companyInfo")
    ) {
      localStorage.setItem("scrollTo", "companyInfo");
    }

    if (currentPageUrl.includes("emailVerification")) {
      axios
        .get("/user/getByGuid?userGUID=" + userGUIDFromURL)
        .then((userDetailsResponse) => {
          if (userDetailsResponse.data && userDetailsResponse.data.active) {
            let userDetailsResp = userDetailsResponse.data;
            let emailVerifiedRequest = {
              emailID: userDetailsResp.emailID,
              userGUID: userGUIDFromURL,
            };
            axios
              .post(
                "/user/getUserDetailsByEmailIdAsLoginResp",
                emailVerifiedRequest
              )
              .then((userDetailsByEmailIdResponse) => {
                let userDetailsByEmailIdResp =
                  userDetailsByEmailIdResponse.data;
                userDetailsByEmailIdResp["User-Session-Auth-Key"] =
                  userDetailsByEmailIdResponse.headers["user-session-auth-key"];
                localStorage.setItem(
                  "rebelSkoolUser",
                  JSON.stringify(userDetailsByEmailIdResp)
                );
                localStorage.setItem("navigateTo", "dashboard");
                setTimeout(() => {
                  window.location.assign("/annualreport");
                }, 1000);
              });
          }
        });
    }

    if (currentPageUrl.includes("reportShare")) {
      let userGUID =
        splitCurrentPageUrl &&
        splitCurrentPageUrl[splitCurrentPageUrl.length - 3];

      let reportGUID =
        splitCurrentPageUrl &&
        splitCurrentPageUrl[splitCurrentPageUrl.length - 2];

      let checkUserAndReportGuidIsValid = {
        reportGuid: userGUIDFromURL,
        userGuid: userGUID,
        userSessionAuthKey:
          localObject != null
            ? localObject.userSessionAuthKey
            : "anonymousUser",
      };

      let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
      if (localObject != null) {
        axios
          .post(
            "/annualReport/getUserAndReportGuidISValid",
            checkUserAndReportGuidIsValid,
            userSessionAuthKeyReturnValue
          )
          .then((checkUserAndReportGuidIsValidResp) => {
            if (checkUserAndReportGuidIsValidResp.data) {
              axios
                .get("/user/getByGuid?userGUID=" + userGUID)
                .then((userDetailsResponse) => {
                  if (userDetailsResponse.data) {
                    let userDetailsResp = userDetailsResponse.data;

                    let emailVerifiedRequest = {
                      emailID: userDetailsResp.emailID,
                      userGUID: userGUIDFromURL,
                    };

                    axios
                      .post(
                        "/user/getUserDetailsByEmailIdAsLoginResp",
                        emailVerifiedRequest
                      )
                      .then((userDetailsByEmailIdResponse) => {
                        let userDetailsByEmailIdResp =
                          userDetailsByEmailIdResponse.data;
                        userDetailsByEmailIdResp["User-Session-Auth-Key"] =
                          userDetailsByEmailIdResponse.headers["user-session-auth-key"];
                        localStorage.setItem(
                          "rebelSkoolUser",
                          JSON.stringify(userDetailsByEmailIdResp)
                        );

                        axios
                          .get(
                            "/annualReport/getHeader?reportId=" + reportGUID,
                            userSessionAuthKeyReturnValue
                          )
                          .then((headerResponse) => {
                            let headerResponseData = headerResponse.data;

                            if (
                              null != headerResponseData.reportID &&
                              headerResponseData.reportID != ""
                            ) {
                              let reportAccessReq = {
                                reportId: headerResponseData.reportID,
                              };

                              axios
                                .post(
                                  "/annualReport/getAccessStatus",
                                  reportAccessReq,
                                  userSessionAuthKeyReturnValue
                                )
                                .then((accessStatusResponse) => {
                                  window.location.assign(
                                    "/annualreport/" +
                                    userGUIDFromURL +
                                    "/companyInfo"
                                  );
                                })
                                .catch((error) => {
                                  window.location.assign(
                                    "/annualreport/" +
                                    userGUIDFromURL +
                                    "/no-access"
                                  );
                                });
                            }
                          });
                      });
                  }
                });
            }
          });
        // }
      } else {
        axios
          .post(
            "/annualReport/getUserAndReportGuidISValid",
            checkUserAndReportGuidIsValid,
            userSessionAuthKeyReturnValue
          )
          .then((checkUserAndReportGuidIsValidResp) => {
            if (checkUserAndReportGuidIsValidResp.data) {
              axios
                .get("/user/getByGuid?userGUID=" + userGUID)
                .then((userDetailsResponse) => {
                  if (userDetailsResponse.data) {
                    let userDetailsResp = userDetailsResponse.data;

                    let emailVerifiedRequest = {
                      emailID: userDetailsResp.emailID,
                      userGUID: userGUIDFromURL,
                    };

                    axios
                      .post(
                        "/user/getUserDetailsByEmailIdAsLoginResp",
                        emailVerifiedRequest
                      )
                      .then((userDetailsByEmailIdResponse) => {
                        let userDetailsByEmailIdResp =
                          userDetailsByEmailIdResponse.data;

                        userDetailsByEmailIdResp["User-Session-Auth-Key"] =
                          userDetailsByEmailIdResponse.headers["user-session-auth-key"];
                        localStorage.setItem(
                          "rebelSkoolUser",
                          JSON.stringify(userDetailsByEmailIdResp)
                        );
                        window.location.assign(
                          "/annualreport/" + userGUIDFromURL + "/companyInfo"
                        );
                        window.location.reload(false);
                      });
                  }
                });
            }
          });
      }
    }

    let sruNavigateTo = localStorage.getItem("sruNavigateTo");
    let navigateToGUID = localStorage.getItem("navigateToGUID");
    axios.get("/cms/generateUUID").then((response) => {
      let currentPageUrl = window.location.href;
      let splitCurrentPageUrl = currentPageUrl.split("/");
      if (splitCurrentPageUrl.length == 7) {
        uuid = splitCurrentPageUrl[5];
      } else {
        uuid = response.data;
      }
      let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
      if (userSessionAuthKeyReturnValue) {
        axios
          .get("/user/setLastActiveDateForUser", userSessionAuthKeyReturnValue)
          .then((response) => { })
          .catch((ex) => { });
      }
      if (currentReportGUID != "") {
        uuid = currentReportGUID;

        let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
        if (userSessionAuthKeyReturnValue) {
          axios
            .get(
              "/annualReport/getHeader?reportId=" + uuid,
              userSessionAuthKeyReturnValue
            )
            .then((headerResponse) => {
              let headerResponseData = headerResponse.data;

              if (
                null != headerResponseData.reportID &&
                headerResponseData.reportID != ""
              ) {
                axios
                  .get(
                    "/annualReport/getCompletedPages?reportId=" +
                    headerResponseData.reportID,
                    userSessionAuthKeyReturnValue
                  )
                  .then((completedStatementTypesResponse) => {
                    let sideBarGreenTickPages = [];
                    sideBarGreenTickPages =
                      completedStatementTypesResponse.data;
                    sideBarGreenTickPages.push(
                      "My Dashboard",
                      "Company Information"
                    );
                    let updatePossibleNavigationPagesListString =
                      JSON.stringify(sideBarGreenTickPages);
                    localStorage.setItem(
                      "possibleAccessablePages",
                      updatePossibleNavigationPagesListString
                    );
                  });
              }
            });
        }

        let possibleNavigationPagesListLS = localStorage.getItem(
          "possibleAccessablePages"
        );
      }

      if (localObject != "") {
        propsStore["login"]["login"].values = localObject;
      }

      if (navigateToGUID != "" && navigateToGUID != null) {
        uuid = navigateToGUID;
      }
      let sessionDetails = {
        uuid: uuid,
        currentPage: "home",
        IsAnnualReportSubmitted: false,
      };
      propsStore["sessionDetails"]["sessionDetails"].values = sessionDetails;
      localStorage.setItem("sessionUUID", uuid);
      setRerender(true);
    });
  }, []);
  const userGUID = (localObject &&  localObject.userGUID) ? localObject.userGUID : uuid;

  const annualReport = "/annualreport/";
  const incomeStatement = annualReport + uuid + "/incomeStatement";
  const companyInfo = annualReport + uuid + "/companyInfo";
  const balanceSheet = annualReport + uuid + "/balanceSheet";
  const notes = annualReport + uuid + "/notes";
  const managementStatement = annualReport + uuid + "/managementStatement";
  const representative = annualReport + uuid + "/representative";
  const payment = annualReport + uuid + "/payment";
  const dashboard = annualReport + uuid + "/dashboard";
  const forgetPassword = "/myProfile/" + uuid + "/forgetpassword";
  const myProfile = annualReport + userGUID + "/MyProfile";
  const admin = annualReport + uuid + "/admin";
  const reviewAndSend = annualReport + uuid + "/reviewAndSend";
  const paymentConfirmation = annualReport + uuid + "/paymentConfirmation";
  const noAccess = annualReport + uuid + "/no-access";
  const digitalSignature = annualReport + uuid + "/digitalSignature";

  // SRU - START

  const incomeDeclaration2SRU = "/income-declaration-2/";
  const sruCompanyInfo = incomeDeclaration2SRU + uuid + "/companyInfo";
  const iNK2 = incomeDeclaration2SRU + uuid + "/INK2";
  const iNK2R = incomeDeclaration2SRU + uuid + "/INK2R";
  const iNK2S = incomeDeclaration2SRU + uuid + "/INK2S";
  const sruPayment = incomeDeclaration2SRU + uuid + "/payment";
  const sruDashboardPage = incomeDeclaration2SRU + uuid + "/dashboard";
  const sruMyProfile = incomeDeclaration2SRU + userGUID + "/MyProfile";
  const sruAdmin = incomeDeclaration2SRU + uuid + "/admin";
  const sruReviewAndSend = incomeDeclaration2SRU + uuid + "/reviewAndSend";
  const sruPaymentConfirmation =
    incomeDeclaration2SRU + uuid + "/paymentConfirmation";
  // SRU - END

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={home} />

          <Route exact path="/annualreport" component={home} />
          <Route exact path="/contact-us/" component={home} />
          <Route
            exact
            path="/income-declaration-1"
            component={ID_NEappendix}
          />
          <Route exact path={companyInfo} component={CompanyInformation} />
          <Route exact path={incomeStatement} component={IncomeStatement} />
          <Route exact path={balanceSheet} component={BalanceSheet} />
          <Route exact path={notes} component={Notes} />
          <Route exact path={dashboard} component={MyDashboard} />
          <Route exact path={admin} component={ProductsDemo} />
          <Route exact path={sruAdmin} component={ProductsDemo} />
          <Route exact path="/Template" component={Template} />
          <Route exact path="/DemoSlide" component={DemoSlide} />
          <Route exact path="/faq" component={FaqSection} />
          <Route exact path="/credits" component={Credits} />
          <Route
            exact
            path={managementStatement}
            component={ManagementStatement}
          />
          <Route exact path={representative} component={Representative} />
          <Route exact path={digitalSignature} component={DigitalSignature} />
          <Route exact path={myProfile} component={MyProfile} />
          <Route exact path={sruMyProfile} component={MyProfile} />
          <Route exact path={payment} component={Payment} />
          <Route exact path={forgetPassword} component={ForgetPassword} />
          <Route
            exact
            path="/termsandconditions"
            component={TermsAndConditons}
          />
          <Route exact path={reviewAndSend} component={reviewAndSendPage} />
          <Route
            exact
            path={paymentConfirmation}
            component={PaymentConfirmation}
          />
          <Route
            exact
            path={sruPaymentConfirmation}
            component={PaymentConfirmation}
          />
          <Route exact path={noAccess} component={NoAccess} />
          <Route exact path="/income-declaration-2" component={ID_SRUFiles} />

          {/* SRU _ START */}
          {/* <Route exact path="/" component={SRUHomePage} /> */}
          {/* <Route exact path="/income-declaration-2" component={SRUHomePage} /> */}
          <Route exact path={sruCompanyInfo} component={SRUCompanyInfo} />
          <Route exact path={iNK2} component={INK2} />
          <Route exact path={iNK2R} component={INK2R} />
          <Route exact path={iNK2S} component={INK2S} />
          <Route exact path={sruDashboardPage} component={SRUDashboard} />
          <Route exact path={sruPayment} component={SRUPayment} />
          <Route exact path={sruReviewAndSend} component={SRUReviewAndSend} />

          {/* SRU - END */}

          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}
