import React, { Component } from "react";
import TranslationFile from "../../15-Translation/locale/Translation_apps.json";
import { Toast } from "primereact/toast";
import Sidebar from "../SRUSidebar/SRUSidebar";
import Footer_contact_tab from "../../Footerpage/Footer_contact_tab";
import ScrolltoTop from "../../ScrollTop/ScrollTop";
import SRUNavBarHeader from "../SRUNavBarHeader/SRUNavBarHeader";
import { setUserSessionAuthKey } from "../../../Component/commonUtils";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import "./INK2.css";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import { FcInfo } from "react-icons/fc";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let INK2TemplateReqArray = [];
let userSelectedLanguage;
let tabTitleArray = [],
  sruINK2TemplateResponse = {},
  sruINK2TemplateReqArray = [];
let sruReportHeaderRequestObj = {
  reportGUID: "",
  companyName: "",
  registrationNo: "",
  postalCode: 0,
  city: "",
  currentYear: "",
},
  currentReportGUIDFromUrl,
  selectedForms = [],
  currentPageName = "INK2";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    activeSideBar: state.activeSideBar.activeSideBar,
    incomeStatement: state.incomeStatement.incomeStatement,
    currentPageName: state.currentPageName.currentPageName,
    readOnly: state.readOnly.readOnly,
    showWIPStatus: state.showWIPStatus.showWIPStatus,
  };
};

class INK2 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.inputNumberRef = [];
    this.state = {
      activeIndex: [0],
      checked: true,
      checkedNobtn: true,
      checkedYesbtn: false,
      checkedNobtn2: true,
      checkedYesbtn2: false,
      sideBarStatus: true,
      latestCustomLineNo: 0,
      reportGUID: "",
      finalSumLbl: "Årets resultat",
      toggleOpen: false,
      headerCount: 0,
      isLoading: true,
      sruINK2Value: "",
      companyName: "",
      currentFinancialYear: "",
      clickedIndex: null,
      clickedParentIndex: null,
      columnIndex: null,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleChange(toggleOpen) {
    window.scroll(0, 0);
    if (toggleOpen == true) {
      let headerIdxList = [];
      Object.keys(tabTitleArray).map((heading, idx) => {
        headerIdxList.push(idx);
      });
      this.setState({ activeIndex: headerIdxList });
      this.setState({ toggleOpen: false });
    } else {
      let collapse = [];
      this.setState({ activeIndex: collapse });
      this.setState({ toggleOpen: true });
    }
  }

  openAll(resp) {
    let headerIdxList = [];
    Object.keys(resp).map((heading, idx) => {
      headerIdxList.push(idx);
    });
    let open = headerIdxList;
    this.setState({ activeIndex: open });
  }

  convert = (str) =>
    str.split(",").flatMap((s) => {
      if (!s.includes("-")) return +s;
      const [min, max] = s.split("-");
      return Array.from({ length: max - min + 1 }, (_, n) => n + +min);
    });

  handleClickOutside(event) {
    let index = this.state.clickedIndex;
    let inputReference = this.inputNumberRef[index];
    if (
      this.state.clickedIndex !== null &&
      inputReference
    ) {
      this.setState({ clickedIndex: null });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    let { sideBarReducer, currentPageName, companyInformation } = this.props;
    currentPageName.values = "INK2";
    localStorage.setItem("currentPageName", "INK2");

    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];

    sruReportHeaderRequestObj.reportGUID = currentReportGUIDFromUrl;
    if (userSessionAuthKeyReturnValue) {
      sruINK2TemplateReqArray = [];
      axios
        .get(
          "/SRUHeader/getHeader?reportGuid=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data.forms != "" && response.data.forms != null) {
            selectedForms = response.data.forms.split(",");
          }

          let companyName = response.data.companyName;
          let registrationNo = response.data.registrationNo;
          let currentYear = response.data.currentYear;
          let splittedCurrentYear = currentYear.split(" ");
          let currentStartingPeriod = splittedCurrentYear[1];
          let currentEndingPeriod = splittedCurrentYear[3];

          let sruTemplateReq = {
            validFromDate: currentStartingPeriod,
            validToDate: currentEndingPeriod,
            statementType: 1,
          };
          sruINK2TemplateReqArray.push(sruTemplateReq);

          this.setState({
            companyName: companyName,
            registrationNo: registrationNo,
          });

          let responseDate = response.data;
          if (responseDate != "" && null != responseDate.reportID) {
            let reportAccessReq = { reportId: responseDate.reportID };

            // axios
            //   .post(
            //     "/SRUHeader/getAccessStatus",
            //     reportAccessReq,
            //     userSessionAuthKeyReturnValue
            //   )
            //   .then((response) => {
            //     switch (response.data) {
            //       case 2:
            //         linkSharedUser = 2; //Write
            //         break;
            //       case 3:
            //         linkSharedUser = 3; //Read Only
            //         break;
            //       case 4:
            //         linkSharedUser = 4; //No Access
            //         break;
            //     }
            //     if (responseDate.companyName != "") {
            //       this.setState({
            //         companyName: responseDate.companyName,
            //       });
            //     }
            //     if (responseDate.currentYear != "")
            //       this.setState({
            //         currentFinancialYear: responseDate.currentYear,
            //       });
            //   })

            //   .catch((error) => {
            //     let noAccessURL =
            //       "/income-declaration-2/" +
            //       currentReportGUIDFromUrl +
            //       "/no-access";
            //     this.props.history.push(noAccessURL);
            //   });

            // axios
            //   .get(
            //     "/SRU/getCompletedPages?reportId=" + responseDate.reportID,
            //     userSessionAuthKeyReturnValue
            //   )
            //   .then((response2) => {
            //     sideBarReducer.values = response2.data;

            //     sideBarReducer.values.push("My Dashboard");
            //     sideBarReducer.values.push("Company Information");
            //     let updatePossibleNavigationPagesListString = JSON.stringify(
            //       sideBarReducer.values
            //     );
            //     localStorage.setItem(
            //       "possibleAccessablePages",
            //       updatePossibleNavigationPagesListString
            //     );
            //   });
          }
          // else {
          //   sideBarGreenTickPages = [];
          //   sideBarGreenTickPages.push(
          //     "My Dashboard",
          //     "Company Information",
          //     "INK2"
          //   );
          //   let updatePossibleNavigationPagesListString = JSON.stringify(
          //     sideBarGreenTickPages
          //   );
          //   localStorage.setItem(
          //     "possibleAccessablePages",
          //     updatePossibleNavigationPagesListString
          //   );
          // }
        });

      let getJsonLines = {
        guid: currentReportGUIDFromUrl,
        pageName: "INK2",
      };

      axios
        .post(
          "/SRULines/getLinesJson",
          getJsonLines,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let responseData = response.data.INK2;
          if (responseData == undefined) {
            responseData = "";
          }

          if (responseData != "") {
            let jsonConvertedResponseData = JSON.parse(responseData);
            let financialYearFromJsonResponse = [];
            Object.keys(jsonConvertedResponseData).map((i, idx) => {
              if (i == "financialYear") {
                financialYearFromJsonResponse = [
                  ...jsonConvertedResponseData[i],
                ];
              }
            });

            INK2TemplateReqArray = financialYearFromJsonResponse;

            delete jsonConvertedResponseData.financialYear;

            tabTitleArray = jsonConvertedResponseData;
            this.openAll(jsonConvertedResponseData);

            axios
              .get(
                "/SRU/getCompletedPages?reportId=" + response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                sideBarReducer.values = completedStatementTypesResponse.data;
                // sideBarGreenTickPages = completedStatementTypesResponse.data;
                this.setState({
                  reRender: true,
                });
              });
            this.setState({
              sruINK2Value: jsonConvertedResponseData,
              isLoading: false,
            });
          } else {
            if (userSessionAuthKeyReturnValue) {
              INK2TemplateReqArray = [];
              companyInformation != undefined &&
                companyInformation.financialYear.length &&
                companyInformation.financialYear.map((financialYear, idx) => {
                  let INK2TemplateReq = {
                    validFromDate: moment(financialYear.from).format(
                      "YYYY-MM-DD"
                    ),
                    validToDate: moment(financialYear.to).format("YYYY-MM-DD"),
                    statementType: 1,
                  };
                  INK2TemplateReqArray.push(INK2TemplateReq);
                });

              axios
                .post(
                  "/SRUHeader/getSRUTemplateLinesByFY",
                  sruINK2TemplateReqArray,
                  userSessionAuthKeyReturnValue
                )
                .then((Response) => {
                  sruINK2TemplateResponse = Response.data;
                  this.dataManipulation(sruINK2TemplateResponse, true, false);

                  tabTitleArray = sruINK2TemplateResponse;
                  this.openAll(sruINK2TemplateResponse);
                });
            }
          }
        });
    }
  }

  dataManipulation(resp, isInitialRender, isOnChangeCall) {
    let { sruINK2Value } = this.state;
    if (isInitialRender) {
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
      });
      this.setState({
        sruINK2Value: resp,
      });

      let responseJson = resp;
      let currentPageName = localStorage.getItem("currentPageName");
      let updateJsonTableObj = {
        pageName: currentPageName,
        guid: currentReportGUIDFromUrl,
        json: JSON.stringify(responseJson),
      };

      axios
        .post(
          "/SRULines/insertAndUpdateLinesJsonTable",
          updateJsonTableObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => { });
    }
  }

  AmountOnBlur(e, num, heading) {
    let possibleAccessablePagesListLSString = localStorage.getItem(
      "possibleAccessablePages"
    );

    let possibleAccessablePagesListLSArray =
      possibleAccessablePagesListLSString &&
      JSON.parse(possibleAccessablePagesListLSString);

    // possibleAccessablePagesListLSArray.push("INK2");

    let possibleNavigationPagesListString = JSON.stringify(
      possibleAccessablePagesListLSArray
    );

    localStorage.setItem(
      "possibleAccessablePages",
      possibleNavigationPagesListString
    );

    const { INK2Value } = this.state;
    let custEnteredValue = e.target.value;
    custEnteredValue = custEnteredValue.replaceAll(",", "");
    custEnteredValue = custEnteredValue.replaceAll("kr", "");
    custEnteredValue = Number(custEnteredValue);
    let insertLinesArray = [];
    let insertLinesObj = {
      currentYearValue: custEnteredValue,
      isHeader: num["isHeader"],
      lineNo: num["lineNo"],
      lineType: num["lineType"],
      name: num["name"],
      no: num["no"],
      parentLineNo: num["parentLineNo"],
      reportGUID: currentReportGUIDFromUrl,
      statementType: "INK2",
    };
    insertLinesArray.push(insertLinesObj);

    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/SRULines/insertAndUpdateLines",
          insertLinesArray,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            this.dataManipulation(INK2Value, false, true);
          }
        });
    }
  }

  callback = (incomedeclaration2SRU, session, link, status) => {
    const { sessionDetails } = this.props;
    let sessionFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      this.props.history.push(incomedeclaration2SRU + sessionFromLS + link);
    }
    this.setState({
      reRender: true,
    });
  };

  navigateToNextPages(side) {
    const { sessionDetails } = this.props;
    let uuid = sessionDetails.sessionDetails.values.uuid;
    let index = selectedForms.indexOf(currentPageName);

    if (side == "next") {
      // Go To Next Page
      let nextPageName = selectedForms[index + 1];
      if (nextPageName != "" && nextPageName != undefined) {
        // Go To INK2R/INK2S
        this.props.history.push(
          "/income-declaration-2/" + uuid + "/" + nextPageName
        );
      }
      // Go To Payment Page
      if (nextPageName == undefined) {
        this.props.history.push("/income-declaration-2/" + uuid + "/payment");
      }
    } else {
      // Go To Previous Page
      let previousPageName = selectedForms[index - 1];
      if (previousPageName == undefined) {
        // Go To Company Info Page
        this.props.history.push(
          "/income-declaration-2/" + uuid + "/companyInfo"
        );
      } else {
        this.props.history.push(
          "/income-declaration-2/" + uuid + "/" + previousPageName
        );
      }
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };

  accordionHeader(header) {
    return (
      <Row>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{ width: "41%", marginTop: "7px", marginLeft: "2%" }}
        >
          <label
            className="SRU-INK2FieldsStyle"
            style={{ marginLeft: "-21px" }}
          >
            {header}
          </label>
        </Col>
      </Row>
    );
  }
  setClickedIndex(idx, parentIdx, number) {
    this.setState(prevState => ({
      clickedIndex: prevState.clickedIndex === idx ? null : idx
    }));
    this.setState({
      // clickedIndex: idx,
      clickedParentIndex: parentIdx,
      columnIndex: number,
    });
  }

  setRef = (index) => (el) => {
    this.inputNumberRef[index] = el;
  }

  render() {
    const {
      sideBarStatus,
      toggleOpen,
      activeIndex,
      sruINK2Value,
      companyName,
      registrationNo,
      clickedIndex,
      clickedParentIndex,
      columnIndex,
    } = this.state;
    let { showWIPStatus } = this.props;
    let pageUnderWIP = false;
    if (showWIPStatus.values != undefined) {
      if (showWIPStatus.values) {
        pageUnderWIP = true;
      } else {
        pageUnderWIP = false;
      }
    }
    return (
      <div>
        {/* <Toast
          ref={(el) => {
            this.toast = el;
          }}
          className="SRU-companyInfoErrorToast"
          style={{ marginTop: "34%" }}
        ></Toast> */}

        <SRUNavBarHeader
          callBack={this.callback}
          isAppPortal={true}
          isHomePage={false}
        />

        <div
          className={
            pageUnderWIP
              ? "SRU-companyInfoSideBarWithWIP"
              : "SRU-companyInfoSideBar"
          }
        >
          <Sidebar
            children="INK2"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={this.sideBarCallback.bind(this)}
          />
        </div>
        {/* <br></br> */}
        <div>
          <Row
            className={
              sideBarStatus
                ? "SRU-INK2FYStyle"
                : "SRU-INK2FYStyle-sidebar-close"
            }
          >
            <div className="SRU-INK2parent">
              <Col xs={5} sm={5} md={5} lg={5} xl={5} sruINK2TemplateReqArray>
                <div>
                  <div className="SRU-Page_Title_INK2">
                    <div className="SRU-Page_Title_T1_INK2">
                      {this.state.companyName}

                      <div>{sruINK2TemplateReqArray.map((selectedYear, fyIdx) => {
                        return (
                          <div className="SRU-INK2FYMainDiv">
                            {selectedYear.validFromDate} - {" "}{selectedYear.validToDate}
                          </div>
                        );
                      })}</div>

                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  outlined
                  style={{ borderRadius: "70px" }}
                  className="SRU-pdf_view_incomestatement"
                  tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
                  tooltipOptions={{ position: "bottom" }}
                // onClick={() => this.navigateToPDF()}
                // onClick={() => previewPdf(currentReportGUIDFromUrl)}
                />
              </Col>

              <div className="SRU-INK2ExapndCollapsToggleLbl">
                {toggleOpen == true ? (
                  <Button
                    value={toggleOpen}
                    icon="pi pi-chevron-down"
                    title="Expand"
                    onClick={(e) => this.handleChange(toggleOpen)}
                    className="SRU-toggle-open"
                  />
                ) : (
                  <Button
                    value={toggleOpen}
                    icon="pi pi-chevron-up"
                    title="Collapse"
                    onClick={(e) => this.handleChange(toggleOpen)}
                    className="SRU-toggle-open"
                  />
                )}
              </div>
            </div>
          </Row>
        </div>

        <div
          className={
            sideBarStatus ? "SRU-INK2SideBarOpen" : "SRU-INK2SideBarClose"
          }
        >
          <div>
            <Row className="SRU-INK2ParentRow">
              <Col
                style={{
                  width: "100%",
                }}
              >
                {sruINK2Value != undefined && (
                  <Accordion
                    multiple
                    activeIndex={activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                    ref={this.myRef}
                    className="SRU-INK2-accordion"
                  >
                    {Object.keys(sruINK2Value).map((heading, parentIdx) => {
                      let responseArray = sruINK2Value[heading];
                      responseArray = [...responseArray].sort(
                        (a, b) => a.lineNo - b.lineNo
                      );
                      let header = responseArray[0].name;

                      // let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
                      let numbers = responseArray;
                      numbers = numbers.filter(
                        (num) => num.lineType != "Header"
                      );
                      let firstHalf = [];
                      let secondHalf = [];

                      for (let i = 0; i < Math.ceil(numbers.length / 2); i++) {
                        firstHalf.push(numbers[i]);
                      }
                      for (
                        let i = Math.ceil(numbers.length / 2);
                        i < numbers.length;
                        i++
                      ) {
                        secondHalf.push(numbers[i]);
                      }

                      return (
                        <AccordionTab
                          header={this.accordionHeader(header)}
                          ref={this.myRef}
                          className="SRU-INK2-accordion-Tab"
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2,1fr)",
                              marginLeft: "5%",
                            }}
                          >
                            <div>
                              {firstHalf.map((num, idx) => {
                                switch (num.valueType) {
                                  case "Positive":
                                    num.valueType = (
                                      <i className="pi pi-plus-circle" />
                                    );
                                    break;
                                  case "Negative":
                                    num.valueType = (
                                      <i className=" pi pi-minus-circle" />
                                    );
                                    break;
                                  case "Equal":
                                    num.valueType = "=";
                                    break;
                                  case "Yes/No":
                                    num.valueType = "Yes/No";
                                    break;
                                  default:
                                    num.valueType = "";
                                }
                                if (num.valueType != "Yes/No") {
                                  return (
                                    <div>
                                      <Row className="SRU-ISFields" key={idx}>
                                        <Col
                                          // className="INK2Main_column"
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                        >
                                          <div>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "10px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {num.serialNumber}
                                              </td>
                                              <td>
                                                {num.name}
                                                {clickedIndex === idx &&
                                                  clickedParentIndex ===
                                                  parentIdx &&
                                                  columnIndex == 1 && (
                                                    <span>
                                                      {num.mapToAccountNos !=
                                                        "" && (
                                                          <div className={`tooltip-container ${clickedIndex === idx ? 'show' : ''}`}>
                                                            <FcInfo style={{ marginLeft: "10px" }} />
                                                            <span className="tooltip-text">
                                                              Mapping Number = {num.mapToAccountNos}
                                                            </span>
                                                          </div>
                                                        )}
                                                    </span>
                                                  )}
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>

                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          className="SRU-ISAmountBoxCol"
                                        >
                                          <div>
                                            <tr>
                                              <td>{num.valueType}</td>
                                              <td></td>
                                              <td>
                                                <InputNumber
                                                  className="SRU-ISAmountBoxDisabled"
                                                  mode="decimal"
                                                  suffix=" kr"
                                                  placeholder="kr"

                                                  onBlur={(e) => {
                                                    this.AmountOnBlur(e, num, heading);
                                                  }}
                                                  onClick={() => { this.setClickedIndex(idx, parentIdx, 1) }}
                                                  ref={this.setRef(idx)}
                                                  locale="sv-SE"
                                                // onMouseDown={(e) => e.preventDefault()} 
                                                />
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div>
                                      <Row className="SRU-ISFields" key={idx}>
                                        <Col
                                          // className="INK2Main_column"
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                        >
                                          <div>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "10px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {num.serialNumber}
                                              </td>
                                              <td>
                                                {num.name}
                                                {clickedIndex === idx &&
                                                  clickedParentIndex ===
                                                  parentIdx &&
                                                  columnIndex == 1 && (
                                                    <span>
                                                      {num.mapToAccountNos !=
                                                        "" && (
                                                          <div className={`tooltip-container ${clickedIndex === idx ? 'show' : ''}`}>
                                                            <FcInfo style={{ marginLeft: "10px" }} />
                                                            <span className="tooltip-text">
                                                              Mapping Number = {num.mapToAccountNos}
                                                            </span>
                                                          </div>
                                                        )}
                                                    </span>
                                                  )}
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>

                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          className="SRU-ISAmountBoxCol"
                                        >
                                          <div>
                                            <tr>
                                              {/* <td>{num.valueType}</td> */}
                                              <td>
                                                Yes
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedYesbtn
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      checkedYesbtn: e.checked,
                                                    })
                                                  }
                                                />
                                              </td>

                                              <td>
                                                No
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedNobtn
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      checkedNobtn: e.checked,
                                                    })
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <div>
                              {secondHalf.map((num, idx) => {
                                switch (num.valueType) {
                                  case "Positive":
                                    num.valueType = (
                                      <i className="pi pi-plus-circle" />
                                    );
                                    break;
                                  case "Negative":
                                    num.valueType = (
                                      <i className=" pi pi-minus-circle" />
                                    );
                                    break;
                                  case "Equal":
                                    num.valueType = "=";
                                    break;
                                  case "Yes/No":
                                    num.valueType = "Yes/No";
                                    break;
                                  default:
                                    num.valueType = "";
                                }
                                if (num.valueType != "Yes/No") {
                                  return (
                                    <div>
                                      <Row className="SRU-ISFields" key={idx}>
                                        <Col
                                          // className="INK2Main_column"
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                        >
                                          <div>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "10px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {num.serialNumber}
                                              </td>
                                              <td>
                                                {num.name}
                                                {clickedIndex === idx &&
                                                  clickedParentIndex ===
                                                  parentIdx &&
                                                  columnIndex == 2 && (
                                                    <span>
                                                      {num.mapToAccountNos !=
                                                        "" && (
                                                          <div className={`tooltip-container ${clickedIndex === idx ? 'show' : ''}`}>
                                                            <FcInfo style={{ marginLeft: "10px" }} />
                                                            <span className="tooltip-text">
                                                              Mapping Number = {num.mapToAccountNos}
                                                            </span>
                                                          </div>
                                                        )}
                                                    </span>
                                                  )}
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>

                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          className="SRU-ISAmountBoxCol"
                                        >
                                          <div>
                                            <tr>
                                              <td>{num.valueType}</td>
                                              <td></td>
                                              <td>
                                                <InputNumber
                                                  className="SRU-ISAmountBoxDisabled"
                                                  mode="decimal"
                                                  suffix=" kr"
                                                  placeholder="kr"
                                                  onBlur={(e) => {
                                                    this.AmountOnBlur(e, num, heading);
                                                  }}
                                                  ref={this.setRef(idx)}
                                                  // onMouseDown={(e) => e.preventDefault()}
                                                  onClick={() => this.setClickedIndex(idx, parentIdx, 2)}
                                                  locale="sv-SE"
                                                />
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div>
                                      <Row className="SRU-ISFields" key={idx}>
                                        <Col
                                          // className="INK2Main_column"
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                        >
                                          <div>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: "10px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {num.serialNumber}
                                              </td>
                                              <td>
                                                {num.name}
                                                {clickedIndex === idx &&
                                                  clickedParentIndex ===
                                                  parentIdx &&
                                                  columnIndex == 2 && (
                                                    <span>
                                                      {num.mapToAccountNos !=
                                                        "" && (
                                                          <div className={`tooltip-container ${clickedIndex === idx ? 'show' : ''}`}>
                                                            <FcInfo style={{ marginLeft: "10px" }} />
                                                            <span className="tooltip-text">
                                                              Mapping Number = {num.mapToAccountNos}
                                                            </span>
                                                          </div>
                                                        )}
                                                    </span>
                                                  )}
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>

                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          lg={5}
                                          xl={5}
                                          className="SRU-ISAmountBoxCol"
                                        >
                                          <div>
                                            <tr>
                                              {/* <td>{num.valueType}</td> */}
                                              <td>
                                                Yes
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedYesbtn2
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      checkedYesbtn2: e.checked,
                                                    })
                                                  }
                                                />
                                              </td>

                                              <td>
                                                No
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedNobtn2
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      checkedNobtn2: e.checked,
                                                    })
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </AccordionTab>
                      );
                    })}
                  </Accordion>
                )}
              </Col>
            </Row>
          </div>

          <center>
            <Button
              label="Previous"
              aria-label="year-btn-save"
              onClick={() => this.navigateToNextPages("previous")}
              style={{
                marginTop: "2%",
                marginRight: "1%",
              }}
              // className="ISSaveBtn"
              className="SRU-year-btn-save"
            />
            <Button
              label="Next"
              aria-label="year-btn-save"
              onClick={() => this.navigateToNextPages("next")}
              style={{
                marginTop: "2%",
              }}
              // className="ISSaveBtn"
              className="SRU-year-btn-save"
            />
          </center>
          <br></br>
          <br></br>
        </div>
        <ScrolltoTop />
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(INK2);
