
import React from "react";
import videoplay from "../../Assests/Videos/videoplay.mp4"
import "./DemoSlide.css";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";

let userSelectedLanguage;
export default function DemoSlide() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const slideListTemplate = (slideList) => {
    return (
      <div>
        <img src={slideList.img} className="demo-slide-image"></img>
      </div>
    );
  };
  return (
    <div className="caro-demo">
      <div>
        <div>
          <div className="caro-demo-slide">
            <center>
              <br></br>
              <h4 className="demo-title-heading">
                {TranslationFile.demoTitle1[userSelectedLanguage]}
                <br></br>
                <br></br>
                {TranslationFile.demoTitle2[userSelectedLanguage]}
              </h4>
            </center>
            <center>

              <video
                className="Demo_slide_video"
                style={{ width: "1092px", height: "611px" }}
                controls
                autoPlay
              >
                <source src={videoplay} />
              </video>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}
