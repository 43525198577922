import SRUSlideList from "./SRUImageSlider";
import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import image1 from "../../../Assests/image1.png";
import "./SRUDemoSlide.css";
import TranslationFile from "../../15-Translation/locale/Translation_apps.json";

let userSelectedLanguage;
export default function SRUDemoSlide() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");

  const slideListTemplate = (SRUSlideList) => {
    return (
      <div>
        <img src={SRUSlideList.img} className="demo-slide-image"></img>
      </div>
    );
  };
  return (
    <div className="caro-demo">
      <div>
        <div>
          <div className="caro-demo-slide">
            <center>
              <br></br>
              <h4 className="demo-title-heading">
                <br></br>
                {TranslationFile.demoTitle2[userSelectedLanguage]}
              </h4>
            </center>
            <center>
              <Carousel
                value={SRUSlideList}
                numVisible={1}
                numScroll={1}
                className="demo-work-carousel"
                circular={false}
                autoplayInterval={3000}
                itemTemplate={slideListTemplate}
              />
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}
