import React, { Component } from "react";
import Config from "../../Configuration/Config";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import { Link } from "react-router-dom";
import { Image } from "primereact/image";
import logo from "../../Assests/RebelSkool.png";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import "./PageNotFound.css";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";

let userSelectedLanguage;
class PageNotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }
  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 4000);
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="Error-Main-Div">
        <NavBarHeader />
        {isLoading ? (
          <div className="ISLoading">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        ) : (
          <div className="forget-password-container">
            <center>
              <div className="forget-password-logo">
                <Link to="/">
                  <Image src={logo} alt="Image" width="200" />
                </Link>
              </div>
            </center>

            <Fieldset legend="Page Not Found">
              <div>
                <center>
                  <Link to="/">
                    <Button label={TranslationFile.backToHomeText[userSelectedLanguage]} aria-label={TranslationFile.yearBtnSaveText[userSelectedLanguage]} />
                  </Link>
                </center>
              </div>
            </Fieldset>
          </div>
        )}
      </div>
    );
  }
}

export default PageNotFound;
