import React, { Component } from "react";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import "../ID_SRUFiles/ID_SRUFiles.css";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
let userSelectedLanguage;

class ID_SRUFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <NavBarHeader />
        <div className="ID_SRU_Homepage">
          <br></br>
          <br></br>
          <div
            style={{
              textTransform: "uppercase",
              fontWeight: 500,
              fontSize: "30px",
              textAlign: "center",
            }}
          >
            Income Declaration <span style={{ color: "red" }}>SRU-Files </span>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <section
            style={{
              fontSize: "25px",
              fontWeight: "800",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Coming Fall 2024
          </section>
          <br></br>
          <br></br>
          <br></br>
          <section>
            1. Introduction These Website Standard Terms And Conditions (these
            “Terms” or these “Website Standard Terms And Conditions”) contained
            herein on this webpage, shall govern your use of this website,
            including all pages within this website (collectively referred to
            herein below as this “Website”). These Terms apply in full force and
            effect to your use of this Website and by using this Website, you
            expressly accept all terms and conditions contained herein in full.
            You must not use this Website, if you have any objection to any of
            these Website Standard Terms And Conditions. This Website is not for
            use by any minors (defined as those who are not at least 18 years of
            age), and you must not use this Website if you a minor.
          </section>
        </div>
        <ScrolltoTop />
        <Footer_contact_tab sideBar={this.state.sideBarStatus} isCorpPortal={false} isHomePage={true} />

      </div>
    );
  }
}
export default ID_SRUFiles;
