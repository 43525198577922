import React, { Component } from "react";
import Sidebar from "../SRUSidebar/SRUSidebar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { connect } from "react-redux";
import { InputMask } from "primereact/inputmask";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import "./SRUPayment.css";
import { Carousel } from "primereact/carousel";
import { Toast } from "primereact/toast";
import ScrolltoTop from "../../ScrollTop/ScrollTop";
import moment from "moment";
import { setUserSessionAuthKey, numberWithCommasAndSpace, previewPdf } from "../../../Component/commonUtils";
import TranslationFile from "../../15-Translation/locale/Translation_apps.json";
import SRUNavBarHeader from "../SRUNavBarHeader/SRUNavBarHeader";
import config from "../../../Configuration/Config";
import Footer_contact_tab from "../../Footerpage/Footer_contact_tab";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails.sessionDetails.values,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    payment: state.payment.payment,
  };
};

let userSelectedLanguage;
let itemsForPostPayson = [];
let paysonIDFromResponse = "";
let cartValuesLocalData = [];
let defaultPackageValue = [],
  restOfThePackage = [],
  filteredStatementType = [],
  finalCheckoutArray = [],
  gridData = [],
  carouselData = [],
  confirmDiscountOK = 0,
  custType = "",
  sideBarGreenTickPages = [],
  previouslyBoughtPackages = [],
  currentReportGUIDFromUrl = "",
  annualReportHeaderAPIResp = "",
  navigationUrl = "";
let iFrameID = "",
  showPayment,
  unitPriceWithMomsMultiByQty = "";
const responsiveOptions = [
  {
    breakpoint: "1199px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "991px",
    numVisible: 1,
    numScroll: 1,
  },
];
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let testData = [],
  billingInfoUpdated = false,
  payFromHome = false;

class SRUPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      currentFinancialYear: "",
      organizationNumber: "",
      postalCode: "",
      city: "",
      activeIndex: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      toggleOpen: false,
      userCompanyType: "Private Company",
      paymentType: "Credit Card",
      orgNumber: "",
      accountingCompanyOrgName: "",
      accountingCompanyPostalCode: "",
      accountingCompanyCity: "",
      emailId: "",
      name: "",
      pricesList: [],
      defaultPrice: [],
      discountCodeRupee: 0,
      totalReportCount: 0,
      totalUsedReportCount: 0,
      remainingReportCount: 0,
      packageEntryNoToUse: 0,
      activePackageAvailable: "",
      registrationNoFromDB: "",
      postalCodeFromDB: "",
      cityFromDB: "",
      iframeKey: 0,
      totalPriceExcMoms: "",
      totalMoms: "",
      totalPriceIncMoms: "",
      paymentEntryNo: "",
      showPaidDialogBox: false,
      billingAddress: "",
      reportPaymentCheckout: false,
      isLoading: true,
    };
    this.removeBodyTemplate = this.removeBodyTemplate.bind(this);
    this.quantityBodyTemplate = this.quantityBodyTemplate.bind(this);
    this.carouselButton = this.carouselButton.bind(this);
    this.packageCarouselRender = this.packageCarouselRender.bind(this);
  }
  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  companyNameOnBlur(e) {
    let { companyInformation, payment, login } = this.props;
    if (userSessionAuthKeyReturnValue) {
      payment.values.organizationame = e.target.value;

      let billingInfo = {
        key: "companyName",
        value: e.target.value,
        checkoutId: paysonIDFromResponse,
      };

      axios
        .post(
          "/annualReportPackage/updatePaymentHeaderBillingInfo",
          billingInfo,
          userSessionAuthKeyReturnValue
        )
        .then((updateCompanyNameResponse) => { });
    }
  }

  postalCodeOnBlur(e) {
    let { companyInformation, payment, login } = this.props;
    if (userSessionAuthKeyReturnValue) {
      payment.values.postalcode = e.target.value;

      let billingInfo = {
        key: "postalCode",
        value: e.target.value,
        // userId: login.userID,
        checkoutId: paysonIDFromResponse,
      };

      axios
        .post(
          "/annualReportPackage/updatePaymentHeaderBillingInfo",
          billingInfo,
          userSessionAuthKeyReturnValue
        )
        .then((updateCompanyNameResponse) => { });
    }
  }

  cityOnBlur(e) {
    let { companyInformation, payment, login } = this.props;
    if (userSessionAuthKeyReturnValue) {
      payment.values.postalcode = e.target.value;

      let billingInfo = {
        key: "city",
        value: e.target.value,
        // userId: login.userID,
        checkoutId: paysonIDFromResponse,
      };

      axios
        .post(
          "/annualReportPackage/updatePaymentHeaderBillingInfo",
          billingInfo,
          userSessionAuthKeyReturnValue
        )
        .then((updateCompanyNameResponse) => { });
    }
  }

  organizationNoOnComplete(e) {
    let { companyInformation, payment, login } = this.props;

    this.setState({
      organizationNumber: e.value,
    });
    if (userSessionAuthKeyReturnValue) {
      payment.values.organizationnumber = e.value;
      axios
        .get("/organization/get/" + e.value, userSessionAuthKeyReturnValue)
        .then((response) => {
          if (response.data != "") {
            this.setState({
              organizationNumber: response.data.organizationnumber,
              companyName: response.data.organizationname,
              postalCode: response.data.postalcode,
              city: response.data.city,
            });
            let companyInformationValue = {
              organizationname: response.data.organizationname,
              organizationnumber: response.data.organizationnumber,
              postalcode: response.data.postalcode,
              city: response.data.city,
            };
            payment.values.organizationnumber =
              response.data.organizationnumber;
            payment.values.organizationname = response.data.organizationname;
            payment.values.postalcode = response.data.postalcode;
            payment.values.city = response.data.city;

            // companyInformation.values = companyInformationValue;

            let billingInfo = {
              key: "all",
              value: "all",
              // userId: login.userID,
              checkoutId: paysonIDFromResponse,
              companyName: response.data.organizationname,
              registrationNo: response.data.organizationnumber,
              postalCode: response.data.postalcode,
              city: response.data.city,
              name: this.state.name,
              lastName: this.state.lastName,
              emailId: this.state.emailId,
            };

            axios
              .post(
                "/annualReportPackage/updatePaymentHeaderBillingInfo",
                billingInfo,
                userSessionAuthKeyReturnValue
              )
              .then((updateCompanyNumberResponse) => { });
          }
        });
    }
  }

  organizationNoOnChange(e) {
    const { payment } = this.props;
    payment.values.organizationnumber = e.value;
  }

  companyNameOnChange(e) {
    const { payment } = this.props;
    this.setState({
      accountingCompanyOrgName: e.target.value,
      companyName: e.target.value,
    });
    payment.values.organizationname = e.target.value;
  }

  PostalcodeOnChange(e) {
    const { payment } = this.props;
    this.setState({
      accountingCompanyPostalCode: e.target.value,
      postalCode: e.target.value,
    });
    payment.values.postalcode = e.target.value;
  }

  cityOnChange(e) {
    const { payment } = this.props;
    this.setState({
      accountingCompanyCity: e.target.value,
      city: e.target.value,
    });
    payment.values.city = e.target.value;
  }

  paymentNameOnChange(e, name) {
    const { payment } = this.props;
    if (name == "name") {
      this.setState({
        name: e.target.value,
      });
    } else {
      this.setState({
        lastName: e.target.value,
      });
    }

    payment.values.name = e.target.value;
  }

  paymentNameOnBlur(e, name) {
    let billingInfo = "";
    if (name == "name") {
      billingInfo = {
        key: "name",
        value: e.target.value,
        checkoutId: paysonIDFromResponse,
      };
    } else {
      billingInfo = {
        key: "lastName",
        value: e.target.value,
        checkoutId: paysonIDFromResponse,
      };
    }
    axios
      .post(
        "/annualReportPackage/updatePaymentHeaderBillingInfo",
        billingInfo,
        userSessionAuthKeyReturnValue
      )
      .then((updateCompanyNameResponse) => { });
  }

  paymentEmailOnChange(e) {
    const { payment } = this.props;
    this.setState({
      emailId: e.target.value,
    });
    payment.values.emailId = e.target.value;
  }
  paymentEmailOnBlur(e) {
    let billingInfo = {
      key: "emailId",
      value: e.target.value,
      checkoutId: paysonIDFromResponse,
    };

    axios
      .post(
        "/annualReportPackage/updatePaymentHeaderBillingInfo",
        billingInfo,
        userSessionAuthKeyReturnValue
      )
      .then((updateCompanyNameResponse) => { });
  }

  handleChange(toggleOpen) {
    window.scroll(0, 0);
    if (toggleOpen == true) {
      let exp = [0, 1, 2, 3, 4, 5, 6];
      this.setState({ activeIndex: exp });
      this.setState({ toggleOpen: false });
    } else {
      let collapse = [];
      this.setState({ activeIndex: collapse });
      this.setState({ toggleOpen: true });
    }
  }

  removePackageFromCart(rowData) {
    const { login } = this.props;
    if (gridData.length > 1) {
      let cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
      let filteredPeople =
        login != undefined &&
        cartValuesLocalData &&
        cartValuesLocalData.filter((item) => item.userID == login.userID);
      let cartArrayList =
        filteredPeople &&
        filteredPeople.length > 0 &&
        filteredPeople[0].cartArray;

      const arr = cartArrayList.filter(
        (item) => item.packageId != rowData.packageId
      );
      gridData = arr;
      gridData = gridData && gridData.filter((val) => val.application == "SRU")

      let cartWithUserID = [
        {
          userID: login.userID,
          cartArray: arr,
        },
      ];
      localStorage.setItem("cartValues", []);
      localStorage.setItem("cartValues", JSON.stringify(cartWithUserID));
      this.updatePaysonCheckout();

      this.setState({
        reRender: true,
      });
    } else {
      this.toast.show({
        severity: "warn",
        summary: "Couldn't delete the package",
        detail: "The Cart should have atleast 1 package",
        life: 3000,
      });
    }
  }

  handleQuantityChange(item, d) {
    if (gridData.length == 1 && gridData[0].quantity == 1 && d < 0) {
      this.toast.show({
        severity: "warn",
        summary: "Couldn't delete the package",
        detail: "The Cart should have atleast 1 package",
        life: 3000,
      });
    } else {
      const { login } = this.props;
      cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
      let filteredPeople =
        login != undefined &&
        cartValuesLocalData &&
        cartValuesLocalData.filter((item) => item.userID == login.userID);
      let cartArrayList =
        filteredPeople &&
        filteredPeople.length > 0 &&
        filteredPeople[0].cartArray;
      let cartValuesHandleChange = cartArrayList;

      let ind = -1;
      cartValuesHandleChange.forEach((data, index) => {
        if (data.packageId == item.packageId) ind = index;
      });

      cartValuesHandleChange[ind].quantity =
        cartValuesHandleChange[ind].quantity + d;

      let cartWithUserID = [
        {
          userID: login.userID,
          cartArray: cartValuesHandleChange,
        },
      ];

      gridData = cartValuesHandleChange;
      gridData = gridData && gridData.filter((val) => val.application == "SRU")

      localStorage.setItem("cartValues", JSON.stringify(cartWithUserID));

      if (cartValuesHandleChange[ind].quantity == 0) {
        this.removePackageFromCart(cartValuesHandleChange[ind]);
      }
      this.updatePaysonCheckout();
      this.setState({
        reRender: true,
      });
    }
  }

  removeBodyTemplate(rowData) {
    return (
      <div>
        <Button
          icon="pi pi-times"
          rounded
          text
          severity="danger"
          aria-label={TranslationFile.CancelBtn[userSelectedLanguage]}
          onClick={() => this.removePackageFromCart(rowData)}
        />
      </div>
    );
  }

  priceBodyTemplate(rowData) {
    return (
      <div>{numberWithCommasAndSpace(rowData.price * rowData.quantity)} kr</div>
    );
  }
  unitPriceBodyTemplate(rowData) {
    return <div>{numberWithCommasAndSpace(rowData.price)} kr</div>;
  }

  quantityBodyTemplate(rowData) {
    return (
      <div>
        <span>
          <Button
            icon="pi pi-minus"
            text
            outlined
            aria-label="Cancel"
            onClick={() => this.handleQuantityChange(rowData, -1)}
          />
        </span>
        <span>
          <InputNumber
            style={{
              width: "2em",
              height: "2em",
            }}
            value={rowData.quantity}
            className="cart-item-amount22"
          />
        </span>
        <span>
          <Button
            icon="pi pi-plus"
            text
            aria-label="Cancel"
            className="cart-plus-button"
            onClick={() => this.handleQuantityChange(rowData, +1)}
          />
        </span>
      </div>
    );
  }

  settingDefaultCartValue(defaultValue) {
    const { login } = this.props;
    if (login != undefined) {
      let pricesListWithoutAddOn = defaultValue.filter(
        (obj) => obj.visibleInGrid
      );

      if (login.isAccountingCompany) {
        carouselData = defaultValue;
      } else {
        carouselData = pricesListWithoutAddOn;
      }
      gridData = defaultValue;
      gridData = defaultValue.filter((item) =>
        (item.customerType == login.accountingCompanyRegNo) != null
          ? "Accounting Company"
          : "User (Private person)"
      );

      gridData = gridData && gridData.filter(
        (e) => e.packageId === Math.min(...gridData.map((f) => f.packageId))
      );
      gridData[0]["quantity"] = 1;
      gridData = gridData && gridData.filter((val) => val.application == "SRU")

      this.updateTotalAmounts(gridData);

      this.setState({
        reRender: true,
      });

      cartValuesLocalData =
        (localStorage.getItem("cartValues") &&
          JSON.parse(localStorage.getItem("cartValues"))) ||
        [];

      let otherUsersCartArray =
        cartValuesLocalData &&
        cartValuesLocalData.filter((item) => item.userID != login.userID);

      let cartWithUserID = {
        userID: login.userID,
        cartArray: gridData,
      };

      otherUsersCartArray.push(cartWithUserID);
      localStorage.setItem("cartValues", JSON.stringify(otherUsersCartArray));
    }
  }

  updateTotalAmounts(cartArrayList) {
    let totalPriceExcMomsLoc = 0.0;
    cartArrayList &&
      cartArrayList.length &&
      cartArrayList.map((item, itemIdx) => {
        totalPriceExcMomsLoc =
          totalPriceExcMomsLoc + item.price * item.quantity;

        if (cartArrayList.length == itemIdx + 1) {
          let totalMomsLoc = 0.0;
          totalMomsLoc = totalPriceExcMomsLoc * 0.25;

          let totalPriceIncMomsLoc = 0.0;
          totalPriceIncMomsLoc = totalPriceExcMomsLoc + totalMomsLoc;

          this.setState({
            totalPriceExcMoms: numberWithCommasAndSpace(
              totalPriceExcMomsLoc.toFixed(2)
            ),
            totalMoms: numberWithCommasAndSpace(totalMomsLoc.toFixed(2)),
            totalPriceIncMoms: numberWithCommasAndSpace(
              totalPriceIncMomsLoc.toFixed(2)
            ),
          });
        }
      });
  }

  getAnnualReportPackageUsageAPICall(login) {
    if (userSessionAuthKeyReturnValue) {
      let cartValuesLocalData =
        JSON.parse(localStorage.getItem("cartValues")) || [];
      let filteredPeople =
        login != undefined &&
        cartValuesLocalData &&
        cartValuesLocalData.filter((item) => item.userID == login.userID);
      let cartArrayList =
        filteredPeople &&
        filteredPeople.length > 0 &&
        filteredPeople[0].cartArray;

      let { activePackageAvailable } = this.state;
      axios
        .get(
          "/annualReportPackage/getPaymentLines",
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let activePackageList = response.data.filter(
            (obj) => obj.status == true
          );

          if (activePackageList.length > 0) {
            this.setState({ activePackageAvailable: true });
          }
          let packageWithDateDiffernceList = [],
            packageWithUnlimitedDateList = [];

          activePackageList.length &&
            activePackageList.map((i, idx) => {
              let startDate = moment(i.startingDate);
              let endDate = moment(i.validUntil);
              let dateDifference = startDate.diff(endDate, "days");

              let packageWithDateDiffernce = {
                entryNo: 0,
                dateDifference: 0,
              };

              packageWithDateDiffernce.entryNo = i.entryNo;
              if (dateDifference < 0) {
                packageWithDateDiffernce.dateDifference = dateDifference * -1;
                packageWithDateDiffernceList.push(packageWithDateDiffernce);
              } else {
                packageWithUnlimitedDateList.push(packageWithDateDiffernce);
              }
            });

          let lowestDifferenceDate = [];
          let getLowestDifferenceDateObj = [];

          if (packageWithDateDiffernceList.length > 0) {
            lowestDifferenceDate = Math.min(
              ...packageWithDateDiffernceList.map((item) => item.dateDifference)
            );

            getLowestDifferenceDateObj = packageWithDateDiffernceList.filter(
              (obj) => {
                return obj.dateDifference == lowestDifferenceDate;
              }
            );
          } else {
            lowestDifferenceDate = packageWithUnlimitedDateList[0];
            if (lowestDifferenceDate != undefined)
              getLowestDifferenceDateObj = [lowestDifferenceDate];
          }

          this.setState({
            packageEntryNoToUse:
              getLowestDifferenceDateObj.length &&
              getLowestDifferenceDateObj[0].entryNo,
          });

          let totalReportCount = activePackageList.reduce(
            (obj, { maxUsage }) => obj + maxUsage,
            0
          );
          this.setState({
            totalReportCount: totalReportCount,
          });

          let totalUsedReportCount = activePackageList.reduce(
            (obj, { actualUsage }) => obj + actualUsage,
            0
          );
          this.setState({
            totalUsedReportCount: totalUsedReportCount,
          });

          let remainingReportCount = totalReportCount - totalUsedReportCount;
          this.setState({
            remainingReportCount: remainingReportCount,
          });
        });
    }
  }

  componentWillReceiveProps() {
    const { login } = this.props;
    cartValuesLocalData =
      (localStorage.getItem("cartValues") &&
        JSON.parse(localStorage.getItem("cartValues"))) ||
      [];
    let filteredPeople =
      login != undefined &&
      cartValuesLocalData &&
      cartValuesLocalData.filter((item) => item.userID == login.userID);
    let cartArrayList =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;
    gridData = cartArrayList;
    gridData = gridData && gridData.filter((val) => val.application == "SRU")

  }

  componentDidMount() {
    const { prices, login, companyInformation, payment } = this.props;

    const { organizationNumber, companyName, postalCode, city } = this.state;

    if (login != undefined && userSessionAuthKeyReturnValue) {
      // From Reducer
      this.setState({
        emailId: login.emailID,
        name: login.userName,
      });

      payment.values.name = login.userName;
      payment.values.emailId = login.emailID;

      if (
        login.accountingCompanyRegNo != null &&
        login.accountingCompanyRegNo != ""
      ) {
        axios
          .get(
            "/accountingCompany/getCompanyDetailsAndUsers?accountingCompanyId=" +
            login.accountingCompanyRegNo,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            this.setState({
              accountingCompanyOrgName:
                response.data.accountingCompany.accountingCompanyName,
              accountingCompanyPostalCode:
                response.data.accountingCompany.postalCode,
              accountingCompanyCity: response.data.accountingCompany.city,
            });
          });
      }
    }
  }

  handleAddToCartClick(item) {
    defaultPackageValue.push(item);
    this.setState({
      rerender: true,
    });
  }
  handleCartPrice() {
    const { login } = this.props;
    let cartValuesLocalData =
      (localStorage.getItem("cartValues") &&
        JSON.parse(localStorage.getItem("cartValues"))) ||
      [];
    let filteredPeople = [];
    if (login != undefined) {
      filteredPeople =
        cartValuesLocalData &&
        cartValuesLocalData.filter((item) => item.userID == login.userID);
    }
    let cartArrayList =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;
  }

  callback = (annualReport, session, link, status) => {
    const { login } = this.props;
    cartValuesLocalData = JSON.parse(localStorage.getItem("cartValues") || []);
    let filteredPeople =
      login != undefined &&
      cartValuesLocalData &&
      cartValuesLocalData.filter((item) => item.userID == login.userID);
    let cartArrayList =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;
    let gridDataTemp = [];

    cartArrayList &&
      cartArrayList.length &&
      cartArrayList.map((i, idx) => {
        i.unitPrice = i.price + (i.price * 25) / 100;
        gridDataTemp.push(i);
      });
    gridData = gridDataTemp;
    gridData = gridData && gridData.filter((val) => val.application == "SRU")

    // gridData = cartArrayList;

    this.setState({
      reRender: true,
    });
  };
  languageValue = (e) => {
    this.setState({
      selectedLanguage: e,
    });
  };

  updatePaysonCheckout = () => {
    const { login, payment } = this.props;
    itemsForPostPayson = [];
    if (userSessionAuthKeyReturnValue) {
      let itemsObject = {
        name: "",
        unitPrice: 0,
        quantity: 0,
        taxRate: 0.25,
      };

      let totalSumIncludingMoms = 0;
      gridData &&
        gridData.length &&
        gridData.map((val) => {
          itemsObject = {
            name: val.text1,
            packageId: val.packageId,
            packageDetailsName: val.packageName,
            accountingCompanyId: login.isAccountingCompany == true ? 1 : 0,
            unitPrice: val.price + (val.price * 25) / 100,
            quantity: val.quantity,
            taxRate: 0,
            status: 1,
            maxUsage: val.maxUsage * val.quantity,
            actualUsage: 0,
            type: "service",
          };
          itemsForPostPayson.push(itemsObject);
        });

      gridData = gridData && gridData.filter((val) => val.application == "SRU")

      this.updateTotalAmounts(gridData);

      const incomeDeclaration2SRU = "/income-declaration-2/";

      let updateCheckout = {
        application: 'SRU',
        userId: login.userID,
        status: "created",
        id: paysonIDFromResponse,
        merchant: {
          checkoutUri: "https://www.examplestore.com/checkout.php",
          confirmationUri: navigationUrl,
          // notificationUri: "https://www.examplestore.com/notification.php",
          notificationUri:
            config.SRU.url +
            "/notification?checkout=" +
            paysonIDFromResponse,
          termsUri: "https://www.examplestore.com/terms.php",
        },
        order: {
          currency: "sek",
          items: itemsForPostPayson,
        },
      };
      axios
        .put(
          "/annualReportPackage/paysonUpdateCheckout",
          updateCheckout,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            paysonIDFromResponse = response.data.id;
            payment.values.checkoutRef = paysonIDFromResponse;
            iFrameID =
              "https://test-www.payson.se/embedded/checkout?id=" +
              paysonIDFromResponse;

            this.setState({
              rerender: true,
              iframeKey: this.state.iframeKey + 1,
            });
          }
        });
    }
  };

  createPaysonCheckout() {
    const { login, payment } = this.props;
    const {
      registrationNoFromDB,
      organizationNumber,
      accountingCompanyOrgName,
      companyNameFromDB,
      companyName,
      accountingCompanyPostalCode,
      postalCodeFromDB,
      postalCode,
      accountingCompanyCity,
      cityFromDB,
      city,
    } = this.state;
    cartValuesLocalData = JSON.parse(localStorage.getItem("cartValues"));
    if (cartValuesLocalData == null) {
      cartValuesLocalData = [];
    }
    let filteredPeople = [];
    if (login != undefined) {
      filteredPeople =
        cartValuesLocalData &&
        cartValuesLocalData.filter((item) => item.userID == login.userID);
    }
    let cartArrayList =
      filteredPeople &&
      filteredPeople.length > 0 &&
      filteredPeople[0].cartArray;
    itemsForPostPayson = [];

    let itemsObject = {
      name: "",
      unitPrice: 0,
      quantity: 0,
      taxRate: 0.25,
    };
    let totalSumIncludingMoms = 0;
    cartArrayList &&
      cartArrayList.length &&
      cartArrayList.map((val) => {
        let itemsObject = {
          name: val.text1,
          packageDetailsName: val.packageName,
          packageId: val.packageId,
          accountingCompanyId: Math.round(
            login.isAccountingCompany == true ? 1 : 0
          ),
          unitPrice: val.price + (val.price * 25) / 100,
          quantity: val.quantity,
          taxRate: 0,
          status: 1,
          maxUsage: val.maxUsage * val.quantity,
          actualUsage: 0,
          type: "service",
        };

        totalSumIncludingMoms =
          totalSumIncludingMoms + itemsObject.quantity * itemsObject.unitPrice;
        itemsForPostPayson.push(itemsObject);
      });

    const incomeDeclaration2SRU = "/income-declaration-2/";

    let createCheckout = {
      application: 'SRU',
      userId: login.userID,
      guid: currentReportGUIDFromUrl,
      merchant: {
        checkoutUri: "https://www.examplestore.com/checkout.php",
        confirmationUri: navigationUrl,
        // notificationUri: "https://www.examplestore.com/notification.php",
        notificationUri:
          config.SRU.url +
          "/notification?checkout=" +
          paysonIDFromResponse,
        termsUri: "https://www.examplestore.com/terms.php",
      },
      order: {
        currency: "sek",
        items: itemsForPostPayson,
      },
    };

    axios
      .post(
        "/annualReportPackage/paysonCreateCheckout",
        createCheckout,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        if (response.data) {
          paysonIDFromResponse = response.data.id;
          payment.values.checkoutRef = paysonIDFromResponse;
          iFrameID =
            "https://test-www.payson.se/embedded/checkout?id=" +
            paysonIDFromResponse;
          this.updatePaysonCheckout();

          let billingInfo = {
            key: "all",
            value: "all",
            checkoutId: paysonIDFromResponse,
            companyName: companyName,
            registrationNo: organizationNumber,
            postalCode: postalCode,
            city: city,
            name: login.userName,
            emailId: login.emailID,
          };

          axios
            .post(
              "/annualReportPackage/updatePaymentHeaderBillingInfo",
              billingInfo,
              userSessionAuthKeyReturnValue
            )
            .then((updateCompanyNumberResponse) => { });
        }

        this.setState({
          rerender: true,
          iframeKey: this.state.iframeKey + 1,
        });
      });
  }

  carouselButton(selectedCart) {
    const { login, prices } = this.props;
    cartValuesLocalData = JSON.parse(localStorage.getItem("cartValues") || []);
    let filteredPeople = [];

    const upd_cartValuesLocalData = cartValuesLocalData.map((obj) => {
      let cartArray = obj.cartArray;
      filteredPeople = cartArray.filter(
        (item) => item.packageId == selectedCart.packageId
      );

      if (filteredPeople.length > 0) {
        cartArray.map((i, idx) => {
          if (i.packageId == selectedCart.packageId) {
            i.quantity = i.quantity + 1;
          }
          return i;
        });

        gridData = obj.cartArray;
        gridData = gridData && ((val) => val.application == "SRU")

      } else {
        selectedCart.quantity = 1;
        cartArray.push(selectedCart);
        gridData = cartArray;
        gridData = gridData && gridData.filter((val) => val.application == "SRU")

      }
      return obj;
    });

    localStorage.setItem("cartValues", JSON.stringify(upd_cartValuesLocalData));

    this.updatePaysonCheckout();
  }

  packageCarouselRender(item) {
    return (
      <div>
        <div class="col-md-4 col-sm-6" className="pricesIterationDiv">
          <div class="pricingTable">
            <div class="pricingTable-header">
              {/* <span class="heading">{item.title}</span> */}
              <span>{item.text1}</span>
            </div>
            <div class="pricing-plans">
              <span class="price-value">
                <span>{numberWithCommasAndSpace(item.price)} kr</span>
              </span>
            </div>
            <div class="pricingContent">
              <ul>
                <li>{item.packageName}</li>
                <li>validity : {item.validityPeriod}</li>
                <li>{item.text3}</li>
              </ul>
            </div>

            <div class="pricingTable-sign-up">
              <button
                onClick={() => this.carouselButton(item)}
                class="btn btn-block btn-default"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  orgNoOnBlur(e) {
    const { login } = this.props;

    if (userSessionAuthKeyReturnValue) {
      let billingInfo = {
        key: "registrationNo",
        value: e.target.value,
        userId: login.userID,
        checkoutId: paysonIDFromResponse,
      };

      //Update Billing Info API
      axios
        .post(
          "/annualReportPackage/updatePaymentHeaderBillingInfo",
          billingInfo,
          userSessionAuthKeyReturnValue
        )
        .then((response) => { });
    }
  }

  updateGridDataNameColumn(gridData) {
    gridData &&
      gridData.length &&
      gridData.map((i, idx) => {
        return (i.title = i.text1 + " - " + i.packageName);
      });
  }

  convertDateFormat(startingEpochValue, validUntilEpochValue) {
    let startingDateValue = moment.unix(startingEpochValue) / 1000;
    let validDateValue = moment.unix(validUntilEpochValue) / 1000;

    let startingDateValueFormat =
      moment(startingDateValue).format("YYYY-MM-DD");

    let validDateValueFormat = moment(validDateValue).format("YYYY-MM-DD");
    return { startingDateValueFormat, validDateValueFormat };
  }

  packageNameBodyTemplate(rowData, rowIndex) {
    if (rowIndex.rowIndex == 0) {
      return (
        <div style={{ color: "black", fontWeight: "700" }}>
          {rowData.packageName}
        </div>
      );
    } else {
      return <div style={{ color: "grey" }}>{rowData.packageName}</div>;
    }
  }
  quantityLevelBodyTemplate(rowData, rowIndex) {
    if (rowIndex.rowIndex == 0) {
      return (
        <div style={{ color: "black", fontWeight: "700", textAlign: "center" }}>
          {rowData.quantity}
        </div>
      );
    } else {
      return (
        <div style={{ color: "grey", textAlign: "center" }}>
          {rowData.quantity}
        </div>
      );
    }
  }
  startingDateBodyTemplate(rowData, rowIndex) {
    let dateOnly = rowData.startingDate.slice(0, 10);
    if (rowIndex.rowIndex == 0) {
      return (
        <div style={{ color: "black", fontWeight: "700" }}>{dateOnly}</div>
      );
    } else {
      return <div style={{ color: "grey" }}>{dateOnly}</div>;
    }
  }
  validUntilBodyTemplate(rowData, rowIndex) {
    let dateOnly = rowData.validUntil.slice(0, 10);
    if (rowIndex.rowIndex == 0) {
      return (
        <div style={{ color: "black", fontWeight: "700" }}>{dateOnly}</div>
      );
    } else {
      return <div style={{ color: "grey" }}>{dateOnly}</div>;
    }
  }
  maxUsageBodyTemplate(rowData, rowIndex) {
    if (rowIndex.rowIndex == 0) {
      return (
        <div style={{ color: "black", fontWeight: "700", textAlign: "center" }}>
          {rowData.maxUsage}
        </div>
      );
    } else {
      return (
        <div style={{ color: "grey", textAlign: "center" }}>
          {rowData.maxUsage}
        </div>
      );
    }
  }
  actualUsageBodyTemplate(rowData, rowIndex) {
    if (rowIndex.rowIndex == 0) {
      return (
        <div style={{ color: "black", fontWeight: "700", textAlign: "center" }}>
          {rowData.actualUsage}
        </div>
      );
    } else {
      return (
        <div style={{ color: "grey", textAlign: "center" }}>
          {rowData.actualUsage}
        </div>
      );
    }
  }

  remainingReportsBodyTemplate(rowData, rowIndex) {
    if (rowIndex.rowIndex == 0) {
      return (
        <div style={{ color: "black", fontWeight: "700", textAlign: "center" }}>
          {rowData.maxUsage - rowData.actualUsage}
        </div>
      );
    } else {
      return (
        <div style={{ color: "grey", textAlign: "center" }}>
          {rowData.maxUsage - rowData.actualUsage}
        </div>
      );
    }
  }

  finalPayForCurrentReport() {
    const { headerTableData } = this.state;
    if (userSessionAuthKeyReturnValue) {
      let splittedUrl = window.location.href.split("/");
      currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
      let payCurrentReport = {
        entryNo: previouslyBoughtPackages[0].entryNo,
        reportGUID: currentReportGUIDFromUrl,
      };

      axios
        .post(
          "/annualReportPackage/payNow",
          payCurrentReport,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            this.setState({ showPaidDialogBox: true });
            this.toast.show({
              severity: "success",
              summary: "Navigating to Review and Send",
              detail: "Package used for Report. Navigation to Review the PDF",
              life: 3000,
            });
            setTimeout(() => {
              this.navigateAfterPayment();
            }, 2000);
          }
        });
    }
  }
  checkUpdatedBillingInfo() {
    const {
      companyName,
      organizationNumber,
      companyNameFromDB,
      registrationNoFromDB,
      postalCodeFromDB,
      cityFromDB,
      postalCode,
      city,
      name,
      emailId,
    } = this.state;
    const { login } = this.props;
    if (login && login.accountingCompany == false) {
      if (name != "" && name != null && emailId != "" && emailId != null) {
        billingInfoUpdated = false;
      } else {
        billingInfoUpdated = true;
      }
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    const { login, prices, currentPageName, sideBarReducer, payment } =
      this.props;

    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    currentPageName.values = "Payment";
    localStorage.setItem("currentPageName", "Payment");

    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    let currentUserDetails = JSON.parse(localStorage.getItem("rebelSkoolUser"));
    let cartArrayList = [];
    if (userSessionAuthKeyReturnValue) {
      if (login != undefined) {
        cartValuesLocalData =
          localStorage.getItem("cartValues") &&
          JSON.parse(localStorage.getItem("cartValues"));
        if (cartValuesLocalData == null) {
          cartValuesLocalData = [];
        }
        let filteredPeople = [];
        filteredPeople =
          cartValuesLocalData &&
          cartValuesLocalData.filter((item) => item.userID == login.userID);

        cartArrayList =
          filteredPeople &&
          filteredPeople.length > 0 &&
          filteredPeople[0].cartArray;

        this.updateTotalAmounts(cartArrayList);

        // From Reducer
        this.setState({
          emailId: login.emailID,
          name: login.userName,
          lastName: login.lastName,
        });

        payment.values.name = login.userName;
        payment.values.emailId = login.emailID;
      }
      axios
        .get(
          // "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          "/SRUHeader/getHeader?reportGuid=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          annualReportHeaderAPIResp = response.data;
          if (annualReportHeaderAPIResp != "") {
            //If he has a record in Annual Report Header Table
            this.setState({ headerTableData: response.data });
            //Check the Access status of the user accessing the report
            //If the user is private
            if (annualReportHeaderAPIResp.reportID != null) {
              this.setState({ reportPaymentCheckout: true });

              this.setState({
                name: currentUserDetails.firstName,
                lastName: currentUserDetails.lastName,
                organizationNumber: annualReportHeaderAPIResp.registrationNo,
                companyName: annualReportHeaderAPIResp.companyName,
                postalCode: annualReportHeaderAPIResp.postalCode,
                city: annualReportHeaderAPIResp.city,
              });

              if (!currentUserDetails.isAccountingCompany) {
                this.setState({
                  annualReportID: annualReportHeaderAPIResp.reportID,
                });
                let reportAccessReq = {
                  reportId: annualReportHeaderAPIResp.reportID,
                };

                axios
                  .post(
                    // "/annualReport/getAccessStatus",
                    "/SRUHeader/getAccessStatus",
                    reportAccessReq,
                    userSessionAuthKeyReturnValue
                  )
                  .then((response) => {

                    if (response.data) {
                      if (response.data == 1) {
                        showPayment = true;
                      } else {
                        showPayment = false;
                      }
                    }
                  })
                  .catch((error) => {
                    let noAccessURL =
                      "/income-declaration-2/" +
                      currentReportGUIDFromUrl +
                      "/no-access";
                    this.props.history.push(noAccessURL);
                  });
              }

              // If the user is from a Accounting company - Admin/User
              else {
                let reportGUID = {
                  reportGUID: currentReportGUIDFromUrl,
                };
                axios
                  .post(
                    "/annualReport/getReportOwnerAccountingCompanyId",
                    reportGUID,
                    userSessionAuthKeyReturnValue
                  )
                  .then((response) => {
                    if (response.data) {
                      showPayment = true;
                    } else {
                      showPayment = false;
                    }
                  });
              }
            }
            // This is to determine whether the user is paying after completing the record or
            // he is paying from the home page clicking Go To CheckOut
            if (
              annualReportHeaderAPIResp != "" &&
              annualReportHeaderAPIResp.reportID != null
            ) {
              navigationUrl =
                config.SRU.url +
                "/" +
                currentReportGUIDFromUrl +
                // "/reviewAndSend";
                "/paymentConfirmation";

              //Get the completed pages for this report for the green tick in the sidebar
              axios
                .get(
                  "/annualReport/getCompletedPages?reportId=" +
                  annualReportHeaderAPIResp.reportID,
                  userSessionAuthKeyReturnValue
                )
                .then((completedStatementTypesResponse) => {
                  sideBarReducer.values = completedStatementTypesResponse.data;
                  sideBarGreenTickPages = completedStatementTypesResponse.data;
                  this.setState({
                    reRender: true,
                  });
                });
            } else {
              let possibleAccessablePagesListLSString = localStorage.getItem(
                "possibleAccessablePages"
              );

              let possibleAccessablePagesListLSArray = JSON.parse(
                possibleAccessablePagesListLSString
              );

              navigationUrl =
                config.SRU.url +
                "/" +
                currentReportGUIDFromUrl +
                // "/MyProfile";
                "/paymentConfirmation";
            }

            this.setState({
              paymentEntryNo: annualReportHeaderAPIResp.paymentEntryNo,
            });

            if (annualReportHeaderAPIResp.registrationNo != "")
              this.setState({
                registrationNoFromDB: annualReportHeaderAPIResp.registrationNo,
              });

            if (annualReportHeaderAPIResp.companyName != "") {
              this.setState({
                companyNameFromDB: annualReportHeaderAPIResp.companyName,
              });
            }

            if (annualReportHeaderAPIResp.postalCode != "")
              this.setState({
                postalCodeFromDB: annualReportHeaderAPIResp.postalCode,
              });

            if (annualReportHeaderAPIResp.city != "")
              this.setState({
                cityFromDB: annualReportHeaderAPIResp.city,
              });

            if (annualReportHeaderAPIResp.currentYear != "")
              this.setState({
                currentFinancialYear: annualReportHeaderAPIResp.currentYear,
              });

            // Check if he has already bought packages
            axios
              .get(
                "/annualReportPackage/getPaymentLines",
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                if (response.data) {
                  previouslyBoughtPackages = response.data;
                  previouslyBoughtPackages = previouslyBoughtPackages.filter(
                    (pack) => pack.status == true
                  );

                  if (
                    previouslyBoughtPackages.length > 0 &&
                    annualReportHeaderAPIResp.reportID != null
                  ) {

                    this.setState({ activePackageAvailable: true });
                  }
                }

                axios.get("/siteOwner/getTemplatePackages").then((response) => {
                  if (response.data.length > 0) {
                    if (
                      (!cartArrayList || cartArrayList.length == 0) &&
                      previouslyBoughtPackages.length <= 0
                    ) {
                      this.settingDefaultCartValue(response.data);
                    } else {
                      gridData = cartArrayList;
                      gridData = gridData && gridData.filter((val) => val.application == "SRU")

                    }
                    if (login) {
                      carouselData = response.data;
                      carouselData = carouselData.filter((item) => item.application == "SRU");
                    }
                    this.setState({
                      pricesList: response.data,
                    });
                  }
                });
                this.setState({ isLoading: false });

                //If he has no exisiting packages, We need to getPaysonCheckOut
                if (
                  previouslyBoughtPackages.length <= 0 ||
                  annualReportHeaderAPIResp.reportID == null
                ) {
                  const config = {
                    headers: {
                      "User-Session-Auth-Key":
                        userSessionAuthKeyReturnValue.headers[
                          "User-Session-Auth-Key"
                        ],
                      language: userSelectedLanguage,
                    },
                  };
                  axios
                    .get(
                      "/annualReportPackage/paysonGetCheckoutObj?reportGUID=" +
                      currentReportGUIDFromUrl,
                      config
                    )
                    .then((response) => {
                      // if(response.data.paymentStatus != null) {

                      if (response.data.address != null) {
                        let addressArray = response.data.address.split("$$$");
                        if (addressArray.length == 1) {
                          this.setState({
                            billingAddress: addressArray[0],
                          });
                        } else {
                          this.setState({
                            careOf: addressArray[0],
                            billingAddress: addressArray[1],
                          });
                        }
                      }

                      if (response.firstName != null) {
                        this.setState({
                          name: response.data.firstName,
                        });
                      } else {
                        this.setState({
                          name: currentUserDetails.firstName,
                        });
                      }

                      if (response.firstName != null) {
                        this.setState({
                          lastName: response.data.lastName,
                        });
                      } else {
                        this.setState({
                          lastName: currentUserDetails.lastName,
                        });
                      }
                      if (response.data.registrationNo != null) {
                        this.setState({
                          organizationNumber: response.data.registrationNo,
                        });
                      }

                      if (response.data.city != null) {
                        this.setState({
                          city: response.data.city,
                        });
                      }

                      if (response.data.companyName != null) {
                        this.setState({
                          companyName: response.data.companyName,
                        });
                      }

                      if (
                        response.data.postalCode != null &&
                        response.data.postalCode > 0
                      ) {
                        this.setState({
                          postalCode: response.data.postalCode,
                        });
                      }

                      if (
                        response.data.paymentStatus == "readyToShip" ||
                        response.data.paymentStatus == "shipped"
                      ) {
                        this.toast.show({
                          severity: "success",
                          summary: "Payment Successfully Completed",
                          detail:
                            "We received your Payment. Navigating to Payment Confirmation",
                          life: 2000,
                        });
                        let navigationUrl =
                          config.SRU.url +
                          currentReportGUIDFromUrl +
                          "/paymentConfirmation";
                        setTimeout(() => {
                          this.props.history.push(navigationUrl);
                        }, 2000);
                      }
                      //If the payment status is equal to created
                      else if (response.data.paymentStatus == "created") {
                        paysonIDFromResponse = response.data.pspCheckoutRef;
                        payment.values.checkoutRef =
                          response.data.pspCheckoutRef;

                        //Update the Billing Info if he has Reg No/Company Name/Postal Code/City
                        if (
                          annualReportHeaderAPIResp.companyName != null &&
                          annualReportHeaderAPIResp.registrationNo != null &&
                          annualReportHeaderAPIResp.postalCode != null &&
                          annualReportHeaderAPIResp.city != null
                        ) {
                          let billingInfo = {
                            key: "all",
                            value: "all",
                            checkoutId: paysonIDFromResponse,
                            companyName: annualReportHeaderAPIResp.companyName,
                            registrationNo:
                              annualReportHeaderAPIResp.registrationNo,
                            postalCode: annualReportHeaderAPIResp.postalCode,
                            city: annualReportHeaderAPIResp.city,
                            name: login.userName,
                            emailId: login.emailID,
                          };

                          axios
                            .post(
                              "/annualReportPackage/updatePaymentHeaderBillingInfo",
                              billingInfo,
                              userSessionAuthKeyReturnValue
                            )
                            .then((updateCompanyNumberResponse) => { });
                        } else {
                          let billingInfo = {
                            key: "all",
                            value: "all",
                            checkoutId: paysonIDFromResponse,
                            companyName: this.state.companyName,
                            registrationNo: this.state.organizationNumber,
                            postalCode: this.state.postalCode,
                            city: this.state.city,
                            name: login.userName,
                            emailId: login.emailID,
                          };

                          axios
                            .post(
                              "/annualReportPackage/updatePaymentHeaderBillingInfo",
                              billingInfo,
                              userSessionAuthKeyReturnValue
                            )
                            .then((updateCompanyNumberResponse) => { });
                        }
                        // Call Update Payson Checkout API
                        // this.updatePaysonCheckout();

                        //If the user refresh the page iFrameID and iframeKey will update once again and rerender
                        iFrameID =
                          "https://test-www.payson.se/embedded/checkout?id=" +
                          paysonIDFromResponse;
                        this.setState({
                          rerender: true,
                          iframeKey: this.state.iframeKey + 1,
                        });
                      }
                      //If the payment status is expired/cancelled/
                      else if (
                        response.data.paymentStatus !== null &&
                        (response.data.paymentStatus == "cancelled" ||
                          response.data.paymentStatus == "expired")
                      ) {
                        this.toast.show({
                          severity: "error",
                          summary: "Session Expired",
                          detail:
                            "Your Payment Session is expired. Redirecting to Home",
                          life: 2000,
                        });
                        let reverseNavigationUrl = "/income-declaration-2/";
                        setTimeout(() => {
                          this.props.history.push(reverseNavigationUrl);
                          // this.props.history.push(config.annualReport.url);
                        }, 3000);
                      }
                      //If the payment status is denied
                      else if (
                        response.data.paymentStatus != null &&
                        response.data.paymentStatus == "denied"
                      ) {
                        this.toast.show({
                          severity: "error",
                          summary: "Payment Denied",
                          detail:
                            "Your Payment is denied by Payson. Redirecting to Home",
                          life: 2000,
                        });
                        setTimeout(() => {
                          this.props.history.push(config.annualReport.url);
                        }, 3000);
                      }
                      //If the payment status is readyToShip/shipped, we need to call Create Payson Checkout API
                      else {
                        this.createPaysonCheckout();
                      }
                      this.setState({ rerender: true });
                      // }
                    })
                    .catch((error) => { });
                }
              });
          }

          if (annualReportHeaderAPIResp.reportID == null) {
            let possibleNavigationPagesList = [
              "My Dashboard",
              "Company Information",
            ];
            let possibleNavigationPagesListString = JSON.stringify(
              possibleNavigationPagesList
            );
            localStorage.setItem(
              "possibleAccessablePages",
              possibleNavigationPagesListString
            );
          }
        });
    }
  }
  navigateAfterPayment() {
    const incomeDeclaration2SRU = "/income-declaration-2/";
    let navigationURL =
      incomeDeclaration2SRU + currentReportGUIDFromUrl + "/reviewAndSend";
    this.props.history.push(navigationURL);
  }

  billingAddressOnChange(e, field) {
    if (field == "CO") {
      this.setState({
        careOf: e.target.value,
      });
    } else {
      this.setState({
        billingAddress: e.target.value,
      });
    }
  }

  billingAddressOnBlur(e, field) {
    const { careOf, billingAddress } = this.state;
    let addressLine = "";
    if (careOf != undefined && careOf.length > 0) {
      addressLine = careOf + "$$$" + billingAddress;
    } else {
      addressLine = billingAddress;
    }
    let billingAddressObj = {
      key: "address",
      value: addressLine,
      checkoutId: paysonIDFromResponse,
    };

    axios
      .post(
        "/annualReportPackage/updatePaymentHeaderBillingInfo",
        billingAddressObj,
        userSessionAuthKeyReturnValue
      )
      .then((updateCompanyNameResponse) => { });
  }

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };

  render() {
    const {
      companyName,
      organizationNumber,
      companyNameFromDB,
      postalCode,
      city,
      toggleOpen,
      activeIndex,
      sideBarStatus,
      userCompanyType,
      paymentType,
      accountingCompanyOrgName,
      accountingCompanyPostalCode,
      accountingCompanyCity,
      pricesList,
      defaultPrice,
      discountCodeRupee,
      totalReportCount,
      totalUsedReportCount,
      remainingReportCount,
      currentFinancialYear,
      activePackageAvailable,
      registrationNoFromDB,
      postalCodeFromDB,
      cityFromDB,
      totalPriceExcMoms,
      totalMoms,
      totalPriceIncMoms,
      billingAddress,
      careOf,
      name,
      lastName,
      isLoading,
    } = this.state;
    const { prices, login, companyInformation } = this.props;
    this.handleCartPrice();
    this.updateGridDataNameColumn(gridData);
    this.checkUpdatedBillingInfo();
    let sideBarClassName = "";
    if (!sideBarStatus || sideBarStatus == undefined) {
      sideBarClassName = "payment-div-sidebar";
    } else {
      sideBarClassName = "payment-div";
    }

    return (
      <div className="notes-main-div">
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          style={{ marginTop: "34%" }}
        ></Toast>

        <SRUNavBarHeader callBack={this.callback} isAppPortal={true} />

        <div className="paymentSideBar">
          <Sidebar
            children="Payment"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={this.sideBarCallback.bind(this)}
          />
        </div>
        {isLoading ? (
          <div className="ISLoading">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        ) : (
          <div>
            {this.state.paymentEntryNo > 0 ? (
              <div className="already-paid-div">
                <center>
                  The Payment for this report has been paid already
                </center>
              </div>
            ) : (
              <div
                //className="payment-div"
                className={sideBarClassName}
              >
                <Row className="row-payment-div1" style={{ width: "100%" }}>
                  {this.state.annualReportID != null && (
                    <div className="PaymentExapndCollapsToggleLbl">
                      <Row className="BSFYStylepayment">

                        <Col>
                          <div>
                            <div className="Payment_PageTitle">
                              <div className="Page_Title_T1_payment_name">
                                {companyNameFromDB}
                              </div>
                              <div className="Page_Title_Paymemt_Year">
                                {currentFinancialYear}
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col>
                          <Button
                            type="button"
                            icon="pi pi-file-pdf"
                            outlined
                            style={{ borderRadius: "70px", marginTop: "19px" }}
                            className="pdf_view_incomestatement"
                            tooltip={
                              TranslationFile.pdfPreview[userSelectedLanguage]
                            }
                            tooltipOptions={{ position: "bottom" }}
                            // onClick={() => this.navigateToPDF()}
                            onClick={() => previewPdf(currentReportGUIDFromUrl)}
                          />
                        </Col>

                        {toggleOpen == true ? (
                          <Button
                            value={toggleOpen}
                            icon="pi pi-chevron-down"
                            title={
                              TranslationFile.ExpandText[userSelectedLanguage]
                            }
                            onClick={(e) => this.handleChange(toggleOpen)}
                            className="toggle-open"
                          />
                        ) : (
                          <Button
                            value={toggleOpen}
                            icon="pi pi-chevron-up"
                            title={
                              TranslationFile.CollapseText[userSelectedLanguage]
                            }
                            onClick={(e) => this.handleChange(toggleOpen)}
                            className="toggle-open"
                          />
                        )}
                      </Row>
                    </div>
                  )}

                  {showPayment != undefined && !showPayment && !payFromHome ? (
                    <div className="payment-restriction">
                      <center>
                        The Owner of the Report can only pay for this current
                        report
                      </center>
                    </div>
                  ) : (
                    <Col
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={8}
                      style={{
                        width: "100%",
                        marginLeft: "12px",
                      }}
                      className="Payment_Main_Div"
                    >
                      <Accordion
                        multiple
                        activeIndex={activeIndex}
                        onTabChange={(e) =>
                          this.setState({ activeIndex: e.index })
                        }
                        ref={this.myRef}
                        className="payment-accordion"
                      >
                        {activePackageAvailable && !payFromHome && (
                          <AccordionTab header="My Packages" className="IS-Accordion-Tab">
                            <div>
                              <center>
                                <b>Choosing the Earliest Bought Package</b>
                              </center>
                              <br></br>

                              {previouslyBoughtPackages && (
                                <center>
                                  <DataTable
                                    value={previouslyBoughtPackages}
                                    tableStyle={{ width: "60rem" }}
                                    paginator
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[5, 10, 15]}
                                    rows={5}
                                  >
                                    <Column
                                      header="Package Name"
                                      field="packageName"
                                      body={this.packageNameBodyTemplate}
                                    ></Column>
                                    <Column
                                      header="Quantity"
                                      field="quantity"
                                      body={this.quantityLevelBodyTemplate}
                                    ></Column>
                                    <Column
                                      header="Starting Date"
                                      field="startingDate"
                                      body={this.startingDateBodyTemplate}
                                    ></Column>
                                    <Column
                                      header="Ending Date"
                                      field="validUntil"
                                      body={this.validUntilBodyTemplate}
                                    ></Column>
                                    <Column
                                      field="maxUsage"
                                      header="Total Packages"
                                      body={this.maxUsageBodyTemplate}
                                    ></Column>

                                    <Column
                                      field="actualUsage"
                                      header="Used Packages"
                                      body={this.actualUsageBodyTemplate}
                                    ></Column>
                                    <Column
                                      header="Remaining Packages"
                                      body={this.remainingReportsBodyTemplate}
                                    ></Column>
                                  </DataTable>
                                  <br></br>
                                  <div className="year-limit-info">
                                    <center>
                                      <i
                                        className="pi pi-info-circle"
                                        style={{ marginRight: "5px" }}
                                      ></i>
                                      <span style={{ marginRight: "2px" }}>
                                        Organization Number and Financial Year
                                        cannot be edited once you pay for this
                                        report
                                      </span>

                                    </center>
                                  </div>
                                  <Button
                                    onClick={() =>
                                      this.finalPayForCurrentReport()
                                    }
                                  >
                                    Use Package
                                  </Button>
                                </center>
                              )}
                              <br />
                            </div>
                          </AccordionTab>
                        )}

                        {(!activePackageAvailable || payFromHome) && (

                          <AccordionTab header="Cart" className="IS-Accordion-Tab">
                            <div>
                              <div className="packageCarouselCard">
                                <Carousel
                                  value={carouselData}
                                  numVisible={3}
                                  numScroll={1}
                                  circular
                                  autoplayInterval={20000}

                                  responsiveOptions={responsiveOptions}
                                  itemTemplate={this.packageCarouselRender}
                                  className="main-carousel-card"
                                />
                              </div>
                              <DataTable

                                value={gridData}
                                tableStyle={{ minWidth: "50rem" }}
                              >
                                <Column header="Name" field="title"></Column>
                                <Column
                                  header="Unit Price"
                                  field="price"
                                  body={this.unitPriceBodyTemplate}
                                ></Column>

                                <Column
                                  header="Quantity"
                                  field="quantity"
                                  body={this.quantityBodyTemplate}
                                ></Column>

                                <Column
                                  header="Price"
                                  body={this.priceBodyTemplate}
                                ></Column>

                                <Column body={this.removeBodyTemplate}></Column>
                              </DataTable>
                            </div>
                            <br></br>

                            <div className="payment_totalCalc_Div">
                              <Row>
                                <Col></Col>
                                <Col className="payment_total_price">
                                  Total Ex Moms
                                </Col>
                                <Col className="payment_total_kr">
                                  <label className="totalSums">
                                    {totalPriceExcMoms} kr
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col></Col>
                                <Col className="payment_total_price">Moms</Col>
                                <Col className="payment_total_kr">
                                  <label className="totalSums">
                                    {totalMoms} kr
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col></Col>
                                <Col className="payment_total_price">
                                  Summa Inc Moms
                                </Col>
                                <Col className="payment_total_kr">
                                  <label className="totalSums">
                                    {totalPriceIncMoms} kr
                                  </label>

                                </Col>
                              </Row>
                            </div>
                          </AccordionTab>
                        )}
                        {(!activePackageAvailable || payFromHome) && (
                          <AccordionTab header="Billing Information" className="IS-Accordion-Tab">
                            <div
                              className="payment_information_fill-div"
                              style={{ padding: "5% 10%" }}
                            >
                              <div className="mand-bill-info">
                                <center>
                                  <i
                                    className="pi pi-info-circle"
                                    style={{ marginRight: "5px" }}
                                  ></i>
                                  <span style={{ marginRight: "2px" }}>
                                    Please fill the Billing Information to make
                                    payment
                                  </span>
                                </center>
                              </div>
                              <br></br>
                              <Row>
                                <Col>
                                  <Row>
                                    <Col>
                                      <label
                                        htmlFor="Name"
                                        className="companyInformationTextBoxTitle"
                                      >
                                        First Name
                                        <p className="companyInformationMandatoryStart">
                                          *
                                        </p>
                                      </label>
                                      <InputText
                                        className="payment-bill-info-name"
                                        value={name}
                                        onChange={(e) =>
                                          this.paymentNameOnChange(e, "name")
                                        }
                                        onBlur={(e) =>
                                          this.paymentNameOnBlur(e, "name")
                                        }

                                      />
                                    </Col>
                                    <Col>
                                      <label
                                        htmlFor="Name"
                                        className="companyInformationTextBoxTitle"
                                      >
                                        Last Name
                                        <p className="companyInformationMandatoryStart">
                                          *
                                        </p>
                                      </label>
                                      <InputText
                                        className="payment-bill-info-name"
                                        value={lastName}
                                        onChange={(e) =>
                                          this.paymentNameOnChange(
                                            e,
                                            "lastName"
                                          )
                                        }
                                        onBlur={(e) =>
                                          this.paymentNameOnBlur(e, "lastName")
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Col>

                                <Col>
                                  <label
                                    htmlFor="Email"
                                    className="companyInformationTextBoxTitle"
                                  >
                                    Email
                                    <p
                                      className="companyInformationMandatoryStart"
                                      style={{ marginRight: "5px" }}
                                    >
                                      *
                                    </p>
                                    (
                                    <i
                                      className="pi pi-info-circle"
                                      style={{
                                        marginRight: "2px",
                                        marginTop: "2px",
                                      }}
                                    ></i>{" "}
                                    Invoice will be sent to the given mail)
                                  </label>
                                  <InputText
                                    className="payment-bill-info-email"
                                    value={this.state.emailId}
                                    onChange={(e) =>
                                      this.paymentEmailOnChange(e)
                                    }
                                    onBlur={(e) => this.paymentEmailOnBlur(e)}
                                  />
                                </Col>
                              </Row>
                              <br></br>

                              <Row>
                                <Col>
                                  <span>
                                    <label
                                      htmlFor="OrganizationNumber"
                                      className="companyInformationTextBoxTitle"
                                    >
                                      Organization Number

                                    </label>
                                    <div>
                                      <InputMask
                                        mask="999999-9999"

                                        value={organizationNumber}
                                        onComplete={(e) =>
                                          this.organizationNoOnComplete(e)
                                        }
                                        onBlur={(e) => this.orgNoOnBlur(e)}
                                        onChange={(e) =>
                                          this.organizationNoOnChange(e)
                                        }
                                        className="payment-bill-info-reg"
                                        useGrouping={false}

                                      />
                                    </div>
                                  </span>
                                </Col>
                                <Col>
                                  <span>
                                    <label
                                      htmlFor="CompanyName"
                                      className="companyInformationTextBoxTitle"
                                    >
                                      Company Name

                                    </label>

                                    <div>
                                      <InputText
                                        className="payment-bill-info-companyname"
                                        onChange={(e) =>
                                          this.companyNameOnChange(e)
                                        }
                                        onBlur={(e) =>
                                          this.companyNameOnBlur(e)
                                        }

                                        value={companyName}

                                      />
                                    </div>
                                  </span>
                                </Col>
                              </Row>
                              <br></br>
                              <Row>
                                <Col>
                                  <label
                                    htmlFor="Address"
                                    className="companyInformationTextBoxTitle"
                                  >
                                    C/O
                                  </label>

                                  <InputText
                                    value={careOf}
                                    onChange={(e) =>
                                      this.billingAddressOnChange(e, "CO")
                                    }
                                    onBlur={(e) =>
                                      this.billingAddressOnBlur(e, "CO")
                                    }
                                  />
                                </Col>
                                <Col>
                                  <label
                                    htmlFor="Address"
                                    className="companyInformationTextBoxTitle"
                                  >
                                    Address Line 1
                                  </label>

                                  <InputText
                                    value={billingAddress}
                                    onChange={(e) =>
                                      this.billingAddressOnChange(e, "Address")
                                    }
                                    onBlur={(e) =>
                                      this.billingAddressOnBlur(e, "Address")
                                    }
                                  />
                                </Col>
                              </Row>
                              <br></br>
                              <Row>
                                <Col>
                                  <span>
                                    <label
                                      htmlFor="PostalCode"
                                      className="companyInformationTextBoxTitle"
                                    >
                                      Postal Code

                                    </label>
                                    <div>
                                      <InputText
                                        className="payment-bill-info-postalcode"
                                        onChange={(e) =>
                                          this.PostalcodeOnChange(e)
                                        }
                                        onBlur={(e) => this.postalCodeOnBlur(e)}
                                        value={
                                          postalCode

                                        }

                                      />
                                    </div>
                                  </span>
                                </Col>
                                <Col>
                                  <span>
                                    <label
                                      htmlFor="City"
                                      className="companyInformationTextBoxTitle"
                                    >
                                      City

                                    </label>

                                    <div>
                                      <InputText
                                        className="payment-bill-info-city"
                                        onChange={(e) => this.cityOnChange(e)}
                                        onBlur={(e) => this.cityOnBlur(e)}
                                        value={
                                          city

                                        }
                                      />
                                    </div>
                                  </span>
                                </Col>
                              </Row>
                              <br></br>
                            </div>
                          </AccordionTab>
                        )}
                        {(!activePackageAvailable || payFromHome) && (
                          <AccordionTab header="Payment" className="IS-Accordion-Tab">
                            <div>
                              <center
                                className="incomeStatementSaveBtnCenter"

                              >
                                {this.state.reportPaymentCheckout && (
                                  <div className="mand-bill-info">
                                    <center>
                                      <i
                                        className="pi pi-info-circle"
                                        style={{ marginRight: "5px" }}
                                      ></i>
                                      <span style={{ marginRight: "2px" }}>
                                        Organization Number and Financial Year
                                        cannot be edited once you pay for this
                                        report
                                      </span>
                                    </center>
                                  </div>
                                )}
                                <br></br>
                                {iFrameID ? (
                                  <div>
                                    <iframe
                                      src={iFrameID && iFrameID}
                                      key={this.state.iframeKey}
                                      height="800"
                                      width="600"
                                      title="Iframe Example"
                                      className="payment_iframeDiv"
                                      style={{
                                        pointerEvents:
                                          billingInfoUpdated && "none",
                                      }}
                                    ></iframe>

                                    <br></br>
                                  </div>
                                ) : (
                                  <div className="ISLoading">
                                    <i
                                      className="pi pi-spin pi-spinner"
                                      style={{ fontSize: "2rem" }}
                                    ></i>
                                  </div>
                                )}
                              </center>
                            </div>
                          </AccordionTab>
                        )}
                      </Accordion>
                    </Col>
                  )}
                </Row>
              </div>
            )}
          </div>
        )}

        <ScrolltoTop />

        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(SRUPayment);

